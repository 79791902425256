import { ButtonBase, styled, Tooltip, Typography } from '@mui/material';
import { memo } from 'react';
import { GraphTag } from '../../types/knowledgeGraph';

const TagBox = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'pointerCursor',
})<{ pointerCursor: boolean }>(({ pointerCursor }) => ({
  width: 'fit-content',
  padding: '2px 0.75rem',
  clipPath: 'polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%)',
  overflow: 'hidden',
  cursor: pointerCursor ? 'pointer' : 'default',
}));

interface RelationshipTagProps extends GraphTag {
  disableToolTip?: boolean;
  onClick?: () => void;
}

export default memo(
  ({
    label,
    backgroundColor,
    textColor,
    disableToolTip,
    onClick,
  }: RelationshipTagProps) => {
    return (
      <Tooltip title={label} disableHoverListener={disableToolTip}>
        <TagBox
          sx={{ backgroundColor }}
          onClick={onClick}
          pointerCursor={onClick != null}
          disableRipple={onClick == null}
        >
          <Typography
            color={textColor}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {label}
          </Typography>
        </TagBox>
      </Tooltip>
    );
  },
);
