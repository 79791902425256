import { Box, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import ForceGraph2D, { GraphData } from 'react-force-graph-2d';
import useObservedClientRect from '../../../hooks/useObservedClientRect';
import { theme } from '../../../styles/theme';

const ForceGraphContainer = styled(Box)({
  height: '75vh',
  width: 'inherit',
  display: 'flex',
  overflow: 'hidden ',
  marginBottom: theme.spacing(2),
}) as typeof Box;

export interface PreviewSectionProps {
  graphData: GraphData;
}

export default memo(({ graphData }: PreviewSectionProps) => {
  const graphDataMemo = useMemo(() => graphData, [graphData]);

  // the force graph canvas needs hard numbers for height and width
  const { ref, rect } = useObservedClientRect();
  // so we access the DOMRect of the parent container and set the canvas dimensions equal to that parent container
  const height = useMemo(() => (rect != null ? rect.height : 0), [rect]);
  const width = useMemo(() => (rect != null ? rect.width : 0), [rect]);

  return (
    <ForceGraphContainer ref={ref}>
      <ForceGraph2D
        graphData={graphDataMemo}
        nodeLabel={'nodeLabel'}
        nodeAutoColorBy={'nodeLabel'}
        backgroundColor={theme.palette.background.default}
        nodeRelSize={7}
        linkDirectionalArrowLength={7}
        linkLabel={'linkLabel'}
        width={width}
        height={height}
      />
    </ForceGraphContainer>
  );
});
