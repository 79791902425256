import { ResponseTypes } from '../types/api';
import { UserProfileResponse } from '../types/user';
import { makeGetRequest } from './authenticatedRequests';

export const getUserProfileService = async (
  username: string,
): Promise<string | UserProfileResponse> => {
  console.log('==== getUserProfileService ======');
  try {
    const getUserProfileResponse = await makeGetRequest(
      `getuserprofile?sk=${username}`,
    );

    if (getUserProfileResponse.data.length > 0) {
      const userProfileData: any[] = await getUserProfileResponse.data;
      return JSON.parse(userProfileData[0].UserProfileData.S);
    } else {
      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log('++++++++++ ERROR WITH getUserProfileService ++++++++++');
    console.log(error);

    return ResponseTypes.ErrorResponse;
  }
};
