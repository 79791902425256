import {
  Box,
  CircularProgress,
  IconButton,
  styled,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { Node, Path, QueryResult, Record, Relationship } from 'neo4j-driver';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ForceGraph2D, { GraphData, NodeObject } from 'react-force-graph-2d';
import { FaRegCirclePlay } from 'react-icons/fa6';
import { useReadCypher } from 'use-neo4j';
import useObservedClientRect from '../../hooks/useObservedClientRect';
import { useKnowledgeGraphState } from '../../state/knowledgeGraphState';
import { chartColors } from '../../styles/colors';
import {
  ColumnFlex,
  SpaceBetweenRowFlex,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  GraphTag,
  KGNodeShape,
  propertyTagColor,
  propertyTagTextColor,
  relationshipTagColor,
  relationshipTagTextColor,
} from '../../types/knowledgeGraph';
import WarningMessage from '../common/warningMessage';
import CypherHelpModal from '../knowledgeGraph/cypherHelpModal';
import GraphSidebar from '../knowledgeGraph/graphSidebar';
import KnowledgeGraphVisualizationSkeletonLoaders from '../knowledgeGraph/skeletonLoaders';
import DatabaseSummary from './databaseSummary';

export const KnowledgeGraphPageContainer = styled(Box)({
  height: 'inherit',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
  paddingBottom: theme.defaultPaddingRem,
}) as typeof Box;

export const KnowledgeGraphHeaderContainer = styled(SpaceBetweenRowFlex)({
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'end',
});

export const FullGraphContainer = styled(Box)({
  height: window.innerHeight * 0.95,
  width: 'calc(100% - 2px)',
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.borderRadiusRem,
  border: `1px solid ${theme.palette.divider}`,
}) as typeof Box;

export const ForceGraphContainer = styled(Box)({
  height: 'inherit',
  width: '80%',
  display: 'flex',
  overflow: 'hidden',
}) as typeof Box;

const CypherInput = styled(TextField)({
  width: 'inherit',
});

const HelperText = styled(Typography)({
  color: theme.palette.secondary.main,
  textDecoration: 'underline',
  cursor: 'pointer',
});

export const CypherInputContainer = styled(ColumnFlex)({
  width: 'inherit',
  marginBottom: theme.spacing(2),
  alignItems: 'flex-end',
});

const getRecordType = (
  record: any,
): 'Path' | 'Node' | 'Relationship' | null => {
  if (record?.segments != null || record?.length != null) {
    return 'Path';
  }
  if (
    record?.startElementId != null ||
    record?.endElementId != null ||
    record?.type != null
  ) {
    return 'Relationship';
  }
  if (record?.labels != null) {
    return 'Node';
  }
  // record type is unknown, this shouldn't ever happen though
  return null;
};

type PageStatusOptions =
  | 'loading'
  | 'error'
  | 'only links'
  | 'good data'
  | 'good data but empty';

export default function KnowledgeGraphVisualizationPageBody() {
  const { selectedKnowledgeGraph } = useKnowledgeGraphState();

  // -------------- QUERY VARS --------------
  const [queryInput, setQueryInput] = useState('MATCH(n) return n limit 100;');
  const [queryHistory, setQueryHistory] = useState<string[]>([queryInput]);
  const [queryHistoryIndex, setQueryHistoryIndex] = useState(0);
  const [mappingDataLoading, setMappingDataLoading] = useState(true);

  const queryInputError = useMemo(() => queryInput === '', [queryInput]);

  const cypherInputRef = useRef();

  // TODO: reimplement this when we figure out a set Neo4j cloud setup
  // const { loading, result, error, run } = useReadCypher(
  //   queryInput,
  //   {},
  //   selectedKnowledgeGraph?.clntDbName, // we ensure selectedKnowledgeGraph is set before rendering this component. This is done in KnowledgeGraphVisualizationPage.tsx
  // );

  const { loading, result, error, run } = useReadCypher(queryInput);

  // -------------- GRAPH VARS --------------
  const [nodes, setNodes] = useState<any[] | undefined>(undefined);
  const [links, setLinks] = useState<any[] | undefined>(undefined);
  const [graphData, setGraphData] = useState<GraphData | undefined>();

  // -------------- SIDEBAR INFO VARS --------------
  const [clickedNode, setClickedNode] = useState<NodeObject | undefined>(
    undefined,
  );
  const [nodeTags, setNodeTags] = useState<GraphTag[]>([]);
  const [relationshipTags, setRelationshipTags] = useState<GraphTag[]>([]);
  const [propertyTags, setPropertyTags] = useState<GraphTag[]>([]);

  // -------------- CANVAS VARS --------------
  // the force graph canvas needs hard numbers for height and width
  const { ref, rect } = useObservedClientRect();
  // so we access the DOMRect of the parent container and set the canvas dimensions equal to that parent container
  const height = useMemo(() => (rect != null ? rect.height : 0), [rect]);
  const width = useMemo(() => (rect != null ? rect.width : 0), [rect]);

  // -------------- LOADING VARS --------------
  const getPageStatus = useCallback((): PageStatusOptions => {
    if (error != null) {
      return 'error';
    }

    if (loading || mappingDataLoading) {
      return 'loading';
    }

    if (result != null && graphData != null && nodes != null && links != null) {
      // if there are only links and no nodes react-force-graph cannot render
      if (graphData.links.length > 0 && graphData.nodes.length === 0) {
        return 'only links';
      }

      if (graphData.links.length === 0 && graphData.nodes.length === 0) {
        return 'good data but empty';
      }
      return 'good data';
    }

    return 'error';
  }, [loading, mappingDataLoading, result, nodes, links, graphData, error]);

  const pageStatus: PageStatusOptions = useMemo(
    () => getPageStatus(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, mappingDataLoading, result, nodes, links, graphData, error],
  );

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleRunQuery = useCallback(() => {
    // useReadCypher throws an error if the query is empty :/
    if (!loading && queryInput.trim() !== '') {
      setNodes(undefined);
      setLinks(undefined);
      setGraphData(undefined);
      setNodeTags([]);
      setRelationshipTags([]);
      setPropertyTags([]);
      setMappingDataLoading(true);

      run();

      // if the query is not the last run query
      if (
        queryHistory.length === 0 ||
        queryHistory[queryHistory.length - 1] !== queryInput
      ) {
        let updatedQueryHistory = queryHistory;

        updatedQueryHistory.push(queryInput);

        setQueryHistory(updatedQueryHistory);
        setQueryHistoryIndex(updatedQueryHistory.length - 1);
      }
    }
  }, [
    run,
    queryInput,
    loading,
    setQueryHistory,
    queryHistory,
    setNodes,
    setLinks,
    setGraphData,
    setNodeTags,
    setRelationshipTags,
    setPropertyTags,
    setMappingDataLoading,
  ]);

  // we use this to trigger the cypher text input key command that runs a query
  // I am not sure why the query won't take the updated one if we just do the handleRunQuery() function
  // this is likely due to niche property of React saving a render
  const simulateKeyDownWithShift = (
    element: HTMLElement | null,
    key: string,
  ) => {
    const event = new KeyboardEvent('keydown', {
      key: key,
      code: key,
      keyCode: key.charCodeAt(0),
      which: key.charCodeAt(0),
      bubbles: true,
      cancelable: true,
      shiftKey: true,
    });

    if (element != null) element.dispatchEvent(event);
  };

  const handleNodeTagClick = useCallback(
    (nodeLabel: string) => {
      setQueryInput(`MATCH (n:${nodeLabel}) RETURN n LIMIT 100;`);

      if (cypherInputRef != null) {
        // @ts-expect-error
        cypherInputRef.current?.focus();
      }

      setTimeout(() => {
        simulateKeyDownWithShift(
          document.getElementById('cypher-text-input'),
          'Enter',
        );
      }, 50);
    },
    [setQueryInput, cypherInputRef],
  );

  const handleRelationshipTagClick = useCallback(
    (relationshipLabel: string) => {
      setQueryInput(
        `MATCH p=()-[:${relationshipLabel}]->() RETURN p LIMIT 100;`,
      );

      if (cypherInputRef != null) {
        // @ts-expect-error
        cypherInputRef.current?.focus();
      }

      setTimeout(() => {
        simulateKeyDownWithShift(
          document.getElementById('cypher-text-input'),
          'Enter',
        );
      }, 50);
    },
    [setQueryInput, cypherInputRef],
  );

  const handlePropertyTagClick = useCallback(
    (propertyLabel: string) => {
      setQueryInput(
        `MATCH (n) WHERE n.${propertyLabel} IS NOT NULL RETURN n LIMIT 100;`,
      );

      if (cypherInputRef != null) {
        // @ts-expect-error
        cypherInputRef.current?.focus();
      }

      setTimeout(() => {
        simulateKeyDownWithShift(
          document.getElementById('cypher-text-input'),
          'Enter',
        );
      }, 50);
    },
    [setQueryInput, cypherInputRef],
  );

  // if the user presses up or down while in the query input we let them navigate their query history
  const navigateQueryHistory = useCallback(
    (goUp: boolean) => {
      if (queryHistory.length > 0) {
        let currentQueryIndex = queryHistoryIndex;

        if (goUp && currentQueryIndex !== 0) currentQueryIndex--;

        if (!goUp && currentQueryIndex !== queryHistory.length - 1)
          currentQueryIndex++;

        setQueryInput(queryHistory[currentQueryIndex]);
        setQueryHistoryIndex(currentQueryIndex);
      }
    },
    [queryHistoryIndex, queryHistory, setQueryInput, setQueryHistoryIndex],
  );

  // track the inputs of the query text field for special key combos
  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const isMultiline = queryInput.includes('\n');
      const textField = event.target as HTMLInputElement;

      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();
        handleRunQuery();
      }

      // if the user presses cmd+up arrow
      if (event.key === 'ArrowUp' && event.metaKey) {
        navigateQueryHistory(true);
      }
      // if the user presses the up arrow and the input is only one line
      if (event.key === 'ArrowUp' && !isMultiline) {
        navigateQueryHistory(true);
      }
      // if the user presses cmd+up arrow
      if (event.key === 'ArrowDown' && event.metaKey) {
        navigateQueryHistory(false);
      }
      // if the user presses the up arrow and the input is only one line
      if (event.key === 'ArrowDown' && !isMultiline) {
        navigateQueryHistory(false);
      }
      // if the user is at the start of a multiline input AND the user presses up
      if (
        isMultiline &&
        event.key === 'ArrowUp' &&
        textField.selectionStart === 0
      ) {
        navigateQueryHistory(true);
      }
      // if the user is at the end of a multiline input AND the user presses down
      if (
        isMultiline &&
        event.key === 'ArrowDown' &&
        textField.selectionStart === queryInput.length
      ) {
        navigateQueryHistory(false);
      }
    },
    [queryInput, handleRunQuery, navigateQueryHistory],
  );

  const mapNodeFromRecord = (
    nodeRecord: Node,
    nodes: any[],
    nodeTags: GraphTag[],
    propertyTags: GraphTag[],
  ) => {
    let resultNodes: any[] = [...nodes];
    let updatedNodeTags: GraphTag[] = [...nodeTags];
    let updatedPropertyTags: GraphTag[] = [...propertyTags];
    const nodeTagsFromKG = selectedKnowledgeGraph?.nodeTags ?? [];

    // if the node has no label we don't map it
    if (nodeRecord?.labels != null && nodeRecord.labels.length > 0) {
      const nodeLabel = nodeRecord.labels[0];
      const nodeProps = Object.keys(nodeRecord.properties);

      // check the tags from the selected knowledge graph first
      const foundNodeTag = nodeTagsFromKG.find(tag => tag.label === nodeLabel);

      if (
        foundNodeTag != null &&
        !updatedNodeTags.some(tag => tag.label === nodeLabel)
      ) {
        updatedNodeTags.push({
          ...foundNodeTag,
        });
      }

      // if the tag does not yet exist
      if (
        foundNodeTag == null &&
        !updatedNodeTags.some(tag => tag.label === nodeLabel)
      ) {
        let colorIndex = updatedNodeTags.length;

        // reset the color index if we're past the length of the colors array. This is unlikely
        if (colorIndex > chartColors.length - 1) {
          colorIndex -= chartColors.length;
        }

        const tagColor = chartColors[colorIndex];
        const textColor = theme.palette.getContrastText(tagColor);

        // add the node to the tags represented on the graph sidebar
        updatedNodeTags.push({
          label: nodeLabel,
          backgroundColor: tagColor,
          textColor,
        });
      }

      // update the properties tags array with these properties represented on the graph sidebar
      nodeProps.forEach(property => {
        if (!updatedPropertyTags.some(tag => tag.label === property)) {
          updatedPropertyTags.push({
            label: property,
            backgroundColor: propertyTagColor,
            textColor: propertyTagTextColor,
          });
        }
      });

      // check for the tag
      const nodeTag = updatedNodeTags.find(tag => tag.label === nodeLabel);
      // format the node for react-force-graph
      const formmattedNode = {
        id: nodeRecord.identity.toNumber(),
        nodeLabel,
        color: nodeTag != null ? nodeTag.backgroundColor : '',
        properties: { ...nodeRecord.properties },
      };

      if (!resultNodes.some(node => node.id === formmattedNode.id))
        resultNodes.push(formmattedNode);
    }

    return {
      nodes: resultNodes,
      nodeTags: updatedNodeTags,
      propertyTags: updatedPropertyTags,
    };
  };

  const mapResultsToGraphData = useCallback(
    (result: QueryResult) => {
      setClickedNode(undefined);
      setMappingDataLoading(true);

      let resultNodes: any[] = [];
      let resultLinks: any[] = [];
      let updatedNodeTags: GraphTag[] = [];
      let updatedRelationshipTags: GraphTag[] = [];
      let updatedPropertyTags: GraphTag[] = [];

      if (result?.records != null) {
        result.records.forEach((record: Record) => {
          const keys = record.keys;

          // each record contains the key used to query it ex MATCH(n:NodeName) the key would be "n"
          keys.forEach(key => {
            const foundRecord = record.get(key);

            if (foundRecord != null) {
              const recordType = getRecordType(foundRecord);
              // we need to determine if it is a Node, Path, or Relationship
              switch (recordType) {
                case 'Node':
                  const nodeRecord = foundRecord as Node;

                  const nodeMapResponse = mapNodeFromRecord(
                    nodeRecord,
                    resultNodes,
                    updatedNodeTags,
                    updatedPropertyTags,
                  );

                  resultNodes = nodeMapResponse.nodes;
                  updatedNodeTags = nodeMapResponse.nodeTags;
                  updatedPropertyTags = nodeMapResponse.propertyTags;

                  break;
                case 'Path':
                  const pathRecord = foundRecord as Path;
                  const startNode = pathRecord.start;
                  const endNode = pathRecord.end;

                  const startNodeResponse = mapNodeFromRecord(
                    startNode,
                    resultNodes,
                    updatedNodeTags,
                    updatedPropertyTags,
                  );

                  resultNodes = startNodeResponse.nodes;
                  updatedNodeTags = startNodeResponse.nodeTags;
                  updatedPropertyTags = startNodeResponse.propertyTags;

                  const endNodeResponse = mapNodeFromRecord(
                    endNode,
                    resultNodes,
                    updatedNodeTags,
                    updatedPropertyTags,
                  );

                  resultNodes = endNodeResponse.nodes;
                  updatedNodeTags = endNodeResponse.nodeTags;
                  updatedPropertyTags = endNodeResponse.propertyTags;

                  pathRecord.segments.forEach(segment => {
                    const relationshipFromSegment = segment?.relationship;

                    if (relationshipFromSegment != null) {
                      if (
                        !updatedRelationshipTags.some(
                          tag => tag.label === relationshipFromSegment.type,
                        )
                      ) {
                        updatedRelationshipTags.push({
                          label: relationshipFromSegment.type,
                          backgroundColor: relationshipTagColor,
                          textColor: relationshipTagTextColor,
                        });
                      }

                      const formattedRelationship = {
                        source: relationshipFromSegment.start.toNumber(),
                        target: relationshipFromSegment.end.toNumber(),
                      };

                      if (
                        !resultLinks.some(
                          link =>
                            link.source === formattedRelationship.source &&
                            link.target === formattedRelationship.target,
                        )
                      )
                        resultLinks.push(formattedRelationship);
                    }
                  });

                  break;
                case 'Relationship':
                  const relationshipRecord = foundRecord as Relationship;

                  if (
                    !updatedRelationshipTags.some(
                      tag => tag.label === relationshipRecord.type,
                    )
                  ) {
                    updatedRelationshipTags.push({
                      label: relationshipRecord.type,
                      backgroundColor: relationshipTagColor,
                      textColor: relationshipTagTextColor,
                    });
                  }

                  const formattedRelationship = {
                    source: relationshipRecord.start.toNumber(),
                    target: relationshipRecord.end.toNumber(),
                  };

                  if (
                    !resultLinks.some(
                      link =>
                        link.source === formattedRelationship.source &&
                        link.target === formattedRelationship.target,
                    )
                  )
                    resultLinks.push(formattedRelationship);
                  break;
              }
            }
          });
        });
      }

      setNodes(resultNodes);
      setLinks(resultLinks);
      setNodeTags(updatedNodeTags);
      setRelationshipTags(updatedRelationshipTags);
      setPropertyTags(updatedPropertyTags);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      setMappingDataLoading,
      setClickedNode,
      setNodeTags,
      setNodes,
      setLinks,
      setRelationshipTags,
      setPropertyTags,
    ],
  );

  const handleCypherHelpClick = useCallback(() => {
    setModalIsOpen(true);
  }, [setModalIsOpen]);

  useEffect(() => {
    if (!loading && result != null && graphData == null) {
      //@ts-ignore
      mapResultsToGraphData(result);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, loading]);

  useEffect(() => {
    if (nodes != null && links != null) {
      setGraphData({ nodes, links });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, links]);

  useEffect(() => {
    if (graphData != null) setMappingDataLoading(false);
  }, [graphData]);

  return (
    <KnowledgeGraphPageContainer>
      <KnowledgeGraphHeaderContainer>
        <Typography variant="h4" gutterBottom>
          {selectedKnowledgeGraph?.xngName ?? 'Knowledge Graph'}
        </Typography>
      </KnowledgeGraphHeaderContainer>

      {selectedKnowledgeGraph != null && (
        <DatabaseSummary
          disabled={pageStatus === 'loading'}
          nodeTags={selectedKnowledgeGraph.nodeTags}
          relationshipTags={selectedKnowledgeGraph.relationshipTags}
          propertyTags={selectedKnowledgeGraph.propertyTags}
          onNodeClick={handleNodeTagClick}
          onRelationshipClick={handleRelationshipTagClick}
          onPropertyClick={handlePropertyTagClick}
        />
      )}

      <CypherInputContainer>
        <HelperText
          variant="body2"
          gutterBottom
          onClick={handleCypherHelpClick}
        >
          Cypher Help
        </HelperText>
        <CypherInput
          id="cypher-text-input"
          variant="outlined"
          label="Neo4j Cypher Query"
          placeholder={'MATCH(n:NodeName) RETURN n'}
          multiline
          value={queryInput}
          error={queryInputError}
          helperText={
            queryInputError ? 'Please ensure query is not empty' : undefined
          }
          disabled={pageStatus === 'loading'}
          inputRef={cypherInputRef}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setQueryInput(event.target.value);
          }}
          onKeyDown={handleInputKeyDown}
          InputProps={{
            endAdornment:
              pageStatus === 'loading' ? (
                <CircularProgress
                  color="primary"
                  size="2.5rem"
                  style={{ marginRight: '0.5rem' }}
                />
              ) : (
                <Tooltip title="Run Query">
                  <IconButton onClick={handleRunQuery}>
                    <FaRegCirclePlay color={theme.palette.primary.main} />
                  </IconButton>
                </Tooltip>
              ),
          }}
        />
      </CypherInputContainer>

      {pageStatus === 'loading' && (
        <KnowledgeGraphVisualizationSkeletonLoaders />
      )}

      {pageStatus === 'only links' && (
        <WarningMessageBox>
          <WarningMessage>
            That query returned only relationships. The graph must contain nodes
            to render.
          </WarningMessage>
        </WarningMessageBox>
      )}

      {pageStatus === 'good data but empty' && (
        <WarningMessageBox>
          <WarningMessage>
            That query returned nothing. Please ensure you are querying nodes
            that are in the database.
          </WarningMessage>
        </WarningMessageBox>
      )}

      {pageStatus === 'error' && (
        <WarningMessageBox>
          <WarningMessage>
            {error?.message ?? 'There was an error with that query'}
          </WarningMessage>
        </WarningMessageBox>
      )}

      {pageStatus === 'good data' &&
        graphData != null &&
        nodes != null &&
        links != null && (
          <FullGraphContainer>
            <ForceGraphContainer ref={ref}>
              <ForceGraph2D
                graphData={graphData}
                nodeLabel={'nodeLabel'}
                nodeAutoColorBy={'nodeLabel'}
                onNodeClick={node => setClickedNode(node)}
                onBackgroundClick={() => setClickedNode(undefined)}
                backgroundColor={theme.palette.background.default}
                height={height}
                width={width}
                nodeRelSize={7}
              />
            </ForceGraphContainer>

            <GraphSidebar
              clickedNode={clickedNode as KGNodeShape}
              nodeTags={nodeTags}
              relationshipTags={relationshipTags}
              propertyTags={propertyTags}
              nodesArrayLength={nodes.length}
              linksArrayLength={links.length}
              clearClickedNode={() => setClickedNode(undefined)}
            />
          </FullGraphContainer>
        )}

      <CypherHelpModal
        modalIsOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </KnowledgeGraphPageContainer>
  );
}
