import { Box, IconButton, Tooltip, Typography, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FaCirclePlus } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useReportsState } from '../../state/reportsState';
import { theme } from '../../styles/theme';
import {
  Report,
  defaultAggregatorName,
  defaultArray,
  defaultRendererName,
  defaultSortOrder,
  setUpPivotTableQueryParams,
} from '../../types/reports';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}) as typeof Box;

// const StyledFormControl = styled(FormControl, {
//   shouldForwardProp: prop => prop !== 'visible',
// })<{ visible: boolean }>(({ visible }) => ({
//   display: 'flex',
//   width: visible ? '11rem' : '2rem', // creates the wipe out effect
//   opacity: visible ? 1 : 0,
//   transition: 'all 300ms ease-in-out', // smooth transition
// }));

const StyledIconButton = styled(IconButton)({
  marginRight: theme.spacing(1),
  marginLeft: theme.spacing(1),
});

interface ReportsHeaderProps {
  fileName: string;
  showAddButton: boolean;
  fileSK?: string;
  fileClient?: string;
  fileCSVKey?: string;
}

export default function ReportsHeader({
  fileName,
  showAddButton,
  fileCSVKey,
  fileClient,
  fileSK,
}: ReportsHeaderProps) {
  const navigate = useNavigate();
  const { setSelectedReport } = useReportsState();

  const pageHeader = useMemo(() => fileName, [fileName]);
  const displayAddButton = useMemo(() => showAddButton, [showAddButton]);
  // const [showDropdown, setShowDropdown] = useState(false);
  // const [plotTypeValue, setPlotTypeValue] = useState('');

  // const handlePlusButtonClick = useCallback(() => {
  //   setShowDropdown(!showDropdown);
  // }, [showDropdown]);

  const handleNewReportClick = useCallback(() => {
    if (fileSK != null && fileClient != null && fileCSVKey != null) {
      const id = uuidv4();
      const newReport: Report = {
        name: 'Report',
        id,
        type: 'pivotTable',
        fileSK,
        csvKey: fileCSVKey,
        client: fileClient,
        PK: `${fileClient}#REPORT`,
        SK: `${fileSK}#${id}`,
        rows: defaultArray,
        cols: defaultArray,
        rowOrder: defaultSortOrder,
        colOrder: defaultSortOrder,
        rendererName: defaultRendererName,
        aggregatorName: defaultAggregatorName,
      };

      // the plot uses query params as state variables, so set those up here
      const queryParams = setUpPivotTableQueryParams(newReport);

      setSelectedReport(newReport);
      // navigate(`${newReport.id}/plot${queryParams}`);
      navigate(`${newReport.id}/pivotTable${queryParams}`);
    } else {
      toast.error('Sorry there was a problem creating your report');
    }
  }, [fileCSVKey, fileClient, fileSK, navigate, setSelectedReport]);

  return (
    <HeaderContainer>
      <Typography variant="h4">{pageHeader}</Typography>
      {displayAddButton && (
        <Tooltip title="Create New Report" placement="top" arrow>
          <StyledIconButton
            aria-label="create report"
            color="secondary"
            onClick={handleNewReportClick}
          >
            <FaCirclePlus size="2rem" />
          </StyledIconButton>
        </Tooltip>
      )}

      {/* <StyledFormControl
        visible={showDropdown}
        disabled={!showDropdown}
        size="small"
      >
        <InputLabel id="new-report-type-select-label">
          New Report Type
        </InputLabel>
        <Select
          labelId="new-report-type-select-label"
          id="new-report-type-select"
          label="New Report Type"
          value={plotTypeValue}
        >
          {PLOT_TYPES_ARRAY.map(plotType => (
            <MenuItem
              key={plotType}
              value={plotType}
              onClick={() => {
                setPlotTypeValue(plotType);
                handleNewReportClick(plotType);
              }}
            >
              {capitalize(plotType)}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl> */}
    </HeaderContainer>
  );
}
