import { Typography, styled } from '@mui/material';
import { memo, useMemo } from 'react';
import { VscCombine } from 'react-icons/vsc';
import { Handle, Position } from 'reactflow';
import { useModelingState } from '../../state/modelingState';
import { NodeContainer } from '../../styles/nodeStyles';
import { theme } from '../../styles/theme';
import { KnowledgeGraphNodeData } from '../../types/nodes';
import DeleteNodeButton from './DeleteNodeButton';

// TODO: update this position stuff there's probably a better way
const NodeNameTypography = styled(Typography)({
  position: 'absolute',
  top: 40,
  left: -43,
  textAlign: 'center',
  width: '8rem',
  overflowWrap: 'break-word',
  display: 'inline',
}) as typeof Typography;

export default memo((props: { data: KnowledgeGraphNodeData }) => {
  const { selectedNode } = useModelingState();

  const nodeName = useMemo(
    () => props.data.nodeName,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props, selectedNode],
  );

  return (
    <div>
      <NodeContainer>
        <DeleteNodeButton nodeId={props.data.objId} />

        <VscCombine size="2rem" color={theme.palette.text.primary} />

        <Handle type="target" position={Position.Left} isConnectable={true} />

        <NodeNameTypography variant="caption">{nodeName}</NodeNameTypography>
      </NodeContainer>
    </div>
  );
});
