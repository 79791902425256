import { ButtonBase, styled, Tooltip, Typography } from '@mui/material';
import { memo } from 'react';
import { GraphTag } from '../../types/knowledgeGraph';

const TagBox = styled(ButtonBase, {
  shouldForwardProp: prop => prop !== 'pointerCursor',
})<{ pointerCursor: boolean }>(({ pointerCursor }) => ({
  width: 'fit-content',
  borderRadius: '1rem',
  padding: '2px 0.5rem',
  overflow: 'hidden',
  cursor: pointerCursor ? 'pointer' : 'default',
}));

interface NodeTagProps extends GraphTag {
  disableToolTip?: boolean;
  onClick?: () => void;
}

export default memo(
  ({
    label,
    backgroundColor,
    textColor,
    disableToolTip,
    onClick,
  }: NodeTagProps) => {
    return (
      <Tooltip title={label} disableHoverListener={disableToolTip}>
        <TagBox
          sx={{ backgroundColor }}
          onClick={onClick}
          pointerCursor={onClick != null}
          disableRipple={onClick == null}
        >
          <Typography
            color={textColor}
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {label}
          </Typography>
        </TagBox>
      </Tooltip>
    );
  },
);
