import {
  IconButton,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FaPlay, FaTrash } from 'react-icons/fa';
import { theme } from '../../styles/theme';
import { KnowledgeGraphColumn, RowData } from '../../types/knowledgeGraphTable';
import { useNavigate } from 'react-router-dom';
import { KnowledgeGraphData } from '../../types/knowledgeGraph';
import { useKnowledgeGraphState } from '../../state/knowledgeGraphState';

interface KnowledgeGraphTableBodyProps {
  columns: readonly KnowledgeGraphColumn[];
  visibleRowsProp: RowData[];
}

export default function KnowledgeGraphTableBody({
  columns,
  visibleRowsProp,
}: KnowledgeGraphTableBodyProps) {
  const visibleRows = useMemo(() => visibleRowsProp, [visibleRowsProp]);

  const navigate = useNavigate();
  const { setSelectedKnowledgeGraph } = useKnowledgeGraphState();

  const handleVisualizeKGClick = useCallback(
    (knowledgeGraph: KnowledgeGraphData) => {
      setSelectedKnowledgeGraph(knowledgeGraph);
      navigate(
        `/knowledgeGraph/visualize/${encodeURIComponent(knowledgeGraph.SK)}`,
      );
    },
    [navigate, setSelectedKnowledgeGraph],
  );

  const handleDeleteKGClick = useCallback(
    (knowledgeGraph: KnowledgeGraphData) => {
      //TODO: RKEP-178
      console.log('DELETE', { knowledgeGraph });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate, setSelectedKnowledgeGraph],
  );

  const mapNames = (names: string[]): string => {
    let output = '';
    names.forEach((nameString, index) => {
      if (index === 0) {
        output = nameString;
      } else {
        output += `, ${nameString}`;
      }
    });

    return output;
  };

  return (
    <TableBody>
      {visibleRows.map((rowData, index) => {
        return (
          <TableRow
            tabIndex={-1}
            key={rowData.knowledgeGraphName}
            sx={{
              backgroundColor:
                index % 2 === 0 ? 'none' : theme.palette.grey[100],
            }}
          >
            {/* Knowledge Graph Name Column */}
            <TableCell key={columns[0].id} align={columns[0].align}>
              {rowData.knowledgeGraphName}
            </TableCell>

            {/* Client Name Column */}
            <TableCell key={columns[1].id} align={columns[1].align}>
              {rowData.client}
            </TableCell>

            {/* Node Names Column */}
            <TableCell key={columns[2].id} align={columns[2].align}>
              {mapNames(rowData.nodeNames)}
            </TableCell>

            {/* Relationship Names Column */}
            <TableCell key={columns[3].id} align={columns[3].align}>
              {mapNames(rowData.relationshipNames)}
            </TableCell>

            {/* Actions Column */}
            <TableCell
              key={columns[4].id}
              align={columns[4].align}
              width="fit-content"
              sx={{ justifyContent: 'center' }}
            >
              <Tooltip title="Visualize Knowledge Graph" placement="top" arrow>
                <IconButton
                  aria-label="visualize knowledge graph"
                  onClick={() =>
                    handleVisualizeKGClick(rowData.knowledgeGraphData)
                  }
                >
                  <FaPlay size="1rem" />
                </IconButton>
              </Tooltip>

              <Tooltip title="Delete Knowledge Graph" placement="top" arrow>
                <IconButton
                  aria-label="delete"
                  onClick={() =>
                    handleDeleteKGClick(rowData.knowledgeGraphData)
                  }
                >
                  <FaTrash size="1rem" />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        );
      })}
    </TableBody>
  );
}
