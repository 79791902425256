import { NodeObject } from 'react-force-graph-2d';
import { theme } from '../styles/theme';
import { lighten } from '@mui/material';

export interface GraphTag {
  label: string;
  backgroundColor: string;
  textColor: string;
}

export interface KGNodeShape extends NodeObject {
  properties: any;
  nodeLabel: string;
}

export interface KnowledgeGraphData {
  PK: string;
  SK: string;
  clntDbName: string; // database name within the neo4j instance
  clntURI: string; //neo4j database uri
  clntUserKey: string; //neo4j database user key
  cltnPwKey: string; //neo4j databse password
  nodeName: string[]; // array of all node names in the database
  nodeProps: string[][]; // array of all properties on all nodes in the database
  processFlowName: string;
  processFlowSK: string;
  rels: string[]; // array of all relationship names in the database
  relsCypher: string[]; // array of the cypher needed to get the relationships
  relsSourceKeys: string[]; // array of the property names attached to the source nodes of the relationship
  relsTargetKeys: string[]; // array of the property names attached to the target nodes of the relationship
  sourceRel: string[]; // array of source nodes for the relationships
  targetRel: string[]; // array of target nodes for the relationships
  xngName: string; // knowledge graph name
  nodeTags: GraphTag[];
  relationshipTags: GraphTag[];
  propertyTags: GraphTag[];
}

export interface Neo4jProfile {
  PK: string;
  SK: string; // all caps client name
  auraEmail: string;
  auraPW: string;
  neo4jPW: string;
  neo4jURI: string;
  neo4jUser: string;
}

export const relationshipTagColor = theme.palette.text.disabled;
export const relationshipTagTextColor =
  theme.palette.getContrastText(relationshipTagColor);
export const propertyTagColor = lighten(theme.palette.text.primary, 0.1);
export const propertyTagTextColor =
  theme.palette.getContrastText(propertyTagColor);
