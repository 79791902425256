import { Box, styled } from '@mui/material';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReportsState } from '../../state/reportsState';
import {
  Report,
  defaultAggregatorName,
  defaultArray,
  defaultRendererName,
  defaultSortOrder,
  setUpPivotTableQueryParams,
} from '../../types/reports';
import ReportTile, { CARD_WIDTH } from './reportTile';

const defaultDataGridReport: Report = {
  name: 'Data Grid',
  rows: defaultArray,
  cols: defaultArray,
  type: 'dataGrid',
  id: '',
  csvKey: '',
  fileSK: '',
  client: '',
  PK: '',
  SK: '',
  aggregatorName: defaultAggregatorName,
  rendererName: defaultRendererName,
  rowOrder: defaultSortOrder,
  colOrder: defaultSortOrder,
};

export const ReportsGridContainer = styled(Box)({
  gridTemplateColumns: `repeat(auto-fill, minmax(${CARD_WIDTH}rem, 1fr))`,
  gap: '2rem',
  height: 'fit-content',
  width: '100%',
  display: 'grid',
}) as typeof Box;

export default function ReportsContent() {
  const { setSelectedReport, availableReports, selectedFileInfoForS3 } =
    useReportsState();
  const navigate = useNavigate();

  const dataGridReport: Report = useMemo(() => {
    return {
      ...defaultDataGridReport,
      csvKey: selectedFileInfoForS3?.fileData ?? '',
      fileSK: selectedFileInfoForS3?.fileSK ?? '',
      client: selectedFileInfoForS3?.fileClient ?? '',
    };
  }, [selectedFileInfoForS3]);

  const handleReportTileClick = (clickedReport: Report) => {
    setSelectedReport(clickedReport);

    if (clickedReport.type === 'dataGrid') {
      navigate('dataGrid');
    } else {
      // the plot uses query params as state variables, so set those up here
      const queryParams = setUpPivotTableQueryParams(clickedReport);
      navigate(`${clickedReport.id}/pivotTable${queryParams}`);
    }
  };

  if (availableReports != null) {
    return (
      <ReportsGridContainer>
        <ReportTile
          key={dataGridReport.id}
          {...dataGridReport}
          handleClick={() => handleReportTileClick(dataGridReport)}
        />
        {availableReports.map(report => (
          <ReportTile
            key={`${report.id}-${report.name}`}
            {...report}
            handleClick={() => handleReportTileClick(report)}
          />
        ))}
      </ReportsGridContainer>
    );
  }

  return <></>;
}
