import { GridColDef } from '@mui/x-data-grid';
import { create } from 'zustand';
import { getAllReportsService } from '../services/reportsService';
import { FileInfoForS3, Report } from '../types/reports';

export interface ReportsState {
  availableReports: Report[] | undefined;
  selectedReport: Report | undefined;
  getAllReportsIsLoading: boolean;
  selectedData: any[] | undefined;
  selectedDataColumns: GridColDef[] | undefined;
  selectedFileName: string | undefined;
  selectedFileInfoForS3: FileInfoForS3 | undefined;
  selectedFileCSVKey: string | undefined;
  reportsIsLoading: boolean;
  reportsErrorMessage: string | undefined;

  setSelectedReport: (report: Report | undefined) => void;
  setSelectedData: (data: any[] | undefined) => void;
  setSelectedDataColumns: (columns: GridColDef[] | undefined) => void;
  setSelectedFileName: (fileName: string | undefined) => void;
  setSelectedFileInfoForS3: (fileInfoForS3: FileInfoForS3 | undefined) => void;
  setSelectedFileCSVKey: (csvKey: string | undefined) => void;
  getAvailableReports: (clientName: string, fileSK: string) => void;
  setReportsIsLoading: (isLoading: boolean) => void;
  setReportsErrorMessage: (message: string | undefined) => void;
  clearReportsState: () => void;
}

export const useReportsState = create<ReportsState>(set => ({
  availableReports: undefined,
  selectedReport: undefined,
  getAllReportsIsLoading: false,
  selectedData: undefined,
  selectedDataColumns: undefined,
  selectedFileName: undefined,
  selectedFileInfoForS3: undefined,
  selectedFileCSVKey: undefined,
  reportsIsLoading: false,
  reportsErrorMessage: undefined,

  setSelectedData: (data: any[] | undefined) => {
    set({ selectedData: data });
  },
  setSelectedDataColumns: (columns: GridColDef[] | undefined) => {
    set({ selectedDataColumns: columns });
  },
  setSelectedReport: (report: Report | undefined) => {
    set({ selectedReport: report });
  },
  setSelectedFileName: (fileName: string | undefined) => {
    set({ selectedFileName: fileName });
  },
  setSelectedFileInfoForS3: (fileInfoForS3: FileInfoForS3 | undefined) => {
    set({ selectedFileInfoForS3: fileInfoForS3 });
  },
  setSelectedFileCSVKey: (csvKey: string | undefined) => {
    set({ selectedFileCSVKey: csvKey });
  },
  setReportsIsLoading: (isLoading: boolean) => {
    set({ reportsIsLoading: isLoading });
  },
  setReportsErrorMessage: (message: string | undefined) => {
    set({ reportsErrorMessage: message });
  },

  getAvailableReports: async (clientName: string, fileSK: string) => {
    set({ getAllReportsIsLoading: true, availableReports: undefined });
    try {
      const getAllReportsResponse = await getAllReportsService(
        clientName,
        fileSK,
      );

      const isGoodResponse = typeof getAllReportsResponse !== typeof '';

      set({
        availableReports: isGoodResponse
          ? (getAllReportsResponse as Report[])
          : undefined,
        getAllReportsIsLoading: false,
      });
    } catch (error) {
      set({ getAllReportsIsLoading: false });
      console.error('getAllReports Error: ', error);
    }
  },

  clearReportsState: () => {
    set({
      availableReports: undefined,
      selectedReport: undefined,
      getAllReportsIsLoading: false,
      selectedData: undefined,
      selectedDataColumns: undefined,
      selectedFileName: undefined,
      selectedFileInfoForS3: undefined,
      selectedFileCSVKey: undefined,
      reportsIsLoading: false,
      reportsErrorMessage: undefined,
    });
  },
}));
