import {
  Box,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  lighten,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Tooltip,
} from '@mui/material';
import { memo, useCallback, useEffect, useMemo } from 'react';
import { FaInfoCircle, FaLongArrowAltRight } from 'react-icons/fa';
import { FaTrash } from 'react-icons/fa6';
import { SingleGapRowFlex } from '../../../styles/commonStyles';
import { theme } from '../../../styles/theme';
import { Neo4jNodeFormat, Relationship } from '../../../types/nodes';

const SectionContainer = styled(Card)({
  minHeight: 'fit-content',
  marginBottom: theme.spacing(3),
  backgroundColor: lighten(theme.palette.background.default, 0.5),
  padding: `${theme.defaultPaddingRem} ${theme.spacing(1)} 0 ${theme.spacing(
    1,
  )}`,
}) as typeof Card;

const SelectInputsContainer = styled(Box)({
  gridTemplateColumns: '1fr 2rem 1fr',
  display: 'grid',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(1),
  alignItems: 'center',
}) as typeof Box;

const BottomSectionContainer = styled(Box)({
  gridTemplateColumns: 'repeat(2, minmax(9.375rem, 1fr)) 3rem',
  display: 'grid',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  alignItems: 'start',
}) as typeof Box;

const StyledFormControl = styled(FormControl)({
  display: 'flex',
  minWidth: '9.375rem',
  backgroundColor: theme.palette.surface.default,
}) as typeof FormControl;

const StyledTextField = styled(TextField)`
  width: inherit;

  input {
    background-color: ${theme.palette.surface.default};
  }
`;

const StyledIconButton = styled(IconButton)({
  display: 'flex',
  minWidth: '2rem',
  maxWidth: '3rem',
}) as typeof IconButton;

const getFieldsFromNode = (
  selectedNodeName: string,
  nodes: Neo4jNodeFormat[],
): string[] => {
  const nodeIndex = nodes.findIndex(node => selectedNodeName === node.nodeName);

  if (nodeIndex > -1) {
    return nodes[nodeIndex].renamedFields.map(
      renamedField => renamedField.newName,
    );
  }

  return [];
};

export interface RelationshipSectionProps extends Relationship {
  availableNodes: Neo4jNodeFormat[];
  handleUpdate: (
    id: string,
    type:
      | 'sourceNode'
      | 'targetNode'
      | 'sourceField'
      | 'targetField'
      | 'name'
      | 'attachedFields',
    newValue: any,
  ) => void;
  handleDelete: (id: string) => void;
  handleUpdateErrors: (id: string, hasError: boolean) => void;
}

export default memo(
  ({
    id,
    handleUpdate,
    handleDelete,
    handleUpdateErrors,
    relationshipName,
    sourceNodeName,
    targetNodeName,
    sourceField,
    targetField,
    availableNodes,
    attachedTargetFields,
  }: RelationshipSectionProps) => {
    const relationshipNameMemo = useMemo(
      () => relationshipName,
      [relationshipName],
    );

    const sourceNodeNameMemo = useMemo(() => sourceNodeName, [sourceNodeName]);
    const targetNodeNameMemo = useMemo(() => targetNodeName, [targetNodeName]);
    const sourceFieldName = useMemo(() => sourceField, [sourceField]);
    const targetFieldName = useMemo(() => targetField, [targetField]);
    const availableNodesMemo = useMemo(() => availableNodes, [availableNodes]);
    const attachedFields = useMemo(
      () => attachedTargetFields,
      [attachedTargetFields],
    );

    const availableSourceFields = useMemo(
      () => getFieldsFromNode(sourceNodeNameMemo, availableNodesMemo),
      [sourceNodeNameMemo, availableNodesMemo],
    );
    const availableTargetFields = useMemo(
      () => getFieldsFromNode(targetNodeNameMemo, availableNodesMemo),
      [targetNodeNameMemo, availableNodesMemo],
    );

    // ---------- FORM VALIDATION ----------
    const relationshipNameError = useMemo(
      () => /[^A-Za-z0-9_]/.test(relationshipNameMemo),
      [relationshipNameMemo],
    );

    const doesRelationshipHaveError = useCallback(() => {
      return (
        relationshipNameMemo === '' ||
        relationshipNameError ||
        sourceNodeNameMemo === '' ||
        targetNodeNameMemo === '' ||
        sourceFieldName === '' ||
        targetFieldName === ''
      );
    }, [
      relationshipNameMemo,
      relationshipNameError,
      sourceNodeNameMemo,
      targetNodeNameMemo,
      sourceFieldName,
      targetFieldName,
    ]);

    const relationshipHasError = useMemo(
      () => doesRelationshipHaveError(),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [
        relationshipNameMemo,
        relationshipNameError,
        sourceNodeNameMemo,
        targetNodeNameMemo,
        sourceFieldName,
        targetFieldName,
      ],
    );

    // clear the target node if the user selects the same node as the source node
    useEffect(() => {
      if (sourceNodeNameMemo === targetNodeNameMemo) {
        handleUpdate(id, 'targetNode', '');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceNodeNameMemo, targetNodeNameMemo]);

    useEffect(() => {
      handleUpdateErrors(id, relationshipHasError);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relationshipHasError]);

    return (
      <SectionContainer>
        {/* SELECT THE NODES */}
        <SelectInputsContainer>
          <StyledFormControl>
            <InputLabel id="source-node-name-select-label">
              Source Node Name
            </InputLabel>
            <Select
              labelId="source-node-name-select-label"
              id="source-node-name-select"
              label="Source Node Name"
              value={sourceNodeNameMemo}
              onChange={(event: SelectChangeEvent) => {
                handleUpdate(id, 'sourceNode', event.target.value);
              }}
            >
              {availableNodesMemo.map(node => (
                <MenuItem value={node.nodeName}>{node.nodeName}</MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <Tooltip title="Neo4j will find all source nodes and connect them to target nodes if the selected source and target fields are equal">
            <IconButton sx={{ height: 32, padding: 0 }}>
              <FaLongArrowAltRight
                color={theme.palette.text.primary}
                size={32}
              />
            </IconButton>
          </Tooltip>

          <StyledFormControl>
            <InputLabel id="target-node-name-select-label">
              Target Node Name
            </InputLabel>
            <Select
              labelId="target-node-name-select-label"
              id="target-node-name-select"
              label="Target Node Name"
              value={targetNodeNameMemo}
              onChange={(event: SelectChangeEvent) => {
                handleUpdate(id, 'targetNode', event.target.value);
              }}
            >
              {availableNodesMemo.map(node => (
                <MenuItem
                  value={node.nodeName}
                  disabled={node.nodeName === sourceNodeNameMemo}
                >
                  {node.nodeName}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </SelectInputsContainer>

        {/* SELECT THE FIELDS */}
        <SelectInputsContainer
          sx={{
            marginTop: theme.defaultPaddingRem,
            marginBottom: theme.defaultPaddingRem,
          }}
        >
          <StyledFormControl>
            <InputLabel id="source-field-select-label">Source Field</InputLabel>
            <Select
              labelId="source-field-select-label"
              id="source-field-select"
              label="Source Field"
              value={sourceFieldName}
              onChange={(event: SelectChangeEvent) => {
                handleUpdate(id, 'sourceField', event.target.value);
              }}
            >
              {availableSourceFields.map(field => (
                <MenuItem value={field}>{field}</MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <Tooltip title="The Relationship will be created by searching for target fields equal to the source field">
            <IconButton sx={{ height: 32, padding: 0 }}>
              <FaLongArrowAltRight
                color={theme.palette.text.primary}
                size={32}
              />
            </IconButton>
          </Tooltip>

          <StyledFormControl>
            <InputLabel id="target-field-select-label">Target Field</InputLabel>
            <Select
              labelId="target-field-select-label"
              id="target-field-select"
              label="Target Field"
              value={targetFieldName}
              onChange={(event: SelectChangeEvent) => {
                handleUpdate(id, 'targetField', event.target.value);
              }}
            >
              {availableTargetFields.map(field => (
                <MenuItem value={field}>{field}</MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </SelectInputsContainer>

        {/* RELATIONSHIP NAME AND ATTACHED FIELDS */}
        <BottomSectionContainer>
          <SingleGapRowFlex sx={{ width: 'inherit' }}>
            <StyledTextField
              variant="outlined"
              label="Relationship Name"
              placeholder="MY_CUSTOM_NAME"
              value={relationshipNameMemo}
              helperText={
                relationshipNameError
                  ? 'Please only use letters, numbers, and underscores'
                  : ' '
              }
              error={relationshipNameError}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdate(id, 'name', event.target.value);
              }}
            />

            <Tooltip title="This is the name that will be applied to the Relationship when it is created in the Knowledge Graph. Neo4j recommends using all caps and underscores as the naming convention. Ex: MY_CUSTOM_NAME">
              <IconButton sx={{ height: 24, padding: 0, marginTop: '1rem' }}>
                <FaInfoCircle size={20} color={theme.palette.secondary.main} />
              </IconButton>
            </Tooltip>
          </SingleGapRowFlex>

          <SingleGapRowFlex sx={{ width: 'inherit' }}>
            <StyledFormControl sx={{ width: 'inherit' }}>
              <InputLabel id="attached-fields-select-label">
                Attached Fields
              </InputLabel>
              <Select
                labelId="attached-fields-select-label"
                id="attached-fields-select"
                label="Attached Fields"
                multiple
                value={attachedFields}
                renderValue={selected => selected.join(', ')}
                onChange={(event: SelectChangeEvent<typeof attachedFields>) => {
                  const {
                    target: { value },
                  } = event;

                  const newValue =
                    typeof value === 'string' ? value.split(',') : value;

                  handleUpdate(id, 'attachedFields', newValue);
                }}
              >
                <ListSubheader>{sourceNodeNameMemo}</ListSubheader>
                {availableSourceFields.map(field => (
                  <MenuItem value={field}>{field}</MenuItem>
                ))}
                <ListSubheader>{targetNodeNameMemo}</ListSubheader>
                {availableTargetFields.map(field => (
                  <MenuItem value={field}>{field}</MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <Tooltip title="These are the fields that will be attched to the Relationship object.">
              <IconButton sx={{ height: 24, padding: 0, marginTop: '1rem' }}>
                <FaInfoCircle size={20} color={theme.palette.secondary.main} />
              </IconButton>
            </Tooltip>
          </SingleGapRowFlex>

          <StyledIconButton
            aria-label="delete"
            className="delete-node-button"
            onClick={() => {
              handleDelete(id);
            }}
          >
            <FaTrash
              size="2rem"
              color={theme.palette.error.main}
              style={{ display: 'inherit' }}
            />
          </StyledIconButton>
        </BottomSectionContainer>
      </SectionContainer>
    );
  },
);
