import { Box, styled, Typography } from '@mui/material';
import { ColumnFlex } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { GraphTag, KGNodeShape } from '../../types/knowledgeGraph';
import KnowledgeGraphNodeDescription from './nodeDescription';
import NodeTag from './nodeTag';
import PropertyTag from './propertyTag';
import RelationshipTag from './relationshipTag';

export const GraphSummaryContainer = styled(ColumnFlex)({
  padding: theme.defaultPaddingRem,
  gap: theme.defaultPaddingRem,
  width: `calc(100% - ${theme.defaultPaddingRem} - ${theme.defaultPaddingRem})`,
  overflowY: 'scroll',
});

export const GraphSidebarContainer = styled(Box)({
  height: '100%',
  width: '20%',
  display: 'flex',
}) as typeof Box;

export const TagContainer = styled(Box)({
  display: 'flex',
  height: 'fit-content',
  flexDirection: 'row',
  gap: '1rem',
  flexWrap: 'wrap',
});

const getNodeTagFromNode = (
  clickedNode: KGNodeShape,
  tags: GraphTag[],
): GraphTag => {
  const defaultTag: GraphTag = {
    label: 'DEFAULT',
    backgroundColor: '#000000',
    textColor: '#ffffff',
  };

  const foundTag = tags.find(tag => tag.label === clickedNode.nodeLabel);

  if (foundTag != null) return foundTag;

  return defaultTag;
};

export interface GraphSidebarProps {
  clickedNode: KGNodeShape;
  nodeTags: GraphTag[];
  relationshipTags: GraphTag[];
  propertyTags: GraphTag[];
  nodesArrayLength: number;
  linksArrayLength: number;
  clearClickedNode: () => void;
}

export default function GraphSidebar({
  clickedNode,
  nodeTags,
  relationshipTags,
  propertyTags,
  nodesArrayLength,
  linksArrayLength,
  clearClickedNode,
}: GraphSidebarProps) {
  return (
    <GraphSidebarContainer>
      {clickedNode == null && (
        <GraphSummaryContainer>
          <Typography variant="h5" fontWeight="bold" textOverflow="ellipsis">
            Graph Summary
          </Typography>

          <Typography variant="body1">{`Nodes (${nodesArrayLength})`}</Typography>
          <TagContainer>
            {nodeTags
              .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
              .map(tag => (
                <NodeTag {...tag} key={`${tag.label}`} />
              ))}
          </TagContainer>

          <Typography variant="body1">
            {`Relationships (${linksArrayLength})`}
          </Typography>
          <TagContainer>
            {relationshipTags
              .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
              .map(tag => (
                <RelationshipTag {...tag} key={`${tag.label}`} />
              ))}
          </TagContainer>

          <Typography variant="body1">
            {`Property Keys (${propertyTags.length})`}
          </Typography>
          <TagContainer>
            {propertyTags
              .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
              .map(tag => (
                <PropertyTag {...tag} key={`${tag.label}`} />
              ))}
          </TagContainer>
        </GraphSummaryContainer>
      )}
      {clickedNode != null && (
        <KnowledgeGraphNodeDescription
          clickedNode={clickedNode as KGNodeShape}
          nodeTag={getNodeTagFromNode(clickedNode as KGNodeShape, nodeTags)}
          exitMenu={clearClickedNode}
        />
      )}
    </GraphSidebarContainer>
  );
}
