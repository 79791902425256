import { Skeleton, styled } from '@mui/material';

import { StyledSkeleton } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  GraphSidebarContainer,
  GraphSummaryContainer,
  TagContainer,
} from './graphSidebar';
import { useMemo } from 'react';
import { ForceGraphContainer, FullGraphContainer } from './pageBody';

const CanvasSkeleton = styled(Skeleton)({
  borderTopLeftRadius: theme.borderRadiusRem,
  borderBottomLeftRadius: theme.borderRadiusRem,
});

export const NodeTagSkeleton = styled(Skeleton)({
  borderRadius: '1rem',
  height: '1.5rem',
});

export const RelationshipTagSkeleton = styled(Skeleton)({
  clipPath: 'polygon(5% 0%, 95% 0%, 100% 50%, 95% 100%, 5% 100%, 0% 50%)',
  height: '1.5rem',
});

export const PropertyTagSkeleton = styled(Skeleton)({
  borderRadius: '0.25rem',
  height: '1.5rem',
});

export function randomIntFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export default function KnowledgeGraphVisualizationSkeletonLoaders() {
  const nodeTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(3, 8) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(20, 35)}%`;
        return (
          <NodeTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  const relationshipTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(2, 6) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(40, 55)}%`;
        return (
          <RelationshipTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  const propertyTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(12, 18) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(15, 75)}%`;
        return (
          <PropertyTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  return (
    <FullGraphContainer>
      <ForceGraphContainer>
        <CanvasSkeleton
          variant="rectangular"
          animation="wave"
          height="100%"
          width="100%"
        />
      </ForceGraphContainer>
      <GraphSidebarContainer>
        <GraphSummaryContainer sx={{ overflowY: 'hidden' }}>
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="2rem"
            width="80%"
          />

          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1rem"
            width="30%"
          />
          <TagContainer sx={{ marginBottom: theme.defaultPaddingRem }}>
            {nodeTagSkeletons}
          </TagContainer>

          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1rem"
            width="35%"
          />
          <TagContainer sx={{ marginBottom: theme.defaultPaddingRem }}>
            {relationshipTagSkeletons}
          </TagContainer>

          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1rem"
            width="35%"
          />
          <TagContainer>{propertyTagSkeletons}</TagContainer>
        </GraphSummaryContainer>
      </GraphSidebarContainer>
    </FullGraphContainer>
  );
}
