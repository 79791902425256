import { create } from 'zustand';

import { ResponseTypes } from '../types/api';
import {
  GraphTag,
  KnowledgeGraphData,
  Neo4jProfile,
  propertyTagColor,
  propertyTagTextColor,
  relationshipTagColor,
  relationshipTagTextColor,
} from '../types/knowledgeGraph';
import { RowData as KnowledgeGraphRowData } from '../types/knowledgeGraphTable';
import {
  getAvailableKnowledgeGraphsFromClient,
  getNeo4jProfileFromClient,
} from '../services/knowledgeGraphService';
import { chartColors } from '../styles/colors';
import { theme } from '../styles/theme';

export interface KnowledgeGraphState {
  selectedKnowledgeGraph: KnowledgeGraphData | undefined;
  knowledgeGraphSaving: boolean;
  availableKnowledgeGraphs: KnowledgeGraphData[] | undefined;
  formattedKnowledgeGraphs: KnowledgeGraphRowData[] | undefined;
  getAvailableKnowledgeGraphsIsLoading: boolean;
  getAvailableKnowledgeGraphsError: boolean;
  knowledgeGraphTableQueryParams: string;

  selectedNeo4jProfile: Neo4jProfile | undefined;
  getNeo4jProfileIsLoading: boolean;
  getNeo4jProfileError: boolean;

  setSelectedKnowledgeGraph: (
    knowledgeGraph: KnowledgeGraphData | undefined,
  ) => void;
  setAvailableKnowledgeGraphs: (
    knowledgeGraphs: KnowledgeGraphData[] | undefined,
  ) => void;
  getAvailableKnowledgeGraphs: (clientName: string) => void;
  setKnowledgeGraphTableQueryParams: (queryString: string) => void;

  getNeo4jProfile: (clientName: string) => void;
  setSelectedNeo4jProfile: (profile: Neo4jProfile | undefined) => void;
}

export const useKnowledgeGraphState = create<KnowledgeGraphState>(set => ({
  selectedKnowledgeGraph: undefined,
  knowledgeGraphSaving: false,
  availableKnowledgeGraphs: undefined,
  formattedKnowledgeGraphs: undefined,
  getAvailableKnowledgeGraphsIsLoading: false,
  getAvailableKnowledgeGraphsError: false,
  knowledgeGraphTableQueryParams: '',

  selectedNeo4jProfile: undefined,
  getNeo4jProfileIsLoading: false,
  getNeo4jProfileError: false,

  setSelectedKnowledgeGraph: (
    knowledgeGraph: KnowledgeGraphData | undefined,
  ) => {
    set({ selectedKnowledgeGraph: knowledgeGraph });
  },
  setAvailableKnowledgeGraphs: (
    knowledgeGraphs: KnowledgeGraphData[] | undefined,
  ) => {
    set({ availableKnowledgeGraphs: knowledgeGraphs });
  },
  getAvailableKnowledgeGraphs: async (clientName: string) => {
    set({ getAvailableKnowledgeGraphsIsLoading: true });
    let updatedAvailableKnowledgeGraphs: KnowledgeGraphData[] = [];

    const getAvailableKnowledgeGraphsResponse =
      await getAvailableKnowledgeGraphsFromClient(clientName);
    const isGoodResponse =
      typeof getAvailableKnowledgeGraphsResponse !== typeof '';

    if (
      getAvailableKnowledgeGraphsResponse !== ResponseTypes.BadResponse &&
      getAvailableKnowledgeGraphsResponse !== ResponseTypes.ErrorResponse &&
      getAvailableKnowledgeGraphsResponse !== null &&
      getAvailableKnowledgeGraphsResponse?.map != null
    ) {
      updatedAvailableKnowledgeGraphs = getAvailableKnowledgeGraphsResponse.map(
        knowledgeGraph => {
          const nodeProps = knowledgeGraph.nodeProps.flatMap(
            property => property,
          );
          let updatedNodeTags: GraphTag[] = [];
          let updatedPropertyTags: GraphTag[] = [];
          let updatedRelationshipTags: GraphTag[] = [];

          knowledgeGraph.nodeName.forEach(nodeLabel => {
            // if the tag does not yet exist
            if (!updatedNodeTags.some(tag => tag.label === nodeLabel)) {
              let colorIndex = updatedNodeTags.length;

              // reset the color index if we're past the length of the colors array. This is unlikely
              if (colorIndex > chartColors.length - 1) {
                colorIndex -= chartColors.length;
              }

              const tagColor = chartColors[colorIndex];
              const textColor = theme.palette.getContrastText(tagColor);

              // add the node to the tags represented on the graph sidebar
              updatedNodeTags.push({
                label: nodeLabel,
                backgroundColor: tagColor,
                textColor,
              });
            }
          });

          // update the properties tags array with these properties represented on the graph sidebar
          nodeProps.forEach(property => {
            if (!updatedPropertyTags.some(tag => tag.label === property)) {
              updatedPropertyTags.push({
                label: property,
                backgroundColor: propertyTagColor,
                textColor: propertyTagTextColor,
              });
            }
          });

          knowledgeGraph.rels.forEach(relationshipLabel => {
            if (
              !updatedRelationshipTags.some(
                tag => tag.label === relationshipLabel,
              )
            ) {
              updatedRelationshipTags.push({
                label: relationshipLabel,
                backgroundColor: relationshipTagColor,
                textColor: relationshipTagTextColor,
              });
            }
          });

          return {
            ...knowledgeGraph,
            nodeTags: updatedNodeTags,
            relationshipTags: updatedRelationshipTags,
            propertyTags: updatedPropertyTags,
          };
        },
      );
    }

    const formattedKnowledgeGraphs: KnowledgeGraphRowData[] =
      updatedAvailableKnowledgeGraphs.map(knowledgeGraph => {
        return {
          SK: knowledgeGraph.SK,
          knowledgeGraphName: knowledgeGraph.xngName,
          client: knowledgeGraph.SK.split('#')[0],
          nodeNames: knowledgeGraph.nodeName,
          relationshipNames: knowledgeGraph.rels,
          neo4jUri: knowledgeGraph.clntURI,
          neo4jUser: knowledgeGraph.clntUserKey,
          neo4jPW: knowledgeGraph.cltnPwKey,
          action: '',
          knowledgeGraphData: knowledgeGraph,
        };
      });

    set({
      getAvailableKnowledgeGraphsIsLoading: false,
      getAvailableKnowledgeGraphsError: !isGoodResponse,
      availableKnowledgeGraphs: [...updatedAvailableKnowledgeGraphs],
      formattedKnowledgeGraphs,
    });
  },
  setKnowledgeGraphTableQueryParams: (queryString: string) => {
    set({ knowledgeGraphTableQueryParams: queryString });
  },

  getNeo4jProfile: async (clientName: string) => {
    set({ getNeo4jProfileIsLoading: true });

    const getNeo4jProfileResponse = await getNeo4jProfileFromClient(
      clientName.toUpperCase(),
    );
    const isGoodResponse = typeof getNeo4jProfileResponse !== typeof '';

    set({
      getNeo4jProfileIsLoading: false,
      getNeo4jProfileError: !isGoodResponse,
      selectedNeo4jProfile: isGoodResponse
        ? (getNeo4jProfileResponse as Neo4jProfile)
        : undefined,
    });
  },

  setSelectedNeo4jProfile: (profile: Neo4jProfile | undefined) => {
    set({ selectedNeo4jProfile: profile });
  },
}));
