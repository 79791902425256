import { QueryParamConfig, encodeNumber, encodeString } from 'use-query-params';
import { decodeNumberQueryParam } from './tables';
import { KnowledgeGraphData } from './knowledgeGraph';

export interface RowData {
  client: string;
  knowledgeGraphName: string;
  nodeNames: string[];
  relationshipNames: string[];
  SK: string;
  neo4jUri: string;
  neo4jUser: string;
  neo4jPW: string;
  knowledgeGraphData: KnowledgeGraphData;
}

export interface KnowledgeGraphColumn {
  id: keyof RowData | 'action';
  label: string;
  minWidth?: number | string;
  align?: 'right' | 'left';
}

// used for asserting the correct Typescript type when decoding query params on the KnowledgeGraph table
type RowDataKeysArrayType = Array<keyof RowData>;

// used for asserting the correct Typescript type when decoding query params on the KnowledgeGraph table
export const RowDataKeysArray: RowDataKeysArrayType = [
  'client',
  'knowledgeGraphName',
  'nodeNames',
  'relationshipNames',
];

// ========== Query Param Utils ==========

// defaults for the various filters and sorting values
// if the datalake page url is loaded with no params, the table will use these values
export const defaultRowsPerPage = 15;
export const defaultOrderBy: keyof RowData = 'knowledgeGraphName';
export const defaultShowProcessFlows = true;
export const defaultShowUploads = true;

// We have these custom decoder functions because the user-query-param library doesn't know how to handle default, null, undefined, or unexpected values so handle them here

// custom function telling use-query-params how to decode the orderBy param so typescript is happy
function decodeOrderByQueryParam(
  orderBy: string | (string | null)[] | null | undefined,
): keyof RowData {
  if (orderBy == null) return defaultOrderBy;

  if (typeof orderBy === typeof ['']) return defaultOrderBy;

  // this array contains all of the keys of RowData so we check and and see if the orderBy param is in that array
  if (RowDataKeysArray.includes(orderBy as keyof RowData)) {
    return orderBy as keyof RowData;
  }

  return defaultOrderBy;
}

export const RowsPerPageKnowledgeGraphParam: QueryParamConfig<number> = {
  encode: (rowsPerPage: number | null | undefined) => encodeNumber(rowsPerPage),
  decode: (rowsPerPage: string | (string | null)[] | null | undefined) =>
    decodeNumberQueryParam(rowsPerPage, defaultRowsPerPage),
};

export const OrderByKnowledgeGraphParam: QueryParamConfig<keyof RowData> = {
  encode: (orderBy: keyof RowData | null | undefined) => encodeString(orderBy),
  decode: (orderBy: string | (string | null)[] | null | undefined) =>
    decodeOrderByQueryParam(orderBy),
};
