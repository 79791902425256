import { Auth } from 'aws-amplify';
import axios from 'axios';
import { ResponseTypes } from '../types/api';
import { AUTH_BASE_URL } from '../utils/constants';

export async function makeGetRequest(endpoint: string, params?: any) {
  const url = `${AUTH_BASE_URL}/${endpoint}`;
  const definedParams = params ?? {};
  let responseToReturn: ResponseTypes | any = ResponseTypes.BadResponse;

  try {
    await Auth.currentSession()
      .then(async response => {
        const idToken = response.getIdToken().getJwtToken();

        console.log(`========== GET/${endpoint}==========`);
        const getRequestRepsonse = await axios.get(url, {
          headers: {
            Authorization: idToken,
          },
          params: definedParams,
        });

        responseToReturn = getRequestRepsonse;
      })
      .catch(error => {
        console.error(`---------- AUTH ERROR WITH GET/${endpoint}`);
        console.log(error);

        responseToReturn = ResponseTypes.BadAuthResponse;
      });
  } catch (error) {
    console.error(`---------- ERROR WITH GET/${endpoint}`);
    console.log(error);

    responseToReturn = ResponseTypes.ErrorResponse;
  }

  return responseToReturn;
}

export async function makePostRequest<T>(endpoint: string, bodyData: T) {
  const url = `${AUTH_BASE_URL}/${endpoint}`;
  let responseToReturn: ResponseTypes | any = ResponseTypes.BadResponse;

  try {
    await Auth.currentSession()
      .then(async response => {
        const idToken = response.getIdToken().getJwtToken();

        console.log(`========== POST/${endpoint}==========`);
        const postRequestResponse = await axios.post(url, bodyData, {
          headers: {
            Authorization: idToken,
          },
        });

        responseToReturn = postRequestResponse;
      })
      .catch(error => {
        console.error(`---------- AUTH ERROR WITH POST/${endpoint}`);
        console.log(error);

        responseToReturn = ResponseTypes.BadAuthResponse;
      });
  } catch (error) {
    console.error(`---------- ERROR WITH POST/${endpoint}`);
    console.log(error);

    responseToReturn = ResponseTypes.ErrorResponse;
  }

  return responseToReturn;
}
