import { Edge, Node } from 'reactflow';
import { CustomNodeTypes } from './nodes';
import { QueryParamConfig, encodeNumber, encodeString } from 'use-query-params';
import { decodeNumberQueryParam } from './tables';

export type ProcessFlowType = {
  PK: string;
  SK: string;
  name: string;
  objId: string;
  creationDate: string;
  createdBy: string; // username of who created it
  lastRunDate: string;
  lastRunStatus: string;
  lastRunErrorMsg: string;
  nodes: (CustomNodeTypes | Node)[];
  edges: Edge[]; // the array of nodes connected to the process flow
};

export type GetAllProcessFlowsResponse = {
  PK: string;
  SK: string;
  lastRunDate?: string;
  lastRunStatus?: string;
  lastRunErrMsg?: string;
  ProcessFlowData: {
    S: string; //JSON string of process flow data
  };
};

export type GetSingleProcessFlowsResponse = {
  PK: string;
  SK: string;
  ProcessFlowData: {
    S: string; //JSON string of process flow data
  };
};

// the shape of the object when a process flow is stored in the db
export type ProcessFlowStorageType = {
  PK: string;
  SK: string;
  lastRunDate?: string;
  lastRunStatus?: string;
  lastRunErrorMsg?: string;
  JSONPROCESSFLOW: ProcessFlowType[];
};

export interface RunProcessFlowBodyShape {
  jobParams: {
    job_name: 'RKE Process Process Flow';
    job_steps: ['processFlow'];
    PK: string;
    SK: string;
  };
  sysParams: {
    sys_mountPoint: 's3://'; // this may be dynamic in the future
  };
}

export interface ProcessFlowTableColumn {
  id: keyof ProcessFlowRowData;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left';
}

export interface ProcessFlowRowData extends ProcessFlowType {
  action: string;
  numberOfNodes: number;
}

// used for asserting the correct Typescript type when decoding query params on the Datalake table
type ProcessFlowRowDataKeysArrayType = Array<keyof ProcessFlowRowData>;

// used for asserting the correct Typescript type when decoding query params on the Datalake table
export const ProcessFlowRowDataKeysArray: ProcessFlowRowDataKeysArrayType = [
  'PK',
  'SK',
  'name',
  'objId',
  'creationDate',
  'createdBy',
  'lastRunDate',
  'lastRunStatus',
  'lastRunErrorMsg',
  'nodes',
  'edges',
  'action',
  'numberOfNodes',
];

// ========== Query Param utils for process flows table ==========

// defaults for the various filters and sorting values
// if the process flow page url is loaded with no params, the table will use these values
export const defaultRowsPerPage = 15;
export const defaultOrderBy: keyof ProcessFlowRowData = 'name';

// We have these custom decoder functions because the user-query-param library doesn't know how to handle default, null, undefined, or unexpected values so handle them here

// custom function telling use-query-params how to decode the orderBy param so typescript is happy
function decodeOrderByQueryParam(
  orderBy: string | (string | null)[] | null | undefined,
): keyof ProcessFlowRowData {
  if (orderBy == null) return defaultOrderBy;

  if (typeof orderBy === typeof ['']) return defaultOrderBy;

  // this array contains all of the keys of ProcessFlowRowData so we check and and see if the orderBy param is in that array
  if (
    ProcessFlowRowDataKeysArray.includes(orderBy as keyof ProcessFlowRowData)
  ) {
    return orderBy as keyof ProcessFlowRowData;
  }

  return defaultOrderBy;
}

export const RowsPerPageProcessFlowParam: QueryParamConfig<number> = {
  encode: (rowsPerPage: number | null | undefined) => encodeNumber(rowsPerPage),
  decode: (rowsPerPage: string | (string | null)[] | null | undefined) =>
    decodeNumberQueryParam(rowsPerPage, defaultRowsPerPage),
};

export const OrderByProcessFlowParam: QueryParamConfig<
  keyof ProcessFlowRowData
> = {
  encode: (orderBy: keyof ProcessFlowRowData | null | undefined) =>
    encodeString(orderBy),
  decode: (orderBy: string | (string | null)[] | null | undefined) =>
    decodeOrderByQueryParam(orderBy),
};
