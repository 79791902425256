import { toast } from 'react-toastify';
import { ResponseTypes } from '../types/api';
import { KnowledgeGraphData, Neo4jProfile } from '../types/knowledgeGraph';
import { makeGetRequest } from './authenticatedRequests';

export const getAvailableKnowledgeGraphsFromClient = async (
  clientName: string,
) => {
  console.log('==== getAvailableKnowledgeGraphsFromClientService ======');
  try {
    const getAvailableKnowledgeGraphsFromClientResponse = await makeGetRequest(
      `getallknowledgegraphs?pk=${clientName.toUpperCase()}`,
    );

    // const getAvailableKnowledgeGraphsFromClientResponse = await axios.get(
    //   `${BASE_URL}/getallknowledgegraphs?pk=${clientName.toUpperCase()}`,
    // );

    if (
      getAvailableKnowledgeGraphsFromClientResponse != null &&
      getAvailableKnowledgeGraphsFromClientResponse?.data != null
    ) {
      const knowledgeGraphData: KnowledgeGraphData[] =
        await getAvailableKnowledgeGraphsFromClientResponse.data;

      return knowledgeGraphData;
    } else {
      toast.error(
        'There was a problem getting your available knowledge graphs',
      );

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log(
      '++++++++++ ERROR WITH getAvailableKnowledgeGraphsFromClientService ++++++++++',
    );
    console.log(error);
    toast.error('There was a problem getting your available knowledge graphs');

    return ResponseTypes.ErrorResponse;
  }
};

export const getNeo4jProfileFromClient = async (clientName: string) => {
  console.log('==== getNeo4jProfileFromClientService ======');
  try {
    const params = { sk: clientName.toUpperCase(), pk: 'NEO4J' };
    const getNeo4jProfileFromClientResponse = await makeGetRequest(
      'getneo4jprofile',
      params,
    );

    if (
      getNeo4jProfileFromClientResponse?.data != null &&
      getNeo4jProfileFromClientResponse.data.length > 0 &&
      getNeo4jProfileFromClientResponse.data[0]?.UserProfileData != null &&
      getNeo4jProfileFromClientResponse.data[0].UserProfileData?.S != null
    ) {
      const neo4jProfile: Neo4jProfile = await JSON.parse(
        getNeo4jProfileFromClientResponse.data[0].UserProfileData.S,
      );

      return neo4jProfile;
    } else {
      toast.error('There was a problem getting your knowledge graph profle');

      return ResponseTypes.BadResponse;
    }

    // TODO: add cases for auth responses, other bad responses, and response types
    // return response.ok ? ResponseTypes.GoodResponse : ResponseTypes.BadResponse;
  } catch (error) {
    console.log(
      '++++++++++ ERROR WITH getNeo4jProfileFromClientService ++++++++++',
    );
    console.log(error);
    toast.error('There was a problem getting your knowledge graph profle');

    return ResponseTypes.ErrorResponse;
  }
};
