import {
  Box,
  Button,
  CardActionArea,
  Collapse,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { memo, useEffect, useMemo, useState } from 'react';
import { FaInfoCircle } from 'react-icons/fa';
import { FaChevronDown } from 'react-icons/fa6';
import {
  SingleGapBox,
  SingleGapCenterAlignedRowFlex,
  SpaceBetweenRowFlex,
  TwoMarginBottomDivider,
} from '../../../styles/commonStyles';
import { theme } from '../../../styles/theme';
import { FieldRename, Neo4jNodeFormat } from '../../../types/nodes';

// ---------- STYLES ----------

const NodeNameContainer = styled(Box)({
  gridTemplateColumns: '1fr 1fr 1.5rem',
  display: 'grid',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  alignItems: 'start',
}) as typeof Box;

const FieldsContainer = styled(Box)({
  gridTemplateColumns: '1fr 1fr',
  display: 'grid',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  width: 'inherit',
  height: 'fit-content',
  marginBottom: theme.spacing(1),
  alignItems: 'center',
}) as typeof Box;

const NodeChevron = styled(FaChevronDown, {
  shouldForwardProp: prop => prop !== 'expanded',
})<{ expanded: boolean }>(({ expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : '',
  transition: 'all 300ms ease-in-out', // smooth transition
}));

const NodeSectionHeader = styled(CardActionArea)({
  display: 'inline-flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: theme.defaultPaddingRem,
  backgroundColor: theme.palette.background.default,
  cursor: 'pointer',
  alignItems: 'center',
  borderRadius: 0,
});

const CollapseContainer = styled(Collapse)({
  padding: `${theme.defaultPaddingRem} ${theme.spacing(2)} 0 4px`,
}) as typeof Collapse;

// ---------- FUNCTIONS ----------

// Neo4j prefers PascalCase node names so we give it our best effort here given the original input dataset name
const createRecommendedNodeName = (name: string): string => {
  // split on all special characters and spaces
  const splitName = name.trim().split(/[-$%&*()#@!+=:;'"?._ ]/);
  let updatedName = '';

  // capitalize the first letter of each section
  splitName.forEach(section => {
    let capitalSection = '';
    let remainingSection = '';

    if (section.length > 0) {
      capitalSection = `${section[0].toLocaleUpperCase()}`;
    }
    if (section.length > 1) {
      remainingSection = `${section.slice(1)}`;
    }

    updatedName += capitalSection + remainingSection;
  });

  return updatedName;
};

// takes a name and does its best to convert to camelCase
// note that this will not work if the name is just one continuous word ex: "apoorlyformattedname"
const createCamelCaseName = (name: string): string => {
  let updatedName = '';
  let splitName: string[] = [name];

  // split on all special characters and spaces
  splitName = name.trim().split(/[-$%&*()#@!+=:;'"?._ ]/);

  splitName.forEach((section, index) => {
    // we want to capitalize the first letter of every section EXCEPT the first section
    if (index !== 0) {
      let capitalSection = '';
      let remainingSection = '';

      // ensure the section length before attempting to call an index
      if (section.length > 0) {
        capitalSection = `${section[0].toLocaleUpperCase()}`;
      }
      if (section.length > 1) {
        remainingSection = `${section.slice(1)}`;
      }

      updatedName += capitalSection + remainingSection;
    } else {
      // make the first letter of the first section lowercase
      let lowercaseSection = '';
      let remainingSection = '';

      // ensure the section length before attempting to call an index
      if (section.length > 0) {
        lowercaseSection = `${section[0].toLocaleLowerCase()}`;
      }
      if (section.length > 1) {
        remainingSection = `${section.slice(1)}`;

        // check if section is all caps
        if (/^[^a-z]*$/.test(remainingSection)) {
          // since this is the first section, we want it to be entirely lowercase
          remainingSection = remainingSection.toLocaleLowerCase();
        }
      }

      updatedName += lowercaseSection + remainingSection;
    }
  });

  return updatedName;
};

interface FieldRenameWithErrors extends FieldRename {
  error: boolean;
}

const checkFieldsForErrors = (
  fields: FieldRename[],
): FieldRenameWithErrors[] => {
  let fieldsWithErrors: FieldRenameWithErrors[] = [];

  fields.forEach(field => {
    // flag an error if the field is empty or uses non leters or non digits
    const fieldNameError =
      /[^A-Za-z0-9]/.test(field.newName) || field.newName === '';

    fieldsWithErrors.push({ ...field, error: fieldNameError });
  });

  return fieldsWithErrors;
};

const doFieldsHaveErrors = (
  fieldsWithErrors: FieldRenameWithErrors[],
): boolean => {
  return fieldsWithErrors.some(field => field.error);
};

export interface NodeSectionProps extends Neo4jNodeFormat {
  handleUpdate: (id: string, type: 'name' | 'fields', newValue: any) => void;
  handleUpdateErrors: (id: string, hasError: boolean) => void;
}

export default memo(
  ({
    nodeName,
    inputName,
    id,
    renamedFields,
    handleUpdate,
    handleUpdateErrors,
  }: NodeSectionProps) => {
    const recommendedNodeName = useMemo(
      () => createRecommendedNodeName(inputName),
      [inputName],
    );
    const nodeNameMemo = useMemo(() => nodeName, [nodeName]);
    const [updatedFields, setUpdatedFields] = useState(renamedFields);

    // for the collapisble section
    const [expanded, setExpanded] = useState(true);

    // ---------- FORM VALIDATION ----------
    const nodeNameError = useMemo(
      () => /[^A-Za-z0-9]/.test(nodeNameMemo),
      [nodeNameMemo],
    );
    const fieldsWithErrors = useMemo(
      () => checkFieldsForErrors(updatedFields),
      [updatedFields],
    );
    const fieldsHaveError = useMemo(
      () => doFieldsHaveErrors(fieldsWithErrors),
      [fieldsWithErrors],
    );
    const nodeHasError = useMemo(
      () => nodeNameError || fieldsHaveError || nodeNameMemo === '',
      [nodeNameError, fieldsHaveError, nodeNameMemo],
    );

    const handleUpdateField = (index: number, newValue: string) => {
      let fields = [...updatedFields];
      fields[index].newName = newValue;

      setUpdatedFields(fields);
    };

    const convertAllFieldsToCamelCase = () => {
      const camelCaseFields = updatedFields.map(field => {
        const newName = createCamelCaseName(field.originalName);

        return { originalName: field.originalName, newName };
      });

      setUpdatedFields(camelCaseFields);
    };

    // we update the array of fields here first and then send that newly updated array to the parent component
    useEffect(() => {
      handleUpdate(id, 'fields', updatedFields);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedFields]);

    useEffect(() => {
      handleUpdateErrors(id, nodeHasError);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeHasError]);

    return (
      <Box>
        <NodeSectionHeader onClick={() => setExpanded(!expanded)}>
          <Typography fontWeight="bold" variant="body1">
            {nodeNameMemo === '' ? 'Please input node name' : nodeNameMemo}
          </Typography>
          <NodeChevron
            expanded={expanded}
            size={20}
            color={theme.palette.text.primary}
          />
        </NodeSectionHeader>

        <CollapseContainer in={expanded}>
          <NodeNameContainer>
            <SingleGapBox>
              <Typography variant="body2" fontWeight="bold">
                Input Dataset Name
              </Typography>
              <Typography sx={{ marginLeft: '4px' }}>{inputName}</Typography>
            </SingleGapBox>

            <TextField
              variant="outlined"
              label="Node Name"
              placeholder={recommendedNodeName}
              value={nodeNameMemo}
              helperText={
                nodeNameError ? 'Please only use letters and numbers' : ' '
              }
              error={nodeNameError}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdate(id, 'name', event.target.value);
              }}
              onDoubleClick={() =>
                handleUpdate(id, 'name', recommendedNodeName)
              }
            />

            <Tooltip title="This is the name that will be applied to the node when it is created in the Knowledge Graph. Neo4j recommends using PascalCase as the naming convention. Ex: ProperNodeName">
              <IconButton sx={{ height: 24, padding: 0, marginTop: '1rem' }}>
                <FaInfoCircle size={20} color={theme.palette.secondary.main} />
              </IconButton>
            </Tooltip>
          </NodeNameContainer>

          <TwoMarginBottomDivider />

          <SpaceBetweenRowFlex sx={{ marginBottom: theme.spacing(2) }}>
            <SingleGapCenterAlignedRowFlex>
              <Typography variant="h6" fontWeight="bold">
                Fields
              </Typography>
              <Tooltip title="Fields are the headers for every column in the input dataset. They need to be properly named for the Knowledge Graph with no special characters or spaces. Neo4j recommends camelCase naming. Ex: 'myFieldName'">
                <IconButton sx={{ height: 16, padding: 0 }}>
                  <FaInfoCircle
                    size={16}
                    color={theme.palette.secondary.main}
                  />
                </IconButton>
              </Tooltip>
            </SingleGapCenterAlignedRowFlex>

            <SingleGapCenterAlignedRowFlex>
              <Button
                variant="contained"
                color="secondary"
                onClick={convertAllFieldsToCamelCase}
              >
                Auto Rename All Fields
              </Button>
              <Tooltip title="This will automiatically rename all the fields in as close to camelCase format as possible and remove any spaces or special characters. Ex: '#My Field-Name' would become 'myFieldName'">
                <IconButton sx={{ height: 16, padding: 0 }}>
                  <FaInfoCircle
                    size={16}
                    color={theme.palette.secondary.main}
                  />
                </IconButton>
              </Tooltip>
            </SingleGapCenterAlignedRowFlex>
          </SpaceBetweenRowFlex>

          <Box>
            {updatedFields.map((field, index) => {
              const recommendedFieldName = createCamelCaseName(
                field.originalName,
              );
              const fieldNameError = /[^A-Za-z0-9]/.test(field.newName);

              return (
                <FieldsContainer>
                  <SingleGapBox key={field.originalName}>
                    <Typography variant="body2" fontWeight="bold">
                      Original Name
                    </Typography>
                    <Typography sx={{ marginLeft: '4px' }}>
                      {field.originalName}
                    </Typography>
                  </SingleGapBox>

                  <TextField
                    variant="outlined"
                    label="New Field Name"
                    placeholder={recommendedFieldName}
                    value={field.newName}
                    error={fieldNameError}
                    helperText={
                      fieldNameError
                        ? 'Please only use letters and numbers'
                        : ' '
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      handleUpdateField(index, event.target.value);
                    }}
                    onDoubleClick={() =>
                      handleUpdateField(index, recommendedFieldName)
                    }
                  />
                </FieldsContainer>
              );
            })}
          </Box>
        </CollapseContainer>
      </Box>
    );
  },
);
