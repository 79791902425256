import { Typography } from '@mui/material';
import { memo } from 'react';
import { theme } from '../../styles/theme';

interface WarningMessageProps {
  children: React.ReactNode;
}

export default memo(({ children }: WarningMessageProps) => {
  return (
    <Typography
      variant="h5"
      textAlign="center"
      color={theme.palette.primary.main}
    >
      {children}
    </Typography>
  );
});
