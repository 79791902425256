import { Box, Button, Modal, Typography, styled } from '@mui/material';
import { useCallback, useState } from 'react';
import { FaCloudArrowUp } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import { StringParam, useQueryParam } from 'use-query-params';
import { fileUploadServiceCognito } from '../../services/fileServiceCognito';
import { useFileConfigState } from '../../state/fileconfigState';
import { useUserState } from '../../state/userState';
import { BasicModalContainer } from '../../styles/commonStyles';
import { TwoMarginTypography } from '../../styles/textStyles';
import { theme } from '../../styles/theme';
import { ACCEPTED_FILE_TYPES } from '../../utils/constants';
import SwipeOutIconButton from '../common/swipeOutIconButton';

const FlexEndContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
}) as typeof Box;

export default function UploadFileComponent() {
  const { selectedClient } = useUserState();
  const { getFileConfigIsLoading, getAvailableFileConfigs } =
    useFileConfigState();

  const [showModal, setShowModal] = useState(false);

  const [selectedFolder] = useQueryParam('selectedFolder', StringParam);

  const onClose = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const handleUploadFileClick = useCallback(() => {
    setShowModal(true);
  }, []);

  const handleFileUpload = useCallback(
    async (file: File) => {
      await fileUploadServiceCognito(
        file,
        selectedClient?.clientNameFormattedS3.toLowerCase(),
        selectedFolder ?? '',
      );

      if (selectedClient != null && !getFileConfigIsLoading) {
        getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
      }
    },
    [
      getAvailableFileConfigs,
      getFileConfigIsLoading,
      selectedClient,
      selectedFolder,
    ],
  );

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement> | undefined) => {
      //ensure the event is valid
      if (event != null) {
        const target = event.target as HTMLInputElement;
        const files = target.files as FileList | null;

        // ensures a file was properly selected
        if (files != null) {
          //ensures exactly one file was selected
          if (files.length === 1) {
            const selectedFile: File = files[0];
            //ensure the file name has no spaces
            const fileNameNoSpaces = selectedFile.name.replaceAll(' ', '_');
            //cannot use spread operator {...} here because file is readonly so we must create a new File with the updated name
            const renamedFile: File = new File(
              [selectedFile],
              fileNameNoSpaces,
            );

            setShowModal(false);
            handleFileUpload(renamedFile);
          } else {
            toast.error('Please select only one file for upload.');
          }
        } else {
          toast.error('There was an issue with the file you selected.');
        }
      }
    },
    [handleFileUpload],
  );

  return (
    <Box>
      <SwipeOutIconButton
        label=" Upload File"
        Icon={FaCloudArrowUp}
        onClick={handleUploadFileClick}
      />

      {/* This is essentially the same structure as the ConfirmationModal.tsx component but we needed to do some custom stuff for the file upload button to work */}
      <Modal
        aria-labelledby="confirm file upload modal"
        aria-describedby="this is a modal to confirm the details of uploading a file"
        open={showModal}
        onClose={onClose}
      >
        <BasicModalContainer>
          <Typography gutterBottom variant="h5">
            Confirm File Upload Details
          </Typography>

          <TwoMarginTypography>{`You are uploading a file to the /home${
            selectedFolder == null ? ' ' : '/' + selectedFolder
          } directory under the client ${selectedClient?.clientName.toUpperCase()}`}</TwoMarginTypography>

          <FlexEndContainer>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button
              component="label"
              variant="contained"
              color="success"
              sx={{ marginLeft: theme.spacing(1) }}
            >
              Choose File
              <input
                id="datalake_file_upload"
                type="file"
                accept={ACCEPTED_FILE_TYPES}
                multiple={false}
                onChange={handleFileChange}
                style={{ display: 'none' }}
              />
            </Button>
          </FlexEndContainer>
        </BasicModalContainer>
      </Modal>
    </Box>
  );
}
