import { useCallback, useState } from 'react';

// allows you to expose the DOMRect of a component which you can use to access things like height and width of the component
export default function useObservedClientRect() {
  const [rect, setRect] = useState<DOMRect | null>(null);

  const ref = useCallback((node: Element | null) => {
    if (node == null) return;

    const resizeObserver = new ResizeObserver(() => {
      setRect(node.getBoundingClientRect());
    });

    resizeObserver.observe(node);
  }, []);

  return { ref, rect };
}
