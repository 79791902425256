import { Box, Button, styled, Tooltip, Typography } from '@mui/material';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useModelingState } from '../../../state/modelingState';
import {
  KnowledgeGraphNodeType,
  Neo4jNodeFormat,
  Relationship,
} from '../../../types/nodes';

import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { GraphData } from 'react-force-graph-2d';
import { MdAddCircle } from 'react-icons/md';
import { chartColors } from '../../../styles/colors';
import { SpaceBetweenRowFlex } from '../../../styles/commonStyles';
import {
  FitContentButton,
  MarginRightButton,
} from '../../../styles/inputStyles';
import {
  NodeMenuContainer,
  NodeMenuEndButtonsContainer,
  NodeMenuErrorContainer,
  NodeMenuHeaderContainer,
  NodeNameTextField,
  ScrollableContentContainer,
} from '../../../styles/nodeStyles';
import { theme } from '../../../styles/theme';
import NodeSection from './NodeSection';
import PreviewSection from './PreviewSection';
import RelationshipSection from './RelationshipSection';

// data shape for the react-force-graph graphData nodes
interface GraphDataNode {
  id: string;
  nodeLabel: string;
  color: string;
}

// data shape for the react-force-graph graphData links
interface GraphDataLink {
  source: string;
  target: string;
  linkLabel: string;
}

const ScrollableRelationshipsContainer = styled(ScrollableContentContainer)({
  width: '100%',
  padding: 0,
});

// labels for the stepper component
const steps = ['Setup Nodes', 'Setup Relationships', 'Preview & Finish'];

// used for tracking error status in the nodes and relationships
interface ObjectWithErrors {
  id: string;
  error: boolean;
}

// creates an array of id's with error statuses to track node and relationship component errors
const initializeObjectsWithErrors = (inputs: any[]): ObjectWithErrors[] => {
  return inputs.map(input => {
    return { id: input.id, error: true };
  });
};

const doInputsHaveErrors = (inputswWithErrors: ObjectWithErrors[]): boolean => {
  return inputswWithErrors.some(input => input.error);
};

export default memo(() => {
  const { selectedNode, setSelectedNode, nodes, setNodes, onNodeSave } =
    useModelingState();

  const selectedKnowledgeGraphNode = useMemo(
    () => selectedNode as KnowledgeGraphNodeType | undefined,
    [selectedNode],
  );
  // array of the inputs connected to the knowledge graph node
  const availableDatasetNodes = useMemo(
    () =>
      selectedKnowledgeGraphNode != null &&
      selectedKnowledgeGraphNode.data?.inputDatasetNodes != null
        ? selectedKnowledgeGraphNode.data.inputDatasetNodes
        : undefined,
    [selectedKnowledgeGraphNode],
  );
  const [nodeName, setNodeName] = useState(
    selectedKnowledgeGraphNode != null
      ? selectedKnowledgeGraphNode.data.nodeName
      : '',
  );
  const [activeStep, setActiveStep] = useState(0);

  const [neo4jNodes, setNeo4jNodes] = useState(
    selectedKnowledgeGraphNode != null &&
      selectedKnowledgeGraphNode.data?.outputNodes != null
      ? selectedKnowledgeGraphNode.data.outputNodes
      : [],
  );
  const [neo4jRelationships, setNeo4jRelationships] = useState(
    selectedKnowledgeGraphNode != null &&
      selectedKnowledgeGraphNode.data?.outputRelationships != null
      ? selectedKnowledgeGraphNode.data.outputRelationships
      : [],
  );

  // react-force-graph data used to preview the knowledge graph
  const [graphData, setGraphData] = useState<GraphData>({
    nodes: [],
    links: [],
  });

  // --------- FORM VALIDATION ---------
  const [nodesWithErrors, setNodesWithErrors] = useState(
    initializeObjectsWithErrors(neo4jNodes),
  );
  const [relationshipsWithErrors, setRelationshipsWithErrors] = useState(
    initializeObjectsWithErrors(neo4jRelationships),
  );

  const nodesHaveError = useMemo(
    () => doInputsHaveErrors(nodesWithErrors),
    [nodesWithErrors],
  );
  const relationshipsHaveError = useMemo(
    () => doInputsHaveErrors(relationshipsWithErrors),
    [relationshipsWithErrors],
  );
  const nodeNameError = useMemo(() => nodeName.trim() === '', [nodeName]);

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, [setActiveStep]);

  const handlePrevious = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, [setActiveStep]);

  const handleDeleteRelationship = useCallback(
    (id: string) => {
      // if there is exactly one relationship remaining, we merely want to wipe the inputs
      // this prevents the relationship component from quickly disappearing and reappearing when deleted
      if (neo4jRelationships.length === 1) {
        const indexToUpdate = neo4jRelationships.findIndex(
          relationship => relationship.id === id,
        );

        if (indexToUpdate >= 0) {
          let updatedRelationships = [...neo4jRelationships];
          let relationshipToUpdate = updatedRelationships[indexToUpdate];

          // reset to its default state
          relationshipToUpdate = {
            id: relationshipToUpdate.id,
            relationshipName: '',
            sourceNodeName: '',
            targetNodeName: '',
            sourceField: '',
            targetField: '',
            attachedTargetFields: [],
          };

          updatedRelationships[indexToUpdate] = relationshipToUpdate;
          setNeo4jRelationships([...updatedRelationships]);
        }

        const errorIndexToUpdate = relationshipsWithErrors.findIndex(
          relationship => relationship.id === id,
        );

        if (errorIndexToUpdate >= 0) {
          let updatedRelationships = [...relationshipsWithErrors];
          let relationshipToUpdate = updatedRelationships[errorIndexToUpdate];

          relationshipToUpdate = {
            id: relationshipToUpdate.id,
            error: true,
          };

          updatedRelationships[errorIndexToUpdate] = relationshipToUpdate;
          setRelationshipsWithErrors([...updatedRelationships]);
        }
      } else {
        const indexToDelete = neo4jRelationships.findIndex(
          relationship => relationship.id === id,
        );

        if (indexToDelete >= 0) {
          let updatedRelationships = [...neo4jRelationships];
          updatedRelationships.splice(indexToDelete, 1);
          setNeo4jRelationships([...updatedRelationships]);
        }

        const errorIndexToDelete = relationshipsWithErrors.findIndex(
          relationship => relationship.id === id,
        );

        if (errorIndexToDelete >= 0) {
          let updatedRelationships = [...relationshipsWithErrors];
          updatedRelationships.splice(errorIndexToDelete, 1);
          setRelationshipsWithErrors([...updatedRelationships]);
        }
      }
    },
    [
      neo4jRelationships,
      setNeo4jRelationships,
      relationshipsWithErrors,
      setRelationshipsWithErrors,
    ],
  );

  const handleAddNewRelationship = useCallback(() => {
    setNeo4jRelationships(
      neo4jRelationships.concat({
        id: uuidv4(),
        relationshipName: '',
        sourceNodeName: '',
        targetNodeName: '',
        sourceField: '',
        targetField: '',
        attachedTargetFields: [],
      }),
    );
  }, [setNeo4jRelationships, neo4jRelationships]);

  const handleOnSave = useCallback(
    (savedSelectedNode: KnowledgeGraphNodeType) => {
      if (nodes != null) {
        let updatedNodes = [...nodes];
        const selectedNodeIndex = updatedNodes.findIndex(
          node => node.id === savedSelectedNode.id,
        );

        //the node was found
        if (selectedNodeIndex >= 0) {
          let updatedSelectedNode = { ...savedSelectedNode };

          updatedSelectedNode.data = {
            ...updatedSelectedNode.data,
            outputNodes: neo4jNodes,
            outputRelationships: neo4jRelationships,
            nodeName: nodeName.trim(),
          };

          updatedNodes[selectedNodeIndex] = updatedSelectedNode;
          setNodes(updatedNodes);
        }
      }

      //clear the state
      onNodeSave(savedSelectedNode.id);
      setSelectedNode(undefined);
      setNodeName('');
      setNeo4jNodes([]);
      setNeo4jRelationships([]);
      setActiveStep(0);
      setNodesWithErrors([]);
      setRelationshipsWithErrors([]);
      setGraphData({ nodes: [], links: [] });
    },
    [
      setSelectedNode,
      setNodes,
      onNodeSave,
      setNodeName,
      setNeo4jNodes,
      setNeo4jRelationships,
      setActiveStep,
      setNodesWithErrors,
      setRelationshipsWithErrors,
      setGraphData,
      nodes,
      nodeName,
      neo4jNodes,
      neo4jRelationships,
    ],
  );

  const handleUpdateNode = useCallback(
    (id: string, type: 'name' | 'fields', newValue: any) => {
      let updatedNodes = [...neo4jNodes];
      const indexToUpdate = updatedNodes.findIndex(
        (node: Neo4jNodeFormat) => node.id === id,
      );

      if (indexToUpdate >= 0) {
        let nodeToUpdate = updatedNodes[indexToUpdate];

        if (type === 'name') {
          nodeToUpdate.nodeName = newValue;
        }
        if (type === 'fields') {
          nodeToUpdate.renamedFields = newValue;
        }

        updatedNodes[indexToUpdate] = nodeToUpdate;
      }

      setNeo4jNodes([...updatedNodes]);
    },

    [neo4jNodes, setNeo4jNodes],
  );

  const handleUpdateNodeErrors = useCallback(
    (id: string, hasError: boolean) => {
      let updatedNodesWithErrors = [...nodesWithErrors];
      const errorIndexToUpdate = nodesWithErrors.findIndex(
        (node: ObjectWithErrors) => node.id === id,
      );

      if (errorIndexToUpdate >= 0) {
        let nodeToUpdate = updatedNodesWithErrors[errorIndexToUpdate];

        nodeToUpdate.error = hasError;

        updatedNodesWithErrors[errorIndexToUpdate] = nodeToUpdate;
      }

      setNodesWithErrors([...updatedNodesWithErrors]);
    },

    [nodesWithErrors, setNodesWithErrors],
  );

  const handleUpdateRelationship = useCallback(
    (
      id: string,
      type:
        | 'sourceNode'
        | 'targetNode'
        | 'sourceField'
        | 'targetField'
        | 'name'
        | 'attachedFields',
      newValue: any,
    ) => {
      let updatedRelationships = [...neo4jRelationships];
      const indexToUpdate = updatedRelationships.findIndex(
        (relationship: Relationship) => relationship.id === id,
      );

      if (indexToUpdate >= 0) {
        let relationshipToUpdate = updatedRelationships[indexToUpdate];

        if (type === 'name') {
          relationshipToUpdate.relationshipName = newValue;
        }
        if (type === 'sourceNode') {
          relationshipToUpdate.sourceNodeName = newValue;
        }
        if (type === 'targetNode') {
          relationshipToUpdate.targetNodeName = newValue;
        }
        if (type === 'sourceField') {
          relationshipToUpdate.sourceField = newValue;
        }
        if (type === 'targetField') {
          relationshipToUpdate.targetField = newValue;
        }
        if (type === 'attachedFields') {
          relationshipToUpdate.attachedTargetFields = newValue;
        }

        updatedRelationships[indexToUpdate] = relationshipToUpdate;
      }

      setNeo4jRelationships([...updatedRelationships]);
    },

    [neo4jRelationships, setNeo4jRelationships],
  );

  const handleUpdateRelationshipErrors = useCallback(
    (id: string, hasError: boolean) => {
      let updatedRelationshipsWithErrors = [...relationshipsWithErrors];
      const errorIndexToUpdate = relationshipsWithErrors.findIndex(
        (relationship: ObjectWithErrors) => relationship.id === id,
      );

      if (errorIndexToUpdate >= 0) {
        let relationshipToUpdate =
          updatedRelationshipsWithErrors[errorIndexToUpdate];

        relationshipToUpdate.error = hasError;

        updatedRelationshipsWithErrors[errorIndexToUpdate] =
          relationshipToUpdate;
      } else {
        updatedRelationshipsWithErrors.push({ id, error: hasError });
      }

      setRelationshipsWithErrors([...updatedRelationshipsWithErrors]);
    },

    [relationshipsWithErrors, setRelationshipsWithErrors],
  );

  const mapToGraphData = useCallback(() => {
    let nodes: GraphDataNode[] = [];
    let links: GraphDataLink[] = [];

    neo4jNodes.forEach((node, index) => {
      nodes.push({
        id: node.nodeName,
        nodeLabel: node.nodeName,
        color: index < chartColors.length ? chartColors[index] : '',
      });
    });

    neo4jRelationships.forEach(relationship => {
      if (
        relationship.sourceNodeName !== '' &&
        relationship.targetNodeName !== ''
      ) {
        links.push({
          source: relationship.sourceNodeName,
          target: relationship.targetNodeName,
          linkLabel: relationship.relationshipName,
        });
      }
    });

    setGraphData({ nodes, links });
  }, [neo4jNodes, neo4jRelationships, setGraphData]);

  // If there are no relationships, generate a blank one by default. This saves the user from clicking the button
  useEffect(() => {
    if (selectedKnowledgeGraphNode != null && neo4jRelationships.length === 0) {
      handleAddNewRelationship();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedKnowledgeGraphNode, neo4jRelationships]);

  useEffect(() => {
    mapToGraphData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [neo4jNodes, neo4jRelationships]);

  if (
    selectedKnowledgeGraphNode != null &&
    availableDatasetNodes != null &&
    availableDatasetNodes.length > 1
  ) {
    return (
      <NodeMenuContainer>
        <NodeMenuHeaderContainer sx={{ alignItems: 'start', marginBottom: 0 }}>
          <Typography variant="h4" gutterBottom>
            Create Knowledge Graph
          </Typography>

          <NodeNameTextField
            variant="outlined"
            label="Node Name"
            placeholder="Node Name"
            value={nodeName}
            error={nodeNameError}
            helperText={nodeNameError ? 'Node Name cannot be blank' : ' '}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setNodeName(event.target.value);
            }}
          />
        </NodeMenuHeaderContainer>

        <Stepper
          activeStep={activeStep}
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}
        >
          {steps.map(label => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};

            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {/* SETUP NODES STEP */}
        {activeStep === 0 && (
          <ScrollableContentContainer>
            {neo4jNodes.map(node => (
              <NodeSection
                {...node}
                handleUpdate={handleUpdateNode}
                handleUpdateErrors={handleUpdateNodeErrors}
              />
            ))}
          </ScrollableContentContainer>
        )}

        {/* SETUP RELATIONSHIPS STEP */}
        {activeStep === 1 && (
          <ScrollableRelationshipsContainer>
            {neo4jRelationships.map(relationship => {
              return (
                <RelationshipSection
                  {...relationship}
                  handleUpdate={handleUpdateRelationship}
                  handleDelete={handleDeleteRelationship}
                  handleUpdateErrors={handleUpdateRelationshipErrors}
                  availableNodes={neo4jNodes}
                />
              );
            })}
          </ScrollableRelationshipsContainer>
        )}

        {activeStep === 1 && (
          <FitContentButton
            variant="contained"
            color="secondary"
            onClick={handleAddNewRelationship}
            endIcon={
              <MdAddCircle color={theme.palette.secondary.contrastText} />
            }
            size="medium"
            sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
          >
            Add Relationship
          </FitContentButton>
        )}

        {/* PREVIEW STEP */}
        {activeStep === 2 && <PreviewSection graphData={graphData} />}

        <SpaceBetweenRowFlex>
          {/* Need something to hold the space when no button is needed */}
          {activeStep === 0 && <Box />}

          {activeStep === 1 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevious}
            >
              Back to Nodes
            </Button>
          )}

          {activeStep === 2 && (
            <Button
              variant="contained"
              color="secondary"
              onClick={handlePrevious}
            >
              Back to Relationships
            </Button>
          )}

          <NodeMenuEndButtonsContainer>
            <MarginRightButton onClick={() => setSelectedNode(undefined)}>
              Cancel
            </MarginRightButton>

            {activeStep === 0 && (
              <Tooltip
                title="Please check the form for any errors or blank inputs"
                placement="top-start"
                arrow
                disableHoverListener={!nodesHaveError}
              >
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleNext}
                    disabled={nodesHaveError}
                  >
                    Save Nodes & Go to Relationships
                  </Button>
                </Box>
              </Tooltip>
            )}

            {activeStep === 1 && (
              <Tooltip
                title="Please check the form for any errors or blank inputs"
                placement="top-start"
                arrow
                disableHoverListener={!relationshipsHaveError}
              >
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleNext}
                    disabled={relationshipsHaveError}
                  >
                    Save Relationships & Go to Preview
                  </Button>
                </Box>
              </Tooltip>
            )}

            {activeStep === 2 && (
              <Tooltip
                title="Please check the Node Name"
                placement="top-start"
                arrow
                disableHoverListener={!nodeNameError}
              >
                <Box>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={() => handleOnSave(selectedKnowledgeGraphNode)}
                    disabled={nodeNameError}
                  >
                    Save Knowledge Graph
                  </Button>
                </Box>
              </Tooltip>
            )}
          </NodeMenuEndButtonsContainer>
        </SpaceBetweenRowFlex>
      </NodeMenuContainer>
    );
  } else {
    // there are not enough inputs connected
    return (
      <NodeMenuErrorContainer>
        {(availableDatasetNodes == null ||
          availableDatasetNodes.length <= 0) && (
          <Typography variant="h5" gutterBottom>
            There are no available datasets to select. Did you connect an input
            node?
          </Typography>
        )}
        {availableDatasetNodes != null &&
          availableDatasetNodes.length > 0 &&
          availableDatasetNodes.length <= 1 && (
            <Typography variant="h5" gutterBottom>
              You must have at least two input datasets connected.
            </Typography>
          )}
        <Button variant="contained" onClick={() => setSelectedNode(undefined)}>
          Cancel
        </Button>
      </NodeMenuErrorContainer>
    );
  }
});
