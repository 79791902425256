import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { useMemo } from 'react';
import { theme } from '../../styles/theme';
import { Report } from '../../types/reports';
import DeleteReportButton from './deleteReportButton';

export const CARD_HEIGHT = 14;
export const CARD_WIDTH = 14;

const DATA_GRID_IMG_URL = '/mui_datagrid_stock_img.png';

const PIVOT_TABLE_IMG_URL = '/pivot_table.png';
const TABLE_HEATMAP_IMG_URL = '/table_heatmap.png';
const EXPORTABLE_TSV_IMG_URL = '/exportable_tsv.png';

const GROUPED_COLUMN_CHART_IMG_URL = '/grouped_column_chart.png';
const STACKED_COLUMN_CHART_IMG_URL = '/stacked_column_chart.png';
const GROUPED_BAR_CHART_IMG_URL = '/grouped_bar_chart.png';
const STACKED_BAR_CHART_IMG_URL = '/stacked_bar_chart.png';

const LINE_CHART_IMG_URL = '/line_chart.png';
const DOT_CHART_IMG_URL = '/dot_chart.png';
const AREA_CHART_IMG_URL = '/area_chart.png';
const SCATTER_CHART_IMG_URL = '/scatter_chart.png';
const MULTIPLE_PIE_CHART_IMG_URL = '/multiple_pie_chart.png';

const DEFAULT_IMG_URL = '/default_chart_img.jpeg';

const getImgUrlFromType = (
  type: Report['rendererName'] | 'dataGrid',
): string => {
  switch (type) {
    case 'Table':
      return PIVOT_TABLE_IMG_URL;
    case 'Table Heatmap':
      return TABLE_HEATMAP_IMG_URL;
    case 'Table Col Heatmap':
      return TABLE_HEATMAP_IMG_URL;
    case 'Table Row Heatmap':
      return TABLE_HEATMAP_IMG_URL;
    case 'Exportable TSV':
      return EXPORTABLE_TSV_IMG_URL;
    case 'Grouped Column Chart':
      return GROUPED_COLUMN_CHART_IMG_URL;
    case 'Stacked Column Chart':
      return STACKED_COLUMN_CHART_IMG_URL;
    case 'Grouped Bar Chart':
      return GROUPED_BAR_CHART_IMG_URL;
    case 'Stacked Bar Chart':
      return STACKED_BAR_CHART_IMG_URL;
    case 'Line Chart':
      return LINE_CHART_IMG_URL;
    case 'Dot Chart':
      return DOT_CHART_IMG_URL;
    case 'Area Chart':
      return AREA_CHART_IMG_URL;
    case 'Scatter Chart':
      return SCATTER_CHART_IMG_URL;
    case 'Multiple Pie Chart':
      return MULTIPLE_PIE_CHART_IMG_URL;
    case 'dataGrid':
      return DATA_GRID_IMG_URL;
    default:
      return DEFAULT_IMG_URL;
  }
};

const ReportTileContainer = styled(Card)`
  width: ${CARD_WIDTH}rem;
  height: ${CARD_HEIGHT}rem;
  background-color: ${theme.palette.background.default};
  border-radius: ${theme.borderRadiusRem};
  cursor: pointer;
  position: relative;

  &:hover .delete-report-button {
    display: flex;
  }
`;

const StyledActionArea = styled(CardActionArea)({
  height: `${CARD_HEIGHT}rem`,
  width: `${CARD_WIDTH}rem`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  justifyContent: 'start',
  overflow: 'hidden',
}) as typeof CardActionArea;

interface ReportTileProps extends Report {
  handleClick: () => void;
}

export default function ReportTile({
  name,
  type,
  PK,
  SK,
  client,
  fileSK,
  rendererName,
  handleClick,
}: ReportTileProps) {
  const imgUrl = useMemo(
    () => getImgUrlFromType(type === 'dataGrid' ? 'dataGrid' : rendererName),
    [type, rendererName],
  );

  return (
    <ReportTileContainer>
      {type !== 'dataGrid' && (
        <DeleteReportButton
          reportPK={PK}
          reportSK={SK}
          reportName={name}
          fileClient={client}
          fileSK={fileSK}
        />
      )}

      <StyledActionArea onClick={handleClick}>
        <CardMedia
          component="img"
          image={imgUrl}
          // the height in rem is not working so just defaulting to a number here (1rem = 16)
          height={CARD_HEIGHT * 16}
          width={`${CARD_WIDTH}rem`}
          alt="bar chart"
          onError={({ currentTarget }) => {
            // this will trigger if the provided img src is broken or not working
            currentTarget.onerror = null;
            currentTarget.src = DEFAULT_IMG_URL;
          }}
        />
        <CardContent sx={{ padding: '0.5rem' }}>
          <Tooltip title={name}>
            <Typography variant="h6" noWrap width={`${CARD_WIDTH - 0.5}rem`}>
              {name}
            </Typography>
          </Tooltip>
        </CardContent>
      </StyledActionArea>
    </ReportTileContainer>
  );
}
