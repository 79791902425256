import { Tooltip, Typography } from '@mui/material';

interface PropertyContentProps {
  text: string;
}

export default function PropertyContent({ text }: PropertyContentProps) {
  return (
    <Tooltip title={text}>
      <Typography variant="body2" textOverflow="ellipsis" noWrap>
        {text}
      </Typography>
    </Tooltip>
  );
}
