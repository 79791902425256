import AWS from 'aws-sdk';
import { create } from 'zustand';
import { makeGetRequest } from '../services/authenticatedRequests';
import { ResponseTypes } from '../types/api';
import { Dataset } from '../types/datalake';

export interface DatalakeState {
  availableFiles: AWS.S3.ObjectList | undefined;
  availableFileConfigList: Dataset[] | undefined;
  datalakeTableQueryParams: string;

  getAvailableFiles: (clientName: string) => void;
  getAvailableFileConfigs: (clientName: string) => void;
  setDatalakeTableQueryParams: (queryString: string) => void;
}

export const useDatalakeState = create<DatalakeState>((set, get) => ({
  availableFiles: undefined,
  availableFileConfigList: undefined,
  datalakeTableQueryParams: '',

  getAvailableFiles: async (clientName: string) => {
    const s3 = new AWS.S3();
    // client name MUST be lowercase here. The system is looking for file names with this client name and the file names are all lowercase
    const lowercaseClientName = clientName.toLowerCase();
    const listParams = {
      Bucket: `rke-${lowercaseClientName}`,
      Delimiter: '',
      Prefix: 'Raw/',
    };

    s3.listObjectsV2(listParams, (err, data) => {
      if (err) {
        console.log(err, err.stack);
      } else {
        set({ availableFiles: data.Contents ?? [] });
      }
    });
  },
  getAvailableFileConfigs: async (clientName: string) => {
    const params = {
      pk: clientName,
      sk: clientName,
    };
    const getAvailableFileConfigsResponse = await makeGetRequest(
      'fileconfig',
      params,
    );

    if (
      typeof getAvailableFileConfigsResponse !==
        typeof ResponseTypes.ErrorResponse &&
      getAvailableFileConfigsResponse != null &&
      getAvailableFileConfigsResponse?.data != null
    ) {
      console.log('Good datalake auth response');

      set({
        availableFileConfigList: await JSON.parse(
          getAvailableFileConfigsResponse.data,
        ),
      });
    } else {
      console.log('---------- BAD GET/filecongi RESPONSE ----------');
      console.log(getAvailableFileConfigsResponse);
    }
  },

  setDatalakeTableQueryParams: (queryString: string) => {
    set({ datalakeTableQueryParams: queryString });
  },
}));
