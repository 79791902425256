import { create } from 'zustand';
import { getUserProfileService } from '../services/profileService';
import {
  SelectedClientInfo,
  UserProfile,
  UserProfileResponse,
} from '../types/user';

export interface UserState {
  userProfile: UserProfile | undefined;
  userIsLoading: boolean;
  selectedClient: SelectedClientInfo | undefined; // this serves as the client used for all client-based API calls
  getUserProfile: (
    username: string,
    selectedClient: string | undefined | null,
  ) => void;
  setSelectedClientName: (clientName: string | undefined) => void;
}

export const useUserState = create<UserState>((set, get) => ({
  userProfile: undefined,
  userIsLoading: false,
  selectedClient: undefined,

  getUserProfile: async (
    username: string,
    selectedClient: string | undefined | null,
  ) => {
    set({ userIsLoading: true });
    const getUserProfileResponse = await getUserProfileService(username);

    // if it's not a string, then we got good data
    if (typeof getUserProfileResponse !== typeof '') {
      const formattedResponse = getUserProfileResponse as UserProfileResponse;

      const clientNameToUse = selectedClient ?? formattedResponse.defaultClient;
      const uppercaseClientName = clientNameToUse.toUpperCase();
      const lowercaseClientName = clientNameToUse.toLowerCase();

      set({
        userProfile: {
          username,
          defaultClient: formattedResponse.defaultClient,
          PK: formattedResponse.PK,
          SK: formattedResponse.SK,
          clientList: formattedResponse.clientList,
          userType: formattedResponse.userType,
          clientNameFormattedDynamo: uppercaseClientName,
          clientNameFormattedS3: lowercaseClientName,
          BIReportingLicense: formattedResponse.BIReportingLicense,
          BiomimeticLicense: formattedResponse.BiomimeticLicense,
          ETLLicense: formattedResponse.ETLLicense,
        },
        userIsLoading: false,
        selectedClient: {
          clientName: clientNameToUse,
          clientNameFormattedDynamo: uppercaseClientName,
          clientNameFormattedS3: lowercaseClientName,
        },
      });
    } else {
      set({
        userProfile: undefined,
        userIsLoading: false,
        selectedClient: undefined,
      });
    }
  },

  setSelectedClientName: clientName => {
    if (clientName == null) {
      set({ selectedClient: undefined });
    } else {
      const uppercaseClientName = clientName.toUpperCase();
      const lowercaseClientName = clientName.toLowerCase();

      set({
        selectedClient: {
          clientName,
          clientNameFormattedDynamo: uppercaseClientName,
          clientNameFormattedS3: lowercaseClientName,
        },
      });
    }
  },
}));
