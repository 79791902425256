import AWS from 'aws-sdk';
import { toast } from 'react-toastify';
import { ResponseTypes } from '../types/api';

// Configure AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_SYS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

export const fileUploadServiceCognito = async (
  file: File,
  s3ClientName?: string,
  folderName?: string,
): Promise<ResponseTypes.GoodResponse | ResponseTypes.ErrorResponse> => {
  const toastId = toast.loading('Uploading your file...');

  try {
    const s3 = new AWS.S3();

    const filename = `Raw/${folderName ?? ''}${file.name}`;

    const params = {
      Bucket: `rke-${s3ClientName ?? 'defaultclient'}`,
      Key: filename,
      Body: file,
    };

    const options = {
      partSize: 100 * 1024 * 1024 * 1024, // 10 GB
      queueSize: 1,
    };

    const upload = s3.upload(params, options);

    await upload.promise();

    toast.update(toastId, {
      type: 'success',
      render: 'File Uploaded',
      isLoading: false,
      autoClose: 3000,
      closeButton: true,
    });

    toast.warning(
      "The file is being processed to ensure it's usable for modeling. Check back in a few minutes if you don't see the file right away.",
      { autoClose: 10000 },
    );

    return ResponseTypes.GoodResponse;
  } catch (error) {
    console.log('Caught an error uploading the file:', error);
    toast.update(toastId, {
      type: 'error',
      render: 'There was an error uploading your file',
      isLoading: false,
      autoClose: 5000,
      closeButton: true,
    });

    return ResponseTypes.ErrorResponse;
  }
};
