import { Tooltip, styled } from '@mui/material';
import AWS from 'aws-sdk';
import Papa from 'papaparse';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useFileConfigState } from '../../state/fileconfigState';
import { useReportsState } from '../../state/reportsState';
import { useUserState } from '../../state/userState';
import { RowData } from '../../types/datalake';
import { Report, setUpPivotTableQueryParams } from '../../types/reports';

// Configure AWS SDK
AWS.config.update({
  region: process.env.REACT_APP_SYS_REGION,
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
});

const OverflowCell = styled('span')({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

// convert the file data to a useable csv key used to grab data from s3
const convertFileDataToCSVKey = (
  fileData: string,
  s3ClientName: string,
): string => {
  const csvFileString = fileData
    .replace('s3://', '')
    .replace(`rke-${s3ClientName}`, '');

  return csvFileString.slice(1);
};

interface SetupReportsDataProps {
  children: ReactElement;
}

// The point of this component is to set up the necessary file and report data if the user refreshes the page
// On a refresh, the state is cleared, so we want to grab the files and select the proper file and/or report using the params store in the url
export default function SetupReportsData({ children }: SetupReportsDataProps) {
  const { selectedClient } = useUserState();

  const {
    formattedFileConfigs,
    getFileConfigIsLoading,
    getAvailableFileConfigs,
  } = useFileConfigState();

  const {
    getAvailableReports,
    availableReports,
    selectedFileInfoForS3,
    selectedFileName,
    selectedReport,
    selectedData,
    selectedDataColumns,
    selectedFileCSVKey,
    setSelectedFileCSVKey,
    setSelectedReport,
    setSelectedFileInfoForS3,
    setSelectedFileName,
    setSelectedData,
    setSelectedDataColumns,
    setReportsErrorMessage,
    setReportsIsLoading,
  } = useReportsState();

  const { reportIdParam, fileNameParam } = useParams();
  const [, setSearchParams] = useSearchParams();
  const { state } = useLocation();

  // this is used to ensure we only run this setup process once, on render, preventing too many API calls and state updartes
  const [refreshDetected, setRefreshDetected] = useState(false);

  const fileDataFromState = useMemo(
    () => selectedFileInfoForS3?.fileData ?? undefined,
    [selectedFileInfoForS3],
  );
  // this variable is true when the user navigates back to this page using the breadcrumb menu
  // this blocks the data from refreshing and making unnecessary API calls
  const navigatedFromBreadcrumb = useMemo(
    () => state?.navigatedFromBreadcrumb ?? false,
    [state],
  );

  // the initial check, if this component is rendered and we have no file configs then someone either refreshed on a reports page or navigated directly there
  useEffect(() => {
    if (
      formattedFileConfigs.length === 0 &&
      !getFileConfigIsLoading &&
      selectedClient != null &&
      !navigatedFromBreadcrumb
    ) {
      setRefreshDetected(true);
      getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
    }
  }, [
    formattedFileConfigs,
    getAvailableFileConfigs,
    getFileConfigIsLoading,
    navigatedFromBreadcrumb,
    refreshDetected,
    selectedClient,
  ]);

  // grab the file name from the url
  useEffect(() => {
    if (fileNameParam != null && !navigatedFromBreadcrumb) {
      setSelectedFileName(fileNameParam);
    }
  }, [fileNameParam, navigatedFromBreadcrumb, setSelectedFileName]);

  // update the CSV key once both the user info and file data are ready
  useEffect(() => {
    if (
      selectedClient != null &&
      fileDataFromState != null &&
      !navigatedFromBreadcrumb
    ) {
      setSelectedFileCSVKey(
        convertFileDataToCSVKey(
          fileDataFromState,
          selectedClient.clientNameFormattedS3,
        ),
      );
    }
  }, [
    fileDataFromState,
    navigatedFromBreadcrumb,
    selectedClient,
    setSelectedFileCSVKey,
  ]);

  // once the csv key is ready we attempt to grab the data associated with that key from s3
  useEffect(() => {
    const getFileDataFromS3 = async (
      myfileKey: string,
      s3ClientName: string,
    ) => {
      setReportsIsLoading(true);
      setReportsErrorMessage(undefined);
      try {
        const s3 = new AWS.S3();

        const getParams = {
          Bucket: `rke-${s3ClientName}`,
          Key: myfileKey,
        };

        const s3Object = await s3.getObject(getParams).promise();

        // there could be an error getting the s3 object, check for that here
        if (s3Object != null && s3Object?.Body != null) {
          const csvString = s3Object?.Body.toString('utf-8');
          const parsedData = Papa.parse(csvString, { header: true }).data;
          // Add a unique ID to each row
          const dataWithIds = parsedData.map((row: any) => ({
            id: uuidv4(),
            ...row,
          }));

          setSelectedData(dataWithIds);
        } else {
          setReportsErrorMessage(
            'There was a problem getting your data from s3.',
          );
        }

        setReportsIsLoading(false);
      } catch (error) {
        console.error('Error reading CSV from S3:', error);
        setReportsIsLoading(false);
        setReportsErrorMessage('There was a problem parsing your file data.');
      }
    };

    if (
      selectedFileCSVKey != null &&
      selectedClient != null &&
      !navigatedFromBreadcrumb
    ) {
      getFileDataFromS3(
        selectedFileCSVKey,
        selectedClient.clientNameFormattedS3,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileCSVKey, selectedClient, navigatedFromBreadcrumb]);

  // grab the selected file using the selected file name
  useEffect(() => {
    if (
      refreshDetected &&
      formattedFileConfigs != null &&
      selectedFileName != null &&
      !navigatedFromBreadcrumb
    ) {
      const fileIndex = formattedFileConfigs.findIndex(
        (file: RowData) => file.fileName === selectedFileName,
      );

      // file was found
      if (fileIndex >= 0) {
        const selectedFileConfig = formattedFileConfigs[fileIndex];

        setSelectedFileInfoForS3({
          fileData: selectedFileConfig.fileDataLocation,
          fileClient: selectedFileConfig.client,
          fileSK: selectedFileConfig.SK,
        });
      }
    }
  }, [
    formattedFileConfigs,
    navigatedFromBreadcrumb,
    refreshDetected,
    selectedFileName,
    setSelectedFileInfoForS3,
  ]);

  // set up the columns
  useEffect(() => {
    // this data is used in multilple places so we assign it to a global state variable here
    if (
      selectedData != null &&
      selectedData.length > 0 &&
      selectedDataColumns == null &&
      !navigatedFromBreadcrumb
    ) {
      setSelectedDataColumns(
        Object.keys(selectedData[0]).map(colName => ({
          field: colName,
          headerName: colName,
          renderCell: (params: any) => (
            <Tooltip title={params.value}>
              <OverflowCell>
                {params.value === '' ? '-' : params.value}
              </OverflowCell>
            </Tooltip>
          ),
        })),
      );
    }
  }, [
    navigatedFromBreadcrumb,
    selectedData,
    selectedDataColumns,
    setSelectedDataColumns,
  ]);

  // grab the available reports once the file info from the state is ready
  useEffect(() => {
    if (
      availableReports == null &&
      selectedFileInfoForS3?.fileClient != null &&
      selectedFileInfoForS3?.fileSK != null &&
      selectedFileInfoForS3?.fileClient !== '' &&
      selectedFileInfoForS3?.fileSK !== '' &&
      !navigatedFromBreadcrumb
    ) {
      getAvailableReports(
        selectedFileInfoForS3.fileClient.toLocaleUpperCase(),
        selectedFileInfoForS3.fileSK,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFileInfoForS3, availableReports, navigatedFromBreadcrumb]);

  // set the selected report using the url param if it's available
  useEffect(() => {
    if (
      selectedReport == null &&
      reportIdParam != null &&
      availableReports != null &&
      availableReports.length > 0 &&
      !navigatedFromBreadcrumb
    ) {
      const reportIndex = availableReports.findIndex(
        (report: Report) => report.id === reportIdParam,
      );

      // the report was found
      if (reportIndex >= 0) {
        const report = availableReports[reportIndex];

        if (report.type !== 'dataGrid') {
          // the plot uses query params as state variables, so set those up here
          setSearchParams(setUpPivotTableQueryParams(report));
        }

        setSelectedReport(report);
      }
    }
  }, [
    availableReports,
    navigatedFromBreadcrumb,
    reportIdParam,
    selectedReport,
    setSearchParams,
    setSelectedReport,
  ]);

  return children;
}
