import { Modal, Typography, Button, styled } from '@mui/material';
import { memo } from 'react';
import {
  BasicModalContainer,
  SpaceBetweenRowFlex,
} from '../../styles/commonStyles';
import { TwoMarginTypography } from '../../styles/textStyles';

const HeaderContainer = styled(SpaceBetweenRowFlex)({
  width: '100%',
  flexWrap: 'wrap',
  alignItems: 'end',
});

interface ModalProps {
  modalIsOpen: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
}

export default memo(({ modalIsOpen, setModalIsOpen }: ModalProps) => {
  return (
    <Modal
      aria-labelledby="cypher-help-modal-label"
      aria-describedby="cypher-help-modal-description"
      open={modalIsOpen}
      onClose={() => setModalIsOpen(false)}
    >
      <BasicModalContainer>
        <HeaderContainer>
          <Typography variant="h6" fontWeight="bold">
            Basic Example Queries
          </Typography>
          <Typography gutterBottom>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://neo4j.com/docs/cypher-manual/current/queries/basic/"
            >
              More detailed Cypher documentation
            </a>
          </Typography>
        </HeaderContainer>

        <Typography variant="body1" fontWeight="bold">
          Get all nodes
        </Typography>
        <Typography variant="body1" gutterBottom>
          MATCH(n) RETURN n LIMIT 1000;
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          Get a specific node
        </Typography>
        <Typography variant="body1" gutterBottom>
          MATCH(n: NodeName) RETURN n LIMIT 1000;
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          Get a relationship
        </Typography>
        <Typography variant="body1" gutterBottom>
          {'MATCH r=()-[:RELATIONSHIP_NAME]->() RETURN r LIMIT 1000;'}
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          Get specific nodes in a relationship
        </Typography>
        <Typography variant="body1" gutterBottom>
          {
            'MATCH (x:SomeNode)-[r:RELATIONSHIP_NAME]->(y:OtherNode) RETURN x,r,y LIMIT 1000;'
          }
        </Typography>

        <Typography variant="body1" fontWeight="bold">
          Get nodes with a filter
        </Typography>
        <TwoMarginTypography variant="body1" gutterBottom>
          {
            "MATCH (x:SomeNode) WHERE x.value > 2 and x.property === 'foo' RETURN x LIMIT 1000;"
          }
        </TwoMarginTypography>

        <TwoMarginTypography variant="body1" gutterBottom>
          Note that the LIMIT operator is not essential but any graph over 5,000
          - 8,000 nodes may cause some lag when displaying.
        </TwoMarginTypography>

        <Button
          variant="contained"
          onClick={() => setModalIsOpen(false)}
          color="primary"
        >
          Close
        </Button>
      </BasicModalContainer>
    </Modal>
  );
});
