import { IconButton, styled, Tooltip } from '@mui/material';
import { Integer } from 'neo4j-driver';
import { FaChevronLeft } from 'react-icons/fa6';
import {
  ColumnFlex,
  RowFlex,
  SingleGapRowFlex,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  GraphTag,
  KGNodeShape,
  propertyTagColor,
  propertyTagTextColor,
} from '../../types/knowledgeGraph';
import NodeTag from './nodeTag';
import PropertyContent from './propertyContent';
import PropertyTag from './propertyTag';

const DescriptionContainer = styled(ColumnFlex)({
  padding: theme.defaultPaddingRem,
  gap: theme.defaultPaddingRem,
  width: `calc(100% - ${theme.defaultPaddingRem} - ${theme.defaultPaddingRem})`,
  flexWrap: 'wrap',
  overflowY: 'scroll',
}) as typeof ColumnFlex;

const PropertyKeysContainer = styled(RowFlex)({
  justifyContent: 'space-between',
  width: '100%',
  gap: '1rem',
  flexWrap: 'wrap',
});

const HeaderContainer = styled(SingleGapRowFlex)({
  flexWrap: 'wrap',
});

export interface NodeDescriptionProps {
  clickedNode: KGNodeShape;
  nodeTag: GraphTag;
  exitMenu: () => void;
}

export default function KnowledgeGraphNodeDescription({
  clickedNode,
  nodeTag,
  exitMenu,
}: NodeDescriptionProps) {
  return (
    <DescriptionContainer>
      <HeaderContainer>
        <Tooltip title="Exit Node Details">
          <IconButton onClick={exitMenu}>
            <FaChevronLeft color={theme.palette.text.primary} size={15} />
          </IconButton>
        </Tooltip>

        <NodeTag {...nodeTag} />
      </HeaderContainer>

      <PropertyKeysContainer>
        <PropertyTag
          label="<id>"
          backgroundColor={propertyTagColor}
          textColor={propertyTagTextColor}
          fontSize={12}
        />
        <PropertyContent text={`${clickedNode.id}`} />
      </PropertyKeysContainer>

      {Object.keys(clickedNode.properties).map(property => {
        let propertyValue = clickedNode?.properties[property];

        if (propertyValue?.high != null) {
          const intValue = propertyValue as Integer;
          propertyValue = intValue.toNumber();
        }
        if (propertyValue == null) {
          propertyValue = '---';
        }
        // show boolean values as text ex: true
        if (typeof propertyValue === typeof true) {
          propertyValue = `${propertyValue}`;
        }

        return (
          <PropertyKeysContainer key={`${property}-${propertyValue}`}>
            <PropertyTag
              label={property}
              backgroundColor={propertyTagColor}
              textColor={propertyTagTextColor}
              fontSize={12}
            />
            <PropertyContent text={propertyValue} />
          </PropertyKeysContainer>
        );
      })}
    </DescriptionContainer>
  );
}
