import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import KnowledgeGraphVisualizationPageLoader from '../components/knowledgeGraph/fullPageSkeletonLoader';
import KnowledgeGraphVisualizationPageBody from '../components/knowledgeGraph/pageBody';
import { useKnowledgeGraphState } from '../state/knowledgeGraphState';
import { useUserState } from '../state/userState';
import { createDriver, Neo4jProvider } from 'use-neo4j';
import { Neo4jScheme } from 'use-neo4j/dist/neo4j-config.interface';

function parseNeo4jUri(uri: string): { uri: string; protocol: Neo4jScheme } {
  let protocol: Neo4jScheme = 'neo4j';

  if (uri.includes('neo4j+s://')) {
    protocol = 'neo4j+s';
  }
  if (uri.includes('bolt+s://')) {
    protocol = 'bolt+s';
  }
  if (uri.includes('bolt://')) {
    protocol = 'bolt';
  }

  return { uri: uri.replace(`${protocol}://`, ''), protocol };
}

export default function KnowledgeGraphVisualizationPage() {
  const { knowledgeGraphSK } = useParams();
  const navigate = useNavigate();

  const { selectedClient } = useUserState();
  const {
    selectedKnowledgeGraph,
    availableKnowledgeGraphs,
    setSelectedKnowledgeGraph,
    getAvailableKnowledgeGraphs,
    getNeo4jProfile,
    getAvailableKnowledgeGraphsIsLoading,
    selectedNeo4jProfile,
    getNeo4jProfileIsLoading,
  } = useKnowledgeGraphState();

  // there are many Driver types in the use-neo4j and neo4j-driver package. None of them seem to work. Really bad typing in the package. Thus, the use of any
  const [neo4jDriver, setNeo4jDriver] = useState<any | undefined>(undefined);
  const [driverIsLoading, setDriverIsLoading] = useState(true);

  useEffect(() => {
    if (
      availableKnowledgeGraphs == null &&
      selectedClient != null &&
      !getAvailableKnowledgeGraphsIsLoading
    ) {
      getAvailableKnowledgeGraphs(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    availableKnowledgeGraphs,
    selectedClient,
    getAvailableKnowledgeGraphsIsLoading,
  ]);

  useEffect(() => {
    if (
      selectedNeo4jProfile == null &&
      selectedClient != null &&
      !getNeo4jProfileIsLoading
    ) {
      getNeo4jProfile(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNeo4jProfile, selectedClient, getNeo4jProfileIsLoading]);

  // logic to get a knowledge graph from the url param after a refresh or when state is cleared
  useEffect(() => {
    if (
      selectedKnowledgeGraph == null &&
      availableKnowledgeGraphs != null &&
      selectedClient != null
    ) {
      if (knowledgeGraphSK == null || knowledgeGraphSK.length === 0) {
        // there is no url param to use so just go back to the knowledge graph table
        navigate('/knowledgeGraph');
      }

      if (knowledgeGraphSK != null) {
        const clientFromSK = knowledgeGraphSK.split('#')[0];

        if (
          clientFromSK.toUpperCase() !== selectedClient.clientName.toUpperCase()
        ) {
          // we are attempting to grab a knowledge graph from a client that is not currently selected
          navigate('/knowledgeGraph');
        } else {
          const knowledgeGraphIndex = availableKnowledgeGraphs.findIndex(
            knowledgeGraph => knowledgeGraph.SK === knowledgeGraphSK,
          );

          if (knowledgeGraphIndex > -1) {
            // we have found our knowledge graph
            setSelectedKnowledgeGraph(
              availableKnowledgeGraphs[knowledgeGraphIndex],
            );
          } else {
            navigate('/knowledgeGraph');
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedKnowledgeGraph,
    availableKnowledgeGraphs,
    selectedClient,
    knowledgeGraphSK,
  ]);

  useEffect(() => {
    setDriverIsLoading(true);

    if (!getNeo4jProfileIsLoading && selectedNeo4jProfile != null) {
      const { uri, protocol } = parseNeo4jUri(selectedNeo4jProfile.neo4jURI);

      const driver = createDriver(
        protocol,
        uri,
        '7687',
        selectedNeo4jProfile.neo4jUser,
        selectedNeo4jProfile.neo4jPW,
      );

      setNeo4jDriver(driver);
      setDriverIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedNeo4jProfile, getNeo4jProfileIsLoading]);

  if (
    selectedKnowledgeGraph == null ||
    getAvailableKnowledgeGraphsIsLoading ||
    availableKnowledgeGraphs == null ||
    selectedClient == null ||
    neo4jDriver == null ||
    getNeo4jProfileIsLoading ||
    driverIsLoading
  ) {
    return <KnowledgeGraphVisualizationPageLoader />;
  }

  return (
    // this package has multiple driver exports and none of them work. Really bad typing
    <Neo4jProvider driver={neo4jDriver}>
      <KnowledgeGraphVisualizationPageBody />
    </Neo4jProvider>
  );
}
