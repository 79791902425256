import {
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  MultiCovariantAnalysisNodeType,
  OutputDatasetNodeType,
} from '../../types/nodes';
import { updateKnowledgeGraphWithInputNodeData } from './knowledgeGraphNodeFunctions';

// ====================== OUTPUT DATASET AS TARGET ======================

export const disconnectNodeFromOutputDataset = <Type>(
  sourceNode: Type,
  targetNode: OutputDatasetNodeType,
): [Type, OutputDatasetNodeType] => {
  let updatedTargetNode: OutputDatasetNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: -1,
    inputDatasetName: undefined,
    inputObjId: undefined,
  };

  return [sourceNode, updatedTargetNode];
};

// ====================== OUTPUT DATASET AS SOURCE ======================

export const connectOutputDatasetToMultiCovariantAnalysis = (
  sourceNode: OutputDatasetNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [OutputDatasetNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    inputNodeName: sourceNode.data.nodeName,
    fields: sourceNode.data?.fields,
  };

  return [sourceNode, updatedTargetNode];
};

export const connectOutputDatasetToKnowledgeGraph = (
  sourceNode: OutputDatasetNodeType,
  targetNode: KnowledgeGraphNodeType,
): [OutputDatasetNodeType, KnowledgeGraphNodeType] => {
  //Format the OutputDataset Node into the shape of a dataset node for consistency in the Knowledge Graph Node
  const formattedOutputDatasetNode: InputDatasetNodeType = {
    ...sourceNode,
    data: {
      PK: sourceNode.data.PK,
      SK: sourceNode.data.SK,
      inputDatasetName: sourceNode.data?.outputDatasetName,
      outputDatasetName: sourceNode.data?.outputDatasetName,
      parquetLocation: sourceNode.data?.parquetLocation,
      fields: sourceNode.data?.fields ?? [],
      processId: sourceNode.data.processId,
      objId: sourceNode.data.objId,
      nodeName: sourceNode.data.outputDatasetName,
    },
  };

  const updatedTargetNode = updateKnowledgeGraphWithInputNodeData(
    formattedOutputDatasetNode,
    targetNode,
  );

  return [sourceNode, updatedTargetNode];
};
