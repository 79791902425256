export interface ColorTypes {
  primaryRed: string;
  notificationRed: string;
  surfaceGray: string;
  disabledGray: string;
  text: string;
  warningYellow: string;
  successGreen: string;
  salmon: string;
  highlightBlue: string;
  dividerGray: string;
  classyGray: string;
  tertiaryGreen: string;
  secondaryBlue: string;
  lightPrimaryRed: string;
  fadedPrimaryRed: string;
}

const colors: ColorTypes = {
  primaryRed: '#670202',
  lightPrimaryRed: '#C55C4C',
  fadedPrimaryRed: '#FFB29C',
  tertiaryGreen: '#00722E',
  secondaryBlue: '#0059C5',
  salmon: '#F55F4C',
  highlightBlue: '#00E7F4',
  surfaceGray: '#e5e5e5',
  disabledGray: '#BDBDBD',
  notificationRed: '#E81F2E',
  warningYellow: '#FFE900',
  successGreen: '#00C9A7',
  text: '#000000',
  dividerGray: '#B9B9B9',
  classyGray: '#B0A8B9',
};

export const chartColors = [
  '#3366cc',
  '#dc3912',
  '#ff9900',
  '#109618',
  '#990099',
  '#0099c6',
  '#dd4477',
  '#66aa00',
  '#b82e2e',
  '#316395',
  '#3366cc',
  '#994499',
  '#22aa99',
  '#aaaa11',
  '#6633cc',
  '#e67300',
  '#8b0707',
  '#651067',
  '#329262',
  '#5574a6',
  '#3b3eac',
  '#b77322',
  '#16d620',
  '#b91383',
  '#f4359e',
  '#9c5935',
  '#a9c413',
  '#2a778d',
  '#668d1c',
  '#bea413',
  '#0c5922',
  '#743411',
];

export default colors;
