import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { FaX } from 'react-icons/fa6';
import { useUserState } from '../../../state/userState';
import {
  CenterAlignedFlexBox,
  StyledSkeleton,
} from '../../../styles/commonStyles';
import { theme } from '../../../styles/theme';
import { useLocation, useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';
import { useKnowledgeGraphState } from '../../../state/knowledgeGraphState';

const ClientSelect = styled(Select)(({ theme, disabled }) => ({
  '.MuiSelect-icon': {
    fill: theme.palette.primary.contrastText,
    display: disabled ? 'none' : '',
  },
  color: theme.palette.primary.contrastText,
  '.MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '&:hover': {
    backgroundColor: disabled
      ? theme.palette.primary.main
      : theme.palette.primary.dark,
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: theme.palette.primary.contrastText,
  },
}));

const formatClientNames = (clients: string[]): string[] => {
  return clients.map(
    //capitalizes the first letter of the client names
    clientName => clientName[0].toUpperCase() + clientName.slice(1),
  );
};

const capitalizeClientName = (
  clientName: string | undefined,
): string | undefined => {
  if (clientName == null) return undefined;

  return clientName[0].toUpperCase() + clientName.slice(1);
};

export default function ClientSelector() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userProfile, selectedClient, setSelectedClientName } = useUserState();
  const { setSelectedKnowledgeGraph } = useKnowledgeGraphState();

  const clients = useMemo(
    () =>
      userProfile != null ? formatClientNames(userProfile.clientList) : [],
    [userProfile],
  );
  const userOnlyHasOneClient = useMemo(() => clients.length === 1, [clients]);
  const capitalClientName = useMemo(
    () => capitalizeClientName(selectedClient?.clientName),
    [selectedClient],
  );

  const [, setClientNameParam] = useQueryParam('clientName', StringParam);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (
        event?.target &&
        event.target?.value &&
        typeof event.target.value === typeof ''
      ) {
        setSelectedClientName(event.target.value);
        setClientNameParam(event.target.value);
        // if the user is on the reports page, navigate them back to the datalake to force them to select a new file
        // we do this because the files are unique to each client so if the client switches while viewing file specific data, we need to reset
        if (location.pathname.includes('/reports')) {
          navigate('/datalake');
        }

        if (location.pathname.includes('/knowledgeGraph/visualize')) {
          navigate('/knowledgeGraph');
          setSelectedKnowledgeGraph(undefined);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname, navigate, setSelectedClientName],
  );

  useEffect(() => {
    if (capitalClientName != null) {
      setClientNameParam(capitalClientName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  if (capitalClientName == null) {
    return (
      <StyledSkeleton
        width="8.375rem"
        height="1.5rem"
        variant="rectangular"
        sx={{ bgcolor: '#FFFFFF55' }}
      />
    );
  }

  return (
    <CenterAlignedFlexBox>
      <FaX size={11} color={theme.palette.primary.contrastText} />
      <FormControl sx={{ minWidth: 120 }} size="small">
        <ClientSelect
          id="select-client"
          value={capitalClientName}
          disabled={userOnlyHasOneClient}
          // There's a bug with styled compnents that changes the event type, ignore it here
          //@ts-ignore
          onChange={handleChange}
          theme={theme}
        >
          {clients.map(client => (
            <MenuItem key={client} value={client}>
              {client}
            </MenuItem>
          ))}
        </ClientSelect>
      </FormControl>
    </CenterAlignedFlexBox>
  );
}
