import { useMemo } from 'react';
import { StyledSkeleton } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  BodyContainer,
  DatabaseSummaryContainer,
  HeaderContainer,
  SummaryContainer,
} from './databaseSummary';
import { TagContainer } from './graphSidebar';
import {
  KnowledgeGraphPageContainer,
  KnowledgeGraphHeaderContainer,
  CypherInputContainer,
} from './pageBody';
import KnowledgeGraphVisualizationSkeletonLoaders, {
  NodeTagSkeleton,
  PropertyTagSkeleton,
  randomIntFromInterval,
  RelationshipTagSkeleton,
} from './skeletonLoaders';

export default function KnowledgeGraphVisualizationPageLoader() {
  const nodeTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(3, 8) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(3, 6)}rem`;
        return (
          <NodeTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  const relationshipTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(2, 6) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(6, 10)}rem`;
        return (
          <RelationshipTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  const propertyTagSkeletons = useMemo(
    () =>
      Array.from(
        { length: randomIntFromInterval(12, 18) },
        (_, index) => index,
      ).map(x => {
        const width = `${randomIntFromInterval(6, 15)}rem`;
        return (
          <PropertyTagSkeleton
            key={x}
            variant="rectangular"
            animation="wave"
            width={width}
          />
        );
      }),
    [],
  );

  return (
    <KnowledgeGraphPageContainer>
      <KnowledgeGraphHeaderContainer style={{ marginBottom: theme.spacing(1) }}>
        {/* KG name */}
        <StyledSkeleton
          variant="rectangular"
          animation="wave"
          height="2.5rem"
          width="20vw"
        />
      </KnowledgeGraphHeaderContainer>

      {/* Database Summary */}
      <DatabaseSummaryContainer style={{ height: '24rem' }}>
        <BodyContainer sx={{ overflow: 'hidden' }}>
          <HeaderContainer sx={{ marginBottom: theme.spacing(2) }}>
            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="2rem"
              width="15rem"
            />
            <StyledSkeleton
              variant="circular"
              animation="wave"
              height="2rem"
              width="2rem"
            />
          </HeaderContainer>

          <SummaryContainer sx={{ gap: '1rem', overflow: 'hidden' }}>
            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="1.75rem"
              width="6rem"
            />
            <TagContainer>{nodeTagSkeletons}</TagContainer>

            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="1.75rem"
              width="10rem"
            />
            <TagContainer>{relationshipTagSkeletons}</TagContainer>

            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="1.75rem"
              width="10rem"
            />
            <TagContainer>{propertyTagSkeletons}</TagContainer>
          </SummaryContainer>
        </BodyContainer>
      </DatabaseSummaryContainer>

      <CypherInputContainer>
        {/* Cypher help text */}
        <StyledSkeleton
          variant="rectangular"
          animation="wave"
          height="1rem"
          width="5rem"
          style={{ marginBottom: theme.spacing(1) }}
        />
        {/* Cypher Input */}
        <StyledSkeleton
          variant="rectangular"
          animation="wave"
          height="4.5rem"
          width="100%"
        />
      </CypherInputContainer>

      {/* Graph and Sidebar */}
      <KnowledgeGraphVisualizationSkeletonLoaders />
    </KnowledgeGraphPageContainer>
  );
}
