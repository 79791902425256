import { Box, Collapse, IconButton, styled, Typography } from '@mui/material';
import { memo, useCallback, useState } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { ColumnFlex, SpaceBetweenRowFlex } from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import { GraphTag } from '../../types/knowledgeGraph';
import NodeTag from './nodeTag';
import PropertyTag from './propertyTag';
import RelationshipTag from './relationshipTag';

export const DatabaseSummaryContainer = styled(Box)({
  width: 'calc(100% - 2px)', // acounts for the border
  height: 'fit-content',
  borderRadius: theme.borderRadiusRem,
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.surface.default,
});

export const BodyContainer = styled(ColumnFlex)({
  padding: theme.defaultPaddingRem,
});

export const HeaderContainer = styled(SpaceBetweenRowFlex)({
  alignItems: 'center',
});

const TagContainer = styled(Box)({
  display: 'flex',
  height: 'fit-content',
  flexDirection: 'row',
  gap: '1rem',
  flexWrap: 'wrap',
  marginBottom: theme.defaultPaddingRem,
});

export const SummaryContainer = styled(ColumnFlex)({
  width: 'inherit',
  height: 'inherit',
  overflowY: 'scroll',
});

interface DatabaseSummaryProps {
  disabled: boolean;
  nodeTags: GraphTag[];
  relationshipTags: GraphTag[];
  propertyTags: GraphTag[];
  onNodeClick: (label: string) => void;
  onRelationshipClick: (label: string) => void;
  onPropertyClick: (label: string) => void;
}

export default memo(
  ({
    nodeTags,
    relationshipTags,
    propertyTags,
    onNodeClick,
    onRelationshipClick,
    onPropertyClick,
  }: DatabaseSummaryProps) => {
    const [expanded, setExpanded] = useState(true);

    const toggleExpanded = useCallback(() => {
      setExpanded(!expanded);
    }, [expanded, setExpanded]);

    return (
      <DatabaseSummaryContainer>
        <BodyContainer>
          <HeaderContainer>
            <Typography fontWeight={400} variant="h6">
              Database Summary
            </Typography>
            {/* TODO: Add in icons with quantities here */}
            {/* <Typography>Nodes</Typography>
            <Typography>Relationships</Typography>
            <Typography>Property Keys</Typography> */}

            <IconButton onClick={toggleExpanded}>
              {!expanded && (
                <FaChevronDown color={theme.palette.text.primary} />
              )}
              {expanded && <FaChevronUp color={theme.palette.text.primary} />}
            </IconButton>
          </HeaderContainer>

          <Collapse in={expanded}>
            <SummaryContainer>
              {/* TODO: add quantities */}
              <Typography variant="body1" gutterBottom>
                Nodes
              </Typography>
              <TagContainer>
                {nodeTags
                  .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
                  .map(tag => (
                    <NodeTag
                      {...tag}
                      disableToolTip
                      key={`${tag.label}`}
                      onClick={() => onNodeClick(tag.label)}
                    />
                  ))}
              </TagContainer>

              <Typography variant="body1" gutterBottom>
                Relationships
              </Typography>
              <TagContainer>
                {relationshipTags
                  .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
                  .map(tag => (
                    <RelationshipTag
                      {...tag}
                      disableToolTip
                      key={`${tag.label}`}
                      onClick={() => onRelationshipClick(tag.label)}
                    />
                  ))}
              </TagContainer>

              <Typography variant="body1" gutterBottom>
                Property Keys
              </Typography>
              <TagContainer>
                {propertyTags
                  .sort((tagA, tagB) => tagA.label.localeCompare(tagB.label))
                  .map(tag => (
                    <PropertyTag
                      {...tag}
                      disableToolTip
                      key={`${tag.label}`}
                      onClick={() => onPropertyClick(tag.label)}
                    />
                  ))}
              </TagContainer>
            </SummaryContainer>
          </Collapse>
        </BodyContainer>
      </DatabaseSummaryContainer>
    );
  },
);
