import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo } from 'react';
import { useQueryParam } from 'use-query-params';
import { useKnowledgeGraphState } from '../../state/knowledgeGraphState';
import { useUserState } from '../../state/userState';
import {
  FullWidthContainer,
  StyledSkeleton,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  KnowledgeGraphColumn,
  OrderByKnowledgeGraphParam,
  RowData,
  RowsPerPageKnowledgeGraphParam,
} from '../../types/knowledgeGraphTable';
import {
  OrderParam,
  PageNumParam,
  defaultPageNum,
  getComparator,
  stableSort,
} from '../../types/tables';
import WarningMessage from '../common/warningMessage';
import KnowledgeGraphTableBody from './tableBody';
import KnowledgeGraphTableHeader from './tableHeader';

const SkeletonLoadersContainer = styled(Box)({
  display: 'grid',
  rowGap: theme.spacing(1),
}) as typeof Box;

const columns: readonly KnowledgeGraphColumn[] = [
  { id: 'knowledgeGraphName', label: 'Name', minWidth: '8dvw', align: 'left' },
  { id: 'client', label: 'Client', minWidth: '7dvw', align: 'left' },
  {
    id: 'nodeNames',
    label: 'Nodes',
    align: 'left',
    minWidth: '15dvw',
  },
  {
    id: 'relationshipNames',
    label: 'Relationships',
    align: 'left',
    minWidth: '15dvw',
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'right',
    minWidth: '10dvw',
  },
];

export default function KnowledgeGraphTable() {
  // query param variables
  const [page, setPage] = useQueryParam('pageNum', PageNumParam);
  const [rowsPerPage, setRowsPerPage] = useQueryParam(
    'rowsPerPage',
    RowsPerPageKnowledgeGraphParam,
  );
  // these values are handled in the <KnowledgeGraphTableHeader> component but we read them here too
  const [order] = useQueryParam('order', OrderParam);
  const [orderBy] = useQueryParam('orderBy', OrderByKnowledgeGraphParam);

  const {
    formattedKnowledgeGraphs,
    getAvailableKnowledgeGraphsIsLoading,
    getAvailableKnowledgeGraphsError,
    getAvailableKnowledgeGraphs,
    getNeo4jProfile,
    getNeo4jProfileIsLoading,
    getNeo4jProfileError,
    selectedNeo4jProfile,
  } = useKnowledgeGraphState();
  const { selectedClient } = useUserState();

  const rows: RowData[] = useMemo(
    () => formattedKnowledgeGraphs ?? [],
    [formattedKnowledgeGraphs],
  );

  // const filteredRows: RowData[] = useMemo(
  //   () =>
  //     rows.filter(row => {
  //       if (showProcessFlows && showUploads) return true;

  //       if (showProcessFlows && !showUploads) return row.type === 'processFlow';

  //       if (!showProcessFlows && showUploads) return row.type === 'upload';

  //       if (!showProcessFlows && !showUploads) return false;

  //       return true;
  //     }),
  //   [rows, showProcessFlows, showUploads],
  // );

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(rows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rows, rowsPerPage],
  );

  // grabs available knowledge graphs on load
  useEffect(() => {
    if (selectedClient != null && !getAvailableKnowledgeGraphsIsLoading) {
      getAvailableKnowledgeGraphs(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableKnowledgeGraphs, selectedClient]);

  // grabs available knowledge graph profiles on load
  useEffect(() => {
    if (selectedClient != null && !getNeo4jProfileIsLoading) {
      getNeo4jProfile(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getNeo4jProfile, selectedClient]);

  const updateTableDataState = useCallback((): number => {
    if (
      getAvailableKnowledgeGraphsError &&
      !getAvailableKnowledgeGraphsIsLoading
    ) {
      return -1;
    }

    if (getNeo4jProfileError && !getNeo4jProfileIsLoading) {
      return -1;
    }

    if (
      getAvailableKnowledgeGraphsIsLoading ||
      getNeo4jProfileIsLoading ||
      selectedNeo4jProfile == null ||
      formattedKnowledgeGraphs == null
    ) {
      return 0;
    }

    if (formattedKnowledgeGraphs != null) {
      return formattedKnowledgeGraphs.length > 0 ? 1 : 2;
    }

    return -1;
  }, [
    formattedKnowledgeGraphs,
    getAvailableKnowledgeGraphsIsLoading,
    getAvailableKnowledgeGraphsError,
    getNeo4jProfileError,
    getNeo4jProfileIsLoading,
    selectedNeo4jProfile,
  ]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const tableDataState = useMemo(
    () => updateTableDataState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateTableDataState,
      formattedKnowledgeGraphs,
      getAvailableKnowledgeGraphsIsLoading,
      getAvailableKnowledgeGraphsError,
      getNeo4jProfileError,
      getNeo4jProfileIsLoading,
      selectedNeo4jProfile,
    ],
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(defaultPageNum);
  };

  // handles if the filters update in such a way that the new length of available pages exceeds the current page number, reset the page number to its default
  useEffect(() => {
    if (rows.length / rowsPerPage > page + 1) {
      setPage(defaultPageNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows, rowsPerPage]);

  const skeletonLoaders = Array.from({ length: rowsPerPage }, (_, index) => (
    <StyledSkeleton
      key={index}
      variant="rectangular"
      animation="wave"
      height="4rem"
    />
  ));

  // Loading
  if (tableDataState === 0) {
    return (
      <SkeletonLoadersContainer>{skeletonLoaders}</SkeletonLoadersContainer>
    );
  }
  // Data is valid and there is data
  if (visibleRows && tableDataState === 1) {
    return (
      <FullWidthContainer>
        <TableContainer sx={{ width: '100%' }}>
          <Table stickyHeader aria-label="RKE Knowledge Graph Table">
            <KnowledgeGraphTableHeader columns={columns} />
            <KnowledgeGraphTableBody
              columns={columns}
              visibleRowsProp={visibleRows}
            />
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 100]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </FullWidthContainer>
    );
  }

  // Data is valid but empty
  if (visibleRows && tableDataState === 2) {
    return (
      <WarningMessageBox>
        <WarningMessage>
          There were no saved Knowledge Graphs found.
        </WarningMessage>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <WarningMessage>
        There was an error getting your data. Refresh to try again.
      </WarningMessage>
    </WarningMessageBox>
  );
}
