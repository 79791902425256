import { Box, styled } from '@mui/material';
import { theme } from '../styles/theme';
import SetupReportsData from '../components/reports/setupReportsData';
import PivotTablePageBody from '../components/pivotTable/pageBody';

const PivotTablePageContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  paddingTop: theme.defaultPaddingRem,
}) as typeof Box;

export default function PivotTablePage() {
  return (
    <PivotTablePageContainer>
      <SetupReportsData>
        <PivotTablePageBody />
      </SetupReportsData>
    </PivotTablePageContainer>
  );
}
