import { Box, Button, TextField, styled } from '@mui/material';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useQueryParam } from 'use-query-params';
import { saveReportService } from '../../services/reportsService';
import { useReportsState } from '../../state/reportsState';
import {
  ColumnFlex,
  FlexBox,
  FullWidthColumnFlex,
  TwoMarginBottomDivider,
} from '../../styles/commonStyles';
import { MarginRightButton } from '../../styles/inputStyles';
import { theme } from '../../styles/theme';
import { PlotQueryParam } from '../../types/plots';
import {
  AggregatorParam,
  PivotTableArrayParam,
  RendererParam,
  Report,
  SortOrderParam,
  defaultAggregatorName,
  defaultArray,
  defaultRendererName,
  defaultSortOrder,
} from '../../types/reports';

export const MenuContainer = styled(Box)({
  display: 'flex',
  backgroundColor: theme.palette.surface.default,
  width: `calc(100% - ${theme.defaultPaddingRem})`,
  height: 'fit-content',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}) as typeof Box;

export default function PivotTableHeader() {
  const navigate = useNavigate();
  const { selectedReport, setSelectedReport, getAvailableReports } =
    useReportsState();
  const { fileNameParam } = useParams();

  const [name, setName] = useQueryParam('name', PlotQueryParam);
  const [aggregatorName] = useQueryParam('aggregatorName', AggregatorParam);
  const [rendererName] = useQueryParam('rendererName', RendererParam);
  const [rows] = useQueryParam('rows', PivotTableArrayParam);
  const [cols] = useQueryParam('cols', PivotTableArrayParam);
  const [colOrder] = useQueryParam('colOrder', SortOrderParam);
  const [rowOrder] = useQueryParam('rowOrder', SortOrderParam);

  const handleCancelClick = useCallback(() => {
    setSelectedReport(undefined);
    if (fileNameParam != null) {
      navigate(`/datalake/${fileNameParam}/reports`, {
        // this prevents the reports from reloading when navigating back
        state: { navigatedFromBreadcrumb: true },
      });
    } else {
      navigate('/datalake', { state: { navigatedFromBreadcrumb: true } });
    }
  }, [setSelectedReport, fileNameParam, navigate]);

  const handleSaveClick = useCallback(async () => {
    if (selectedReport != null) {
      const updatedReport: Report = {
        ...selectedReport,
        name,
        rows: rows ?? defaultArray,
        cols: cols ?? defaultArray,
        rowOrder: rowOrder ?? defaultSortOrder,
        colOrder: colOrder ?? defaultSortOrder,
        rendererName: rendererName ?? defaultRendererName,
        aggregatorName: aggregatorName ?? defaultAggregatorName,
        type: 'pivotTable',
      };

      await saveReportService(updatedReport);
      getAvailableReports(updatedReport.client, updatedReport.fileSK);
    }
  }, [
    aggregatorName,
    colOrder,
    cols,
    getAvailableReports,
    name,
    rendererName,
    rowOrder,
    rows,
    selectedReport,
  ]);

  return (
    <FullWidthColumnFlex>
      <MenuContainer>
        <ColumnFlex>
          <TextField
            variant="filled"
            size="medium"
            label="Report Name"
            value={name}
            placeholder="New Report"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setName(event.target.value);
            }}
          />
        </ColumnFlex>
        <FlexBox>
          <MarginRightButton onClick={handleCancelClick}>
            Cancel
          </MarginRightButton>
          <Button color="success" variant="contained" onClick={handleSaveClick}>
            Save
          </Button>
        </FlexBox>
      </MenuContainer>

      <TwoMarginBottomDivider />
    </FullWidthColumnFlex>
  );
}
