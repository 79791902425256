import { Box, styled } from '@mui/material';

import { theme } from '../styles/theme';
import KnowledgeGraphTable from '../components/knowledgeGraphTable/table';
import KnowledgeGraphHeader from '../components/knowledgeGraphTable/header';

const KnowledgeGraphTableContainer = styled(Box)({
  paddingTop: theme.defaultPaddingRem,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'start',
  width: '100%',
}) as typeof Box;

const KnowledgeGraphTableBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}) as typeof Box;

const KnowledgeGraphTablePage = () => {
  return (
    <KnowledgeGraphTableContainer>
      <KnowledgeGraphTableBody>
        <KnowledgeGraphHeader />
        <KnowledgeGraphTable />
      </KnowledgeGraphTableBody>
    </KnowledgeGraphTableContainer>
  );
};

export default KnowledgeGraphTablePage;
