import { Box, Typography, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useFileConfigState } from '../../state/fileconfigState';
import {
  CenterAlignedRowFlex,
  StyledSkeleton,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';

const HeaderContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1),
}) as typeof Box;

const LabelContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
}) as typeof Box;

const SkeletonLoadersContainer = styled(Box)({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between',
}) as typeof Box;

const OneGapRowFlex = styled(CenterAlignedRowFlex)({
  gap: theme.spacing(1),
});

export default function KnowledgeGraphHeader() {
  const { getFileConfigIsLoading, formattedFileConfigs, getFileConfigsError } =
    useFileConfigState();

  const updateTableDataState = useCallback((): number => {
    if (getFileConfigsError && !getFileConfigIsLoading) {
      return -1;
    }

    if (getFileConfigIsLoading || formattedFileConfigs == null) {
      return 0;
    }

    if (formattedFileConfigs != null) {
      return formattedFileConfigs.length > 0 ? 1 : 2;
    }

    return -1;
  }, [formattedFileConfigs, getFileConfigIsLoading, getFileConfigsError]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const tableDataState = useMemo(
    () => updateTableDataState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateTableDataState,
      formattedFileConfigs,
      getFileConfigIsLoading,
      getFileConfigsError,
    ],
  );

  return (
    <HeaderContainer>
      {(tableDataState === -1 || tableDataState >= 1) && (
        <LabelContainer>
          <Typography variant="h4">Knowledge Graphs</Typography>
        </LabelContainer>
      )}

      {/* Loading */}
      {tableDataState === 0 && (
        <SkeletonLoadersContainer>
          <OneGapRowFlex>
            <StyledSkeleton
              variant="rectangular"
              animation="wave"
              height="3rem"
              width={'40vw'}
            />
          </OneGapRowFlex>
        </SkeletonLoadersContainer>
      )}
    </HeaderContainer>
  );
}
