import {
  Box,
  // Button,
  // FormControl,
  // IconButton,
  // MenuItem,
  // TextField,
  // Tooltip,
  // Typography,
  styled,
} from '@mui/material';
// import * as Plot from '@observablehq/plot';
// import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// import { FaExchangeAlt, FaInfoCircle } from 'react-icons/fa';
// import { useNavigate, useParams } from 'react-router-dom';
// import { useQueryParam } from 'use-query-params';
// import { saveReportService } from '../../services/reportsService';
// import { useReportsState } from '../../state/reportsState';
// import {
//   ColumnFlex,
//   FlexBox,
//   FullWidthColumnFlex,
//   TwoMarginBottomDivider,
//   WarningMessageBox,
// } from '../../styles/commonStyles';
// import {
//   HighlightOnMatchSelect,
//   HighlightOnMatchSelectLabel,
//   MarginRightButton,
// } from '../../styles/inputStyles';
// import { PLOT_LEFT_MARGIN, standardPlotColor } from '../../styles/plotStyles';
import { theme } from '../../styles/theme';
// import {
//   AxesDataType,
//   GroupByType,
//   PlotQueryParam,
//   PlotTypes,
// } from '../../types/plots';
// import ReducerSelector from './reducerSelector';

export const AxesSelectContainer = styled(Box)({
  gridTemplateColumns:
    'minmax(9.375rem, 1fr) auto minmax(9.375rem, 1fr) auto minmax(9.375rem, 1fr) auto minmax(9.375rem, 1fr) auto',
  display: 'grid',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  rowGap: theme.spacing(1),
  width: '100%',
  height: 'fit-content',
  marginBottom: theme.spacing(2),
  alignItems: 'center',
}) as typeof Box;

// const MenuContainer = styled(Box)({
//   display: 'flex',
//   backgroundColor: theme.palette.surface.default,
//   width: `calc(100% - ${theme.defaultPaddingRem})`,
//   height: 'fit-content',
//   justifyContent: 'space-between',
//   alignItems: 'center',
//   marginBottom: theme.spacing(2),
// }) as typeof Box;

// const StyledFormControl = styled(FormControl)({
//   display: 'flex',
//   minWidth: '9.375rem',
// }) as typeof FormControl;

// const getKeysFromData = (data: any[]): string[] => {
//   if (data.length === 0) return [];

//   return Object.keys(data[0]);
// };

// const createXGroupYPlot = (
//   data: any[],
//   groupX: GroupByType,
//   plotType: PlotTypes,
// ) => {
//   if (plotType === 'bar') return Plot.barY(data, groupX);
//   if (plotType === 'dot') return Plot.dotY(data, groupX);
//   if (plotType === 'line') return Plot.lineY(data, groupX);
//   if (plotType === 'area') return Plot.areaY(data, groupX);

//   return Plot.barY(data, groupX);
// };

// const createXDataYReducerPlot = (
//   xAxis: string,
//   yAxis: string,
//   data: any[],
//   plotType: PlotTypes,
// ): ((HTMLElement | SVGSVGElement) & Plot.Plot) | null => {
//   const groupXData = Plot.groupX(
//     // Observable has a "count" reducer but labels it as "frequency" on the axis label. So we account for that here via casting frequency to count
//     //@ts-ignore
//     { y: yAxis === 'frequency' ? 'count' : yAxis },
//     {
//       x: xAxis,
//       y: yAxis,
//       fill: xAxis,
//     },
//   );

//   return Plot.plot({
//     color: standardPlotColor,
//     marks: [Plot.ruleY([0]), createXGroupYPlot(data, groupXData, plotType)],
//   });
// };

// const createYGroupXPlot = (
//   data: any[],
//   groupY: GroupByType,
//   plotType: PlotTypes,
// ) => {
//   if (plotType === 'bar') return Plot.barX(data, groupY);
//   if (plotType === 'dot') return Plot.dotX(data, groupY);
//   if (plotType === 'line') return Plot.lineX(data, groupY);
//   if (plotType === 'area') return Plot.areaX(data, groupY);

//   return Plot.barX(data, groupY);
// };

// const createXReducerYDataPlot = (
//   xAxis: string,
//   yAxis: string,
//   data: any[],
//   plotType: PlotTypes,
// ): ((HTMLElement | SVGSVGElement) & Plot.Plot) | null => {
//   const groupYData = Plot.groupY(
//     // Observable has a "count" reducer but labels it as "frequency" on the axis label. So we account for that here via casting frequency to count
//     //@ts-ignore
//     { x: xAxis === 'frequency' ? 'count' : xAxis },
//     {
//       x: xAxis,
//       y: yAxis,
//       fill: yAxis,
//     },
//   );

//   return Plot.plot({
//     marginLeft: PLOT_LEFT_MARGIN,
//     color: standardPlotColor,
//     marks: [Plot.ruleX([0]), createYGroupXPlot(data, groupYData, plotType)],
//   });
// };

// const createNonGroupedPlot = (
//   data: any[],
//   axesData: AxesDataType,
//   plotType: PlotTypes,
// ) => {
//   if (plotType === 'bar') return Plot.barY(data, axesData);
//   if (plotType === 'dot') return Plot.dotY(data, axesData);
//   if (plotType === 'line') return Plot.lineY(data, axesData);
//   if (plotType === 'area') return Plot.areaY(data, axesData);

//   return Plot.barY(data, axesData);
// };

// const createXYDataPlot = (
//   xAxis: string,
//   yAxis: string,
//   data: any[],
//   plotType: PlotTypes,
// ): ((HTMLElement | SVGSVGElement) & Plot.Plot) | null => {
//   const axesData = {
//     x: xAxis,
//     y: yAxis,
//     fill: xAxis,
//   };

//   return Plot.plot({
//     color: standardPlotColor,
//     marks: [Plot.ruleY([0]), createNonGroupedPlot(data, axesData, plotType)],
//   });
// };

// interface PlotAxesSelectorProps {
//   chartData: any[];
//   plotType: PlotTypes;
// }

// export default function PlotAxesSelector({
//   chartData,
//   plotType,
// }: PlotAxesSelectorProps) {
//   // const containerRef = useRef();
//   // const navigate = useNavigate();
//   // const { setSelectedReport, selectedReport, getAvailableReports } =
//   //   useReportsState();
//   // const { fileNameParam } = useParams();

//   // const data = useMemo(() => chartData, [chartData]);
//   // const fieldNames = useMemo(() => getKeysFromData(data), [data]);

//   // const [xAxis, setXAxis] = useQueryParam('xAxis', PlotQueryParam);
//   // const [yAxis, setYAxis] = useQueryParam('yAxis', PlotQueryParam);
//   // const [xAxisFieldName, setXAxisFieldName] = useQueryParam(
//   //   'xAxisFieldName',
//   //   PlotQueryParam,
//   // );
//   // const [yAxisFieldName, setYAxisFieldName] = useQueryParam(
//   //   'yAxisFieldName',
//   //   PlotQueryParam,
//   // );
//   // const [xAxisReducer, setXAxisReducer] = useQueryParam(
//   //   'xAxisReducer',
//   //   PlotQueryParam,
//   // );
//   // const [yAxisReducer, setYAxisReducer] = useQueryParam(
//   //   'yAxisReducer',
//   //   PlotQueryParam,
//   // );
//   // const [name, setName] = useQueryParam('name', PlotQueryParam);
//   // const [errorMessage, setErrorMessage] = useState<string | undefined>(
//   //   undefined,
//   // );

//   // // these highlight variables control which select input is highlighted, indicating which one is being used for the plot
//   // const highlightXAxisFieldName = useMemo(
//   //   () => xAxis !== '' && xAxis === xAxisFieldName,
//   //   [xAxis, xAxisFieldName],
//   // );
//   // const highlightXAxisReducer = useMemo(
//   //   () => xAxis !== '' && xAxis === xAxisReducer,
//   //   [xAxis, xAxisReducer],
//   // );
//   // const highlightYAxisFieldName = useMemo(
//   //   () => yAxis !== '' && yAxis === yAxisFieldName,
//   //   [yAxis, yAxisFieldName],
//   // );
//   // const highlightYAxisReducer = useMemo(
//   //   () => yAxis !== '' && yAxis === yAxisReducer,
//   //   [yAxis, yAxisReducer],
//   // );

//   // const swapAxes = () => {
//   //   const tempXAxis = xAxis;
//   //   const tempXAxisFieldName = xAxisFieldName;
//   //   const tempXAxisReducer = xAxisReducer;

//   //   setXAxis(yAxis);
//   //   setYAxis(tempXAxis);
//   //   setXAxisFieldName(yAxisFieldName);
//   //   setYAxisFieldName(tempXAxisFieldName);
//   //   setXAxisReducer(yAxisReducer);
//   //   setYAxisReducer(tempXAxisReducer);
//   // };

//   // const handleCancelClick = useCallback(() => {
//   //   setSelectedReport(undefined);
//   //   if (fileNameParam != null) {
//   //     navigate(`/datalake/${fileNameParam}/reports`, {
//   //       // this prevents the reports from reloading when navigating back
//   //       state: { navigatedFromBreadcrumb: true },
//   //     });
//   //   } else {
//   //     navigate('/datalake', { state: { navigatedFromBreadcrumb: true } });
//   //   }
//   // }, [setSelectedReport, fileNameParam, navigate]);

//   // const handleSaveClick = useCallback(async () => {
//   //   if (selectedReport != null) {
//   //     const updatedReport: DepreciatedReport = {
//   //       ...selectedReport,
//   //       name,
//   //       xAxis,
//   //       yAxis,
//   //       xAxisReducer: highlightXAxisReducer,
//   //       yAxisReducer: highlightYAxisReducer,
//   //     };

//   //     await saveReportService(updatedReport);
//   //     getAvailableReports(updatedReport.client, updatedReport.fileSK);
//   //   }
//   // }, [
//   //   getAvailableReports,
//   //   highlightXAxisReducer,
//   //   highlightYAxisReducer,
//   //   name,
//   //   selectedReport,
//   //   xAxis,
//   //   yAxis,
//   // ]);

//   // useEffect(() => {
//   //   //ensure all of the variables are set up before attempting to render a plot
//   //   if (
//   //     data === undefined ||
//   //     containerRef == null ||
//   //     xAxis === '' ||
//   //     yAxis === ''
//   //   )
//   //     return;

//   //   // clear any error message
//   //   setErrorMessage(undefined);

//   //   const dataToUse = [...data];
//   //   let plot: ((HTMLElement | SVGSVGElement) & Plot.Plot) | null;
//   //   // Two reducers have been selected. This is impossible to render as there is no data to use. Show an error message
//   //   if (highlightXAxisReducer && highlightYAxisReducer) {
//   //     plot = null;
//   //     setErrorMessage(
//   //       'You cannot use two reducers. Please select at least one field name to use on an axis.',
//   //     );
//   //   }
//   //   // data on the X axis and a reducer on the Y axis
//   //   else if (highlightXAxisFieldName && highlightYAxisReducer) {
//   //     plot = createXDataYReducerPlot(xAxis, yAxis, dataToUse, plotType);
//   //   } // data on the Y axis and a reducer on the X axis
//   //   else if (highlightYAxisFieldName && highlightXAxisReducer) {
//   //     plot = createXReducerYDataPlot(xAxis, yAxis, dataToUse, plotType);
//   //   } // data used on both axes
//   //   else if (highlightYAxisFieldName && highlightXAxisFieldName) {
//   //     plot = createXYDataPlot(xAxis, yAxis, dataToUse, plotType);
//   //   } else {
//   //     plot = null;
//   //   }

//   //   if (
//   //     containerRef !== undefined &&
//   //     containerRef?.current != null &&
//   //     plot != null
//   //   ) {
//   //     // typescript not handling this ref well
//   //     //@ts-ignore
//   //     containerRef.current.append(plot);
//   //   }

//   //   return () => {
//   //     if (plot != null) plot.remove();
//   //   };
//   //   // eslint-disable-next-line react-hooks/exhaustive-deps
//   // }, [data, xAxis, yAxis]);

//   return <></>;

//   //   return (
//   //     <FullWidthColumnFlex>
//   //       <MenuContainer>
//   //         <ColumnFlex>
//   //           <TextField
//   //             variant="filled"
//   //             size="medium"
//   //             label="Report Name"
//   //             value={name}
//   //             placeholder="New Report"
//   //             onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
//   //               setName(event.target.value);
//   //             }}
//   //           />
//   //         </ColumnFlex>
//   //         <FlexBox>
//   //           <MarginRightButton onClick={handleCancelClick}>
//   //             Cancel
//   //           </MarginRightButton>
//   //           <Button color="success" variant="contained" onClick={handleSaveClick}>
//   //             Save
//   //           </Button>
//   //         </FlexBox>
//   //       </MenuContainer>

//   //       <TwoMarginBottomDivider />

//   //       <AxesSelectContainer>
//   //         <StyledFormControl>
//   //           <HighlightOnMatchSelectLabel
//   //             id="x-axis-field-select-label"
//   //             isHightlighted={highlightXAxisFieldName}
//   //           >
//   //             X Field Name
//   //           </HighlightOnMatchSelectLabel>
//   //           <HighlightOnMatchSelect
//   //             labelId="x-axis-field-select-label"
//   //             id="x-axis-field-select"
//   //             label="X Field Name"
//   //             value={xAxisFieldName}
//   //             isHightlighted={highlightXAxisFieldName}
//   //           >
//   //             {fieldNames.sort().map(field => (
//   //               <MenuItem
//   //                 key={field}
//   //                 value={field}
//   //                 onClick={() => {
//   //                   setXAxisFieldName(field);
//   //                   setXAxis(field);
//   //                 }}
//   //               >
//   //                 {field}
//   //               </MenuItem>
//   //             ))}
//   //           </HighlightOnMatchSelect>
//   //         </StyledFormControl>
//   //         <Typography>or</Typography>
//   //         <ReducerSelector
//   //           reducer={xAxisReducer}
//   //           setReducer={setXAxisReducer}
//   //           setAxis={setXAxis}
//   //           selectLabel="X Reducer"
//   //           matchHighlight={highlightXAxisReducer}
//   //         />

//   //         <Tooltip title="Swap X and Y Axes">
//   //           <IconButton onClick={swapAxes} sx={{ flexDirection: 'column' }}>
//   //             <Typography
//   //               variant="caption"
//   //               color={theme.palette.text.primary}
//   //               sx={{ marginBottom: -1 }}
//   //               fontWeight="bold"
//   //             >
//   //               X
//   //             </Typography>
//   //             <FaExchangeAlt color={theme.palette.text.primary} />
//   //             <Typography
//   //               variant="caption"
//   //               color={theme.palette.text.primary}
//   //               sx={{ marginTop: -0.5 }}
//   //               fontWeight="bold"
//   //             >
//   //               Y
//   //             </Typography>
//   //           </IconButton>
//   //         </Tooltip>

//   //         <StyledFormControl>
//   //           <HighlightOnMatchSelectLabel
//   //             id="y-axis-field-select-label"
//   //             isHightlighted={highlightYAxisFieldName}
//   //           >
//   //             Y Field Name
//   //           </HighlightOnMatchSelectLabel>
//   //           <HighlightOnMatchSelect
//   //             labelId="y-axis-field-select-label"
//   //             id="y-axis-field-select"
//   //             label="Y Field Name"
//   //             value={yAxisFieldName}
//   //             isHightlighted={highlightYAxisFieldName}
//   //           >
//   //             {fieldNames.sort().map(field => (
//   //               <MenuItem
//   //                 key={field}
//   //                 value={field}
//   //                 onClick={() => {
//   //                   setYAxisFieldName(field);
//   //                   setYAxis(field);
//   //                 }}
//   //               >
//   //                 {field}
//   //               </MenuItem>
//   //             ))}
//   //           </HighlightOnMatchSelect>
//   //         </StyledFormControl>
//   //         <Typography>or</Typography>
//   //         <ReducerSelector
//   //           reducer={yAxisReducer}
//   //           setReducer={setYAxisReducer}
//   //           setAxis={setYAxis}
//   //           selectLabel="Y Reducer"
//   //           matchHighlight={highlightYAxisReducer}
//   //         />
//   //         <Tooltip title="This is the Axes selector for building your chart. You can either use a Field Name OR a Reducer for each axis. Whichever one you've selected most recently will be used and highlighted.">
//   //           <IconButton>
//   //             <FaInfoCircle size={20} />
//   //           </IconButton>
//   //         </Tooltip>
//   //       </AxesSelectContainer>

//   //       {errorMessage != null && (
//   //         <WarningMessageBox>
//   //           <Typography
//   //             variant="h5"
//   //             textAlign="center"
//   //             color={theme.palette.primary.main}
//   //           >
//   //             {errorMessage}
//   //           </Typography>
//   //         </WarningMessageBox>
//   //       )}

//   //       {/* Typescript is not sure how to handle this ref currently */}
//   //       {/* @ts-ignore */}
//   //       <div ref={containerRef}></div>
//   //     </FullWidthColumnFlex>
//   //   );
// }
