import { create } from 'zustand';
import { makeGetRequest } from '../services/authenticatedRequests';
import {
  DatalakeFolder,
  DatalakeFolderResponse,
  RowData,
} from '../types/datalake';
import { Fileconfigset } from '../types/fileconfigset';

export interface FileconfigState {
  availableFileConfigList: Fileconfigset[] | undefined;
  getFileConfigIsLoading: boolean;
  getFileConfigsError: boolean;
  formattedFileConfigs: RowData[];
  formattedFileConfigFolders: DatalakeFolder[] | undefined;
  selectedFileConfigs: RowData[] | undefined; // this is what the datalake table uses to render the file configs list
  setSelectedFileConfigs: (files: RowData[] | undefined) => void;
  getAvailableFileConfigs: (clientName: string) => void;
  getAvailableFolders: (clientName: string) => void;
}

export const useFileConfigState = create<FileconfigState>((set, get) => ({
  availableFileConfigList: undefined,
  getFileConfigIsLoading: false,
  getFileConfigsError: false,
  formattedFileConfigs: [],
  formattedFileConfigFolders: undefined,
  selectedFileConfigs: undefined,

  setSelectedFileConfigs: (files: RowData[] | undefined) => {
    set({ selectedFileConfigs: files });
  },
  getAvailableFileConfigs: async (clientName: string) => {
    set({ getFileConfigIsLoading: true, getFileConfigsError: false });
    try {
      const params = {
        pk: clientName,
        sk: clientName,
      };

      console.log('========== getAvailableFolders() ==========');
      const getAvailableFoldersResponse = await makeGetRequest(
        'getallfolders',
        params,
      );
      console.log({ getAvailableFoldersResponse });
      const folderResponseParsed: DatalakeFolderResponse[] = await JSON.parse(
        getAvailableFoldersResponse.data,
      );
      let formattedFileConfigFolders: DatalakeFolder[] = [];

      // set up the folders
      folderResponseParsed.forEach(folderResponse => {
        formattedFileConfigFolders.push({
          name: folderResponse.subfolder,
          files: [],
          client: clientName,
        });
      });

      const getAvailableFileConfigsResponse = await makeGetRequest(
        'fileconfig',
        params,
      );

      console.log({ getAvailableFileConfigsResponse });
      const fileConfigResponseParsed: Fileconfigset[] = await JSON.parse(
        getAvailableFileConfigsResponse.data,
      );

      let formattedFileConfigs: RowData[] = [];
      fileConfigResponseParsed.forEach(dataset => {
        const clientName = dataset.PK.split('#')[0];
        const formattedFileConfig = {
          fileName: dataset.fileNm.replace('.csv', ''),
          client: clientName.length > 0 ? clientName : 'No Client Name',
          type: dataset.processType,
          processFlowName: dataset.processFlow,
          fileDataLocation: dataset.csvLocation,
          lastRunDate: dataset?.lastRunDate ?? '',
          lastRunStatus: dataset?.lastRunStatus ?? '',
          lastRunErrorMsg: dataset?.lastRunErrMsg ?? '',
          SK: dataset?.SK ?? '',
          folder: dataset?.subfolder,
        };

        // the file is inside a folder
        if (
          formattedFileConfig?.folder != null &&
          formattedFileConfig.folder.length > 0
        ) {
          // this is the first one so push it
          if (formattedFileConfigFolders.length === 0) {
            formattedFileConfigFolders.push({
              name: formattedFileConfig.folder,
              files: [formattedFileConfig],
              client: formattedFileConfig.client,
            });
          } else {
            // search the folders to see if the current folder name exists in the folders array
            const matchingFolderIndex = formattedFileConfigFolders.findIndex(
              folder => folder.name === formattedFileConfig.folder,
            );

            // folder was not found so push the new folder name
            if (matchingFolderIndex === -1) {
              formattedFileConfigFolders.push({
                name: formattedFileConfig.folder,
                files: [formattedFileConfig],
                client: formattedFileConfig.client,
              });
            } else {
              // folder was found so add the curernt file config to the files under that folder name
              formattedFileConfigFolders[matchingFolderIndex].files.push(
                formattedFileConfig,
              );
            }
          }
        } else {
          // file is not in a folder, so push it here
          formattedFileConfigs.push(formattedFileConfig);
        }
      });

      console.log({
        formattedFileConfigs,
        formattedFileConfigFolders,
        fileConfigResponseParsed,
        folderResponseParsed,
      });

      set({
        availableFileConfigList: fileConfigResponseParsed,
        formattedFileConfigs,
        formattedFileConfigFolders,
        selectedFileConfigs: formattedFileConfigs,
        getFileConfigIsLoading: false,
        getFileConfigsError: false,
      });
    } catch (error) {
      set({
        getFileConfigIsLoading: false,
        getFileConfigsError: true,
      });
      console.error('GetFileConfig Error: ', error);
    }
  },

  getAvailableFolders: async (clientName: string) => {
    set({ getFileConfigIsLoading: true, getFileConfigsError: false });
    try {
      const pk = clientName;
      const sk = clientName;

      console.log('========== getAvailableFolders() ==========');
      const response = await makeGetRequest('getallfolders', {
        pk: pk,
        sk: sk,
      });
      const jsonResponse = await JSON.parse(response.data);

      // let formattedFileConfigFolders: DatalakeFolder[] = [];

      // let formattedFileConfigs: RowData[] = [];
      // jsonResponse.forEach(dataset => {
      //   const clientName = dataset.PK.split('#')[0];
      //   const formattedFileConfig = {
      //     fileName: dataset.fileNm.replace('.csv', ''),
      //     client: clientName.length > 0 ? clientName : 'No Client Name',
      //     type: dataset.processType,
      //     processFlowName: dataset.processFlow,
      //     fileDataLocation: dataset.csvLocation,
      //     lastRunDate: dataset?.lastRunDate ?? '',
      //     lastRunStatus: dataset?.lastRunStatus ?? '',
      //     lastRunErrorMsg: dataset?.lastRunErrMsg ?? '',
      //     SK: dataset?.SK ?? '',
      //     folder: dataset?.subfolder,
      //   };

      //   // the file is inside a folder
      //   if (
      //     formattedFileConfig?.folder != null &&
      //     formattedFileConfig.folder.length > 0
      //   ) {
      //     // this is the first one so push it
      //     if (formattedFileConfigFolders.length === 0) {
      //       formattedFileConfigFolders.push({
      //         name: formattedFileConfig.folder,
      //         files: [formattedFileConfig],
      //         client: formattedFileConfig.client,
      //       });
      //     } else {
      //       // search the folders to see if the current folder name exists in the folders array
      //       const matchingFolderIndex = formattedFileConfigFolders.findIndex(
      //         folder => folder.name === formattedFileConfig.folder,
      //       );

      //       // folder was not found so push the new folder name
      //       if (matchingFolderIndex === -1) {
      //         formattedFileConfigFolders.push({
      //           name: formattedFileConfig.folder,
      //           files: [formattedFileConfig],
      //           client: formattedFileConfig.client,
      //         });
      //       } else {
      //         // folder was found so add the curernt file config to the files under that folder name
      //         formattedFileConfigFolders[matchingFolderIndex].files.push(
      //           formattedFileConfig,
      //         );
      //       }
      //     }
      //   } else {
      //     // file is not in a folder, so push it here
      //     formattedFileConfigs.push(formattedFileConfig);
      //   }
      // });

      console.log({
        jsonResponse,
      });

      set({
        getFileConfigIsLoading: false,
        getFileConfigsError: false,
      });
    } catch (error) {
      set({
        getFileConfigIsLoading: false,
        getFileConfigsError: true,
      });
      console.error('GetFileConfig Error: ', error);
    }
  },
}));
