import {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ReactFlow, { Background, Controls, ReactFlowInstance } from 'reactflow';
import 'reactflow/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import NodeMenuHandler from '../components/nodeMenus/nodeMenuHandler';
import NodeSidebar from '../components/nodeSidebar/nodeSidebar';
import ProcessFlowMenu from '../components/processFlowMenu/processFlowMenu';
import { useModelingState } from '../state/modelingState';
import { theme } from '../styles/theme';
import { CustomNodeTypes, NodeTypeNames, nodeTypes } from '../types/nodes';

import { Box, CircularProgress, Typography, styled } from '@mui/material';
import { format } from 'date-fns';
import { useParams } from 'react-router-dom';
import { useUserState } from '../state/userState';
import { FlexGrowCenteredColumn } from '../styles/commonStyles';
import '../styles/nodeStyles.css';
import { ProcessFlowType } from '../types/processFlows';
import { createCustomNode } from '../utils/nodeFunctions/generalNodeFunctions';

const ModelingContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'start',
  borderRight: `1px solid ${theme.palette.divider}`,
  borderLeft: `1px solid ${theme.palette.divider}`,
}) as typeof Box;

const ReactFlowContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'end',
  borderTop: `1px solid ${theme.palette.divider}`,
}) as typeof Box;

const defaultViewport = { x: 0, y: 0, zoom: 1.5 };

export default function ModelingPage() {
  const {
    nodes,
    edges,
    selectedNode,
    selectedProcessFlow,
    availableProcessFlows,
    getAvailableProcessFlowsIsLoading,
    getAvailableProcessFlowsError,
    setNodes,
    setEdges,
    setSelectedProcessFlow,
    setAvailableProcessFlows,
    onNodesChange,
    onEdgesChange,
    onConnect,
    onEdgesDelete,
    setSelectedNode,
    isValidConnection,
    getAvailableProcessFlows,
  } = useModelingState();
  const { userProfile, selectedClient } = useUserState();
  const { processFlowId } = useParams();

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance | null>(null);

  // tracks whether or not we've tried to call the getAvailableProcessFlows API
  const [
    getAvailableProcessFlowsAttempted,
    setGetAvailableProcessFlowsAttempted,
  ] = useState(false);

  const updateModelingPageState = useCallback((): number => {
    if (getAvailableProcessFlowsIsLoading) {
      setGetAvailableProcessFlowsAttempted(true);
    }
    if (getAvailableProcessFlowsError && !getAvailableProcessFlowsIsLoading) {
      return -1;
    }

    if (getAvailableProcessFlowsIsLoading || availableProcessFlows == null) {
      return 0;
    }

    if (availableProcessFlows != null) {
      return availableProcessFlows.length > 0 ? 1 : 2;
    }

    return -1;
  }, [
    availableProcessFlows,
    getAvailableProcessFlowsIsLoading,
    getAvailableProcessFlowsError,
  ]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const modelingPageState = useMemo(
    () => updateModelingPageState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateModelingPageState,
      availableProcessFlows,
      getAvailableProcessFlowsIsLoading,
      getAvailableProcessFlowsError,
    ],
  );

  const createNewProcessFlow = useCallback(
    (idFromParams: string | undefined, clientName: string) => {
      const id = idFromParams ?? uuidv4();

      const newProcessFlow: ProcessFlowType = {
        PK: `${clientName}#PROCESSFLOW`,
        SK: `${clientName}#PFNAME#`,
        creationDate: format(new Date(), 'yyyy-MM-dd'),
        createdBy: userProfile?.username ?? 'defaultUser',
        nodes: [],
        edges: [],
        objId: id,
        name: '',
        lastRunDate: '',
        lastRunErrorMsg: '',
        lastRunStatus: '',
      };

      setSelectedProcessFlow(newProcessFlow);
      setAvailableProcessFlows(
        availableProcessFlows != null
          ? [...availableProcessFlows].concat(newProcessFlow)
          : [newProcessFlow],
      );
      setNodes([]);
      setEdges([]);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableProcessFlows],
  );

  const getProcessFlowFromParams = useCallback(
    async (idFromParams: string | undefined) => {
      // if we do not have a process flow selected (likely from navigating directly to this url)
      // OR, if we the desired process flow id (from url params) does not match the current process flow selected in state
      // we need to update the process flow in state
      if (
        selectedProcessFlow == null ||
        selectedProcessFlow?.objId !== idFromParams
      ) {
        // there are no available process flows currently so we need to create a new one based on the id
        if (
          availableProcessFlows == null ||
          availableProcessFlows.length === 0
        ) {
          if (selectedClient != null) {
            createNewProcessFlow(
              idFromParams,
              selectedClient.clientNameFormattedDynamo,
            );
          }
        } else {
          // available process flows exists and has some stored process flows
          // so we search for one matching our id
          const processFlowIndex = availableProcessFlows.findIndex(
            processFlow => processFlow.objId === idFromParams,
          );

          // processFlow was not found in the available process flows
          if (processFlowIndex === -1 && selectedClient != null) {
            createNewProcessFlow(
              idFromParams,
              selectedClient.clientNameFormattedDynamo,
            );
          } else {
            const foundProcessFlow = availableProcessFlows[processFlowIndex];
            setSelectedProcessFlow(foundProcessFlow);
            setNodes(
              foundProcessFlow?.nodes != null
                ? [...foundProcessFlow.nodes]
                : [],
            );
            setEdges(
              foundProcessFlow?.edges != null
                ? [...foundProcessFlow.edges]
                : [],
            );
          }
        }
      } else if (selectedProcessFlow != null) {
        setNodes(
          selectedProcessFlow?.nodes != null
            ? [...selectedProcessFlow.nodes]
            : [],
        );
        setEdges(
          selectedProcessFlow?.edges != null
            ? [...selectedProcessFlow.edges]
            : [],
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [availableProcessFlows, selectedProcessFlow, selectedClient],
  );

  useEffect(() => {
    if (!getAvailableProcessFlowsIsLoading && selectedClient != null) {
      getAvailableProcessFlows(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient]);

  useEffect(() => {
    // only run this once the modeling page has valid data AND the getAvailableProcessFlows API has been called
    if (getAvailableProcessFlowsAttempted && modelingPageState >= 1) {
      getProcessFlowFromParams(processFlowId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processFlowId, getAvailableProcessFlowsAttempted, modelingPageState]);

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  // TODO: Small bug with the the drop positioning not matching up. Usually places the object to the right
  // handles when a user drops a new node into the space
  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();

      if (
        reactFlowWrapper != null &&
        reactFlowInstance != null &&
        selectedClient != null
      ) {
        const reactFlowBounds =
          //@ts-ignore
          reactFlowWrapper.current.getBoundingClientRect();
        const type = event.dataTransfer.getData('application/reactflow');

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });

        const newNode = createCustomNode(
          type as NodeTypeNames,
          position,
          selectedClient.clientNameFormattedDynamo,
          selectedProcessFlow != null ? selectedProcessFlow.name : '',
        );

        //TODO: add in a case here for when this is undefined. Should never happen though
        if (newNode != null) {
          const currentNodes = [...nodes];
          const newNodes = currentNodes.concat(newNode);

          setNodes(newNodes);
        }
      }
    },
    [reactFlowInstance, nodes, setNodes, selectedProcessFlow, selectedClient],
  );

  const handleNodeClick = useCallback(
    (node: CustomNodeTypes) => {
      if (selectedNode != null) {
        if (node.id === selectedNode.id) {
          //close node menu because we clicked the same node
          setSelectedNode(undefined);
        } else {
          // update the selected node menu because a new node was clicked
          setSelectedNode(node);
        }
      } else {
        // open node menu because no node was selected
        setSelectedNode(node);
      }
    },
    [setSelectedNode, selectedNode],
  );

  if (modelingPageState === 0) {
    return (
      <FlexGrowCenteredColumn>
        <CircularProgress
          color="primary"
          size="6rem"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <Typography variant="h4" color="primary" fontWeight={500}>
          Modeling is Loading
        </Typography>
      </FlexGrowCenteredColumn>
    );
  }

  return (
    <ModelingContainer>
      <ProcessFlowMenu />
      <ReactFlowContainer className="reactflow-wrapper" ref={reactFlowWrapper}>
        <NodeSidebar />
        {/* TODO: fix the zoom to account for text names of the nodes */}
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onEdgesDelete={onEdgesDelete}
          isValidConnection={isValidConnection}
          defaultViewport={defaultViewport}
          nodeTypes={nodeTypes}
          onInit={setReactFlowInstance}
          onDrop={onDrop}
          onDragOver={onDragOver}
          // this is a bug with React Flow
          //@ts-ignore
          onNodeClick={(_event: MouseEvent, node: CustomNodeTypes) => {
            handleNodeClick(node);
          }}
          minZoom={0.2}
          maxZoom={4}
          fitView
        >
          <Controls />
          <Background
            style={{ backgroundColor: theme.palette.background.default }}
          />
        </ReactFlow>
      </ReactFlowContainer>
      <NodeMenuHandler />
    </ModelingContainer>
  );
}
