import { Box, styled } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useReportsState } from '../../state/reportsState';
import {
  BreadcrumbsSkeletonContainer,
  StyledSkeleton,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import WarningMessage from '../common/warningMessage';
import BreadCrumbNavigator from '../Navigation/breadcrumbNav';
import ReportsHeader from '../reports/header';
import { AxesSelectContainer } from './plotAxesSelector';

const PlotPageBodyContainer = styled(Box)({
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
}) as typeof Box;

const DropdownSkeleton = styled(StyledSkeleton)({
  minWidth: '9.375rem',
});

export default function PlotPageBody() {
  const { selectedFileName, selectedData, selectedReport } = useReportsState();

  const updatePlotPageState = useCallback((): number => {
    if (selectedReport != null && selectedReport.type === 'dataGrid') {
      // this should never happen but if it does, the report selected is somehow a datagrid and they got navigated here so flash and error
      return -1;
    }
    if (
      selectedData == null ||
      selectedReport == null ||
      selectedFileName == null
    ) {
      return 0;
    }

    if (selectedData != null) {
      // clean up the nav state once the page loads
      window.history.replaceState({}, '');
      return selectedData.length > 0 ? 1 : 2;
    }

    return -1;
  }, [selectedFileName, selectedData, selectedReport]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const plotPageState = useMemo(
    () => updatePlotPageState(),
    [updatePlotPageState],
  );

  // Loading
  if (plotPageState === 0) {
    return (
      <PlotPageBodyContainer>
        <BreadcrumbsSkeletonContainer>
          <StyledSkeleton
            variant="rectangular"
            height="1rem"
            animation="wave"
            width="6rem"
          />
          <StyledSkeleton
            variant="rectangular"
            height="1rem"
            animation="wave"
            width="5rem"
          />
          <StyledSkeleton
            variant="rectangular"
            height="1rem"
            animation="wave"
            width="4rem"
          />
        </BreadcrumbsSkeletonContainer>
        <StyledSkeleton
          variant="rectangular"
          animation="wave"
          height="3rem"
          width="40vw"
          sx={{ marginBottom: theme.spacing(2) }}
        />
        <AxesSelectContainer>
          <DropdownSkeleton
            variant="rectangular"
            animation="wave"
            height="3.5rem"
          />
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1.5rem"
            width="0.9rem"
          />
          <DropdownSkeleton
            variant="rectangular"
            animation="wave"
            height="3.5rem"
          />
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="3.5rem"
            width="2.5rem"
          />
          <DropdownSkeleton
            variant="rectangular"
            animation="wave"
            height="3.5rem"
          />
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1.5rem"
            width="0.9rem"
          />
          <DropdownSkeleton
            variant="rectangular"
            animation="wave"
            height="3.5rem"
          />
          <StyledSkeleton
            variant="rectangular"
            animation="wave"
            height="1.5rem"
            width="0.9rem"
          />
        </AxesSelectContainer>
      </PlotPageBodyContainer>
    );
  }

  // Good Data
  if (
    selectedData != null &&
    selectedReport != null &&
    selectedFileName != null &&
    selectedReport?.type !== 'dataGrid' &&
    plotPageState === 1
  ) {
    return (
      <PlotPageBodyContainer>
        <BreadCrumbNavigator
          breadcrumbs={[
            { text: 'Datalake', href: '/datalake' },
            { text: 'Reports', href: `/datalake/${selectedFileName}/reports` },
            { text: 'Plot', href: '' },
          ]}
        />
        <ReportsHeader
          fileName={selectedFileName ?? 'File Name Not Found'}
          showAddButton={false}
        />
        {/* <PlotAxesSelector
          chartData={selectedData}
          plotType={selectedReport?.type}
        /> */}
      </PlotPageBodyContainer>
    );
  }

  // Data is valid but empty
  if (plotPageState === 2) {
    return (
      <WarningMessageBox>
        <WarningMessage>
          There was no data found for this dataset.
        </WarningMessage>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <WarningMessage>
        There was an error getting your data. Please go navigate back and
        refresh.
      </WarningMessage>
    </WarningMessageBox>
  );
}
