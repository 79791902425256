import {
  CustomNodeTypes,
  FieldRename,
  InputDatasetNodeType,
  KnowledgeGraphNodeType,
  MultiCovariantAnalysisNodeType,
  Neo4jNodeFormat,
} from '../../types/nodes';
import { v4 as uuidv4 } from 'uuid';

// ====================== KNOWLEDGE GRAPH AS TARGET ======================

export const disconnectNodeFromKnowledgeGraph = (
  sourceNode: CustomNodeTypes,
  targetNode: KnowledgeGraphNodeType,
): [CustomNodeTypes, KnowledgeGraphNodeType] => {
  let updatedTargetNode: KnowledgeGraphNodeType = targetNode;
  let updatedDatabaseNames = [...targetNode.data.inputDatasetNames];
  let updatedDatasetNodes = [...targetNode.data.inputDatasetNodes];
  let updatedProcessId = targetNode.data.processId;
  let updatedOutputNodes =
    targetNode.data?.outputNodes != null
      ? [...targetNode.data.outputNodes]
      : [];
  let updatedOutputRelationships =
    targetNode.data?.outputRelationships != null
      ? [...targetNode.data.outputRelationships]
      : [];
  let outputNodeToDelete: undefined | Neo4jNodeFormat;

  // need to handle if the knowledge graph has multiple inputs, so find the deleted input indexes
  const sourceNodeDatabaseNameIndex =
    targetNode.data.inputDatasetNames.findIndex(
      name => name === sourceNode.data?.outputDatasetName,
    );

  const sourceNodeIndex = targetNode.data.inputDatasetNodes.findIndex(
    datasetNode => datasetNode.data.objId === sourceNode.data.objId,
  );

  const sourceNodeTiedToOutputNodeIndex = updatedOutputNodes.findIndex(
    outputNode => sourceNode.data.objId === outputNode.inputObjId,
  );

  // remove the inputs from the input list
  if (sourceNodeDatabaseNameIndex > -1) {
    updatedDatabaseNames.splice(sourceNodeDatabaseNameIndex, 1);
  }
  if (sourceNodeIndex > -1) {
    updatedDatasetNodes.splice(sourceNodeIndex, 1);
  }
  if (sourceNodeTiedToOutputNodeIndex > -1) {
    outputNodeToDelete = updatedOutputNodes[sourceNodeTiedToOutputNodeIndex];
    updatedOutputNodes.splice(sourceNodeTiedToOutputNodeIndex, 1);
  }

  //delete any relationships associated with the node that is being removed
  if (outputNodeToDelete != null) {
    let indexesToDelete: number[] = [];

    for (let i = 0; i < updatedOutputRelationships.length; i++) {
      const currentRelationship = updatedOutputRelationships[i];

      if (
        currentRelationship.sourceNodeName === outputNodeToDelete.nodeName ||
        currentRelationship.targetNodeName === outputNodeToDelete.nodeName
      ) {
        indexesToDelete.push(i);
      }
    }

    indexesToDelete.forEach(indexToDelete =>
      updatedOutputRelationships.splice(indexToDelete, 1),
    );
  }

  // if these arrays both no longer have any items, then this node has no more inputs
  // so we need to reset the process id to -1
  if (updatedDatabaseNames.length === 0 && updatedDatasetNodes.length === 0) {
    updatedProcessId = -1;
  }

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: updatedProcessId,
    inputDatasetNames: [...updatedDatabaseNames],
    inputDatasetNodes: [...updatedDatasetNodes],
    outputNodes: [...updatedOutputNodes],
    outputRelationships: [...updatedOutputRelationships],
    fields: updatedDatasetNodes.flatMap(
      datasetNode => datasetNode?.data?.fields ?? [],
    ),
  };

  return [sourceNode, updatedTargetNode];
};

export const updateKnowledgeGraphWithInputNodeData = (
  sourceNode: InputDatasetNodeType,
  targetNode: KnowledgeGraphNodeType,
): KnowledgeGraphNodeType => {
  let updatedTargetNode: KnowledgeGraphNodeType = targetNode;
  const inputDatasetNames = [...targetNode.data.inputDatasetNames].concat(
    sourceNode.data.nodeName,
  );

  const inputDatasetNodes: InputDatasetNodeType[] = [
    ...targetNode.data.inputDatasetNodes,
  ].concat({ ...sourceNode });

  const targetFields = targetNode.data?.fields ?? [];
  const sourceFields = sourceNode.data?.fields ?? [];
  const combinedFields = targetFields.concat(sourceFields);

  let renamedFields: FieldRename[] = [];

  sourceFields.forEach(field =>
    renamedFields.push({ originalName: field, newName: '' }),
  );

  const newNeo4jNode: Neo4jNodeFormat = {
    id: uuidv4(),
    inputObjId: sourceNode.data.objId,
    inputName: sourceNode.data.nodeName,
    fields: sourceFields,
    renamedFields,
    nodeName: '',
  };

  const outputNodes =
    targetNode.data?.outputNodes != null
      ? [...targetNode.data.outputNodes].concat({
          ...newNeo4jNode,
        })
      : [];

  updatedTargetNode.data = {
    ...targetNode.data,
    processId: sourceNode.data.processId + 1,
    //TODO: add error handling to check inputs?
    inputDatasetNames,
    inputDatasetNodes,
    outputNodes,
    fields: combinedFields,
    objId: targetNode.data.objId,
  };

  return updatedTargetNode;
};

// ====================== KNOWLEDGE GRAPH AS SOURCE ======================

export const connectKnowledgeGraphToMultiCovariantAnalysis = (
  sourceNode: KnowledgeGraphNodeType,
  targetNode: MultiCovariantAnalysisNodeType,
): [KnowledgeGraphNodeType, MultiCovariantAnalysisNodeType] => {
  let updatedTargetNode: MultiCovariantAnalysisNodeType = targetNode;

  updatedTargetNode.data = {
    ...updatedTargetNode.data,
    processId: sourceNode.data.processId + 1,
    inputDatasetName: sourceNode.data.outputDatasetName,
    inputObjId: sourceNode.data.objId,
    fields: sourceNode.data.fields,
  };

  return [sourceNode, updatedTargetNode];
};

// Leaving these connection functions for now

// export const connectKnowledgeGraphToOutputDataset = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: OutputDatasetNodeType,
// ): [KnowledgeGraphNodeType, OutputDatasetNodeType] => {
//   let updatedTargetNode: OutputDatasetNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data.outputDatasetName,
//     inputObjId: sourceNode.data.objId,
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToSelectCategories = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: SelectCategoriesNodeType,
// ): [KnowledgeGraphNodeType, SelectCategoriesNodeType] => {
//   let updatedTargetNode: SelectCategoriesNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...targetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputName: sourceNode.data.outputDatasetName,
//     inputDatasetName: sourceNode.data.outputDatasetName,
//     inputObjId: sourceNode.data.objId,
//     selectedCategories: undefined,
//     fields: sourceNode.data.primaryFields ?? [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToAddConstant = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: AddConstantNodeType,
// ): [KnowledgeGraphNodeType, AddConstantNodeType] => {
//   let updatedTargetNode: AddConstantNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     outputColumnNames: sourceNode.data?.primaryFields ?? [],
//     columnsToAdd: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToFunctionNode = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: FunctionNodeType,
// ): [KnowledgeGraphNodeType, FunctionNodeType] => {
//   let updatedTargetNode: FunctionNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     functions: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToAlterColumns = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: AlterColumnsNodeType,
// ): [KnowledgeGraphNodeType, AlterColumnsNodeType] => {
//   let updatedTargetNode: AlterColumnsNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     outputFields: [],
//     alterColumns: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };

// export const connectKnowledgeGraphToFilter = (
//   sourceNode: KnowledgeGraphNodeType,
//   targetNode: FilterNodeType,
// ): [KnowledgeGraphNodeType, FilterNodeType] => {
//   let updatedTargetNode: FilterNodeType = targetNode;

//   updatedTargetNode.data = {
//     ...updatedTargetNode.data,
//     processId: sourceNode.data.processId + 1,
//     inputDatasetName: sourceNode.data?.outputDatasetName ?? 'bad dataset',
//     inputObjId: sourceNode.data.objId,
//     fields: sourceNode.data?.primaryFields ?? [],
//     filterRules: [],
//   };

//   return [sourceNode, updatedTargetNode];
// };
