import {
  BuildInPlotlyRenderersNames,
  TQuerySort,
  TableRenderers,
  // TODO: track fix in RKEP-50
  //@ts-ignore
} from 'react-pivottable';
import { PlotTypes } from './plots';
import {
  QueryParamConfig,
  decodeArray,
  encodeArray,
  encodeString,
} from 'use-query-params';

export interface DepreciatedReport {
  name: string;
  id: string;
  csvKey: string; // key that links the report to its associated dataset
  xAxis: string; // field name or reducer
  yAxis: string;
  xAxisReducer: boolean; // does the x axis use a reducer?
  yAxisReducer: boolean; // does the y axis use a reducer?
  type: PlotTypes | 'dataGrid'; // bar | dot | line | stackedBar etc.
  client: string; // client name (All Caps) associated with the report (same as the PK for API calls)
  fileSK: string; // SK from the file config data associated with the report
  PK: string; // ex: {uppercaseClientName}#REPORT
  SK: string; // ex: {fileSK}#{id}
}

export interface Report {
  name: string;
  id: string;
  csvKey: string; // key that links the report to its associated dataset
  client: string; // client name (All Caps) associated with the report (same as the PK for API calls)
  fileSK: string; // SK from the file config data associated with the report
  PK: string; // ex: {uppercaseClientName}#REPORT
  SK: string; // ex: {fileSK}#{id}
  rows: string[];
  cols: string[];
  rowOrder: TQuerySort;
  colOrder: TQuerySort;
  rendererName: string;
  aggregatorName: string;
  type: 'pivotTable' | 'dataGrid';
}

export interface FileInfoForS3 {
  fileSK: string; // SK from the file config data
  fileData: string;
  fileClient: string; // client name associated with the file data
}

export interface ReportsResponseData {
  PK: string;
  SK: string;
  ReportData: {
    S: string; //JSON string of report data
  };
}

export interface GetAllReportsResponse {
  config: any;
  headers: any;
  request: any;
  status: number;
  statusText: string;
  data: ReportsResponseData[] | null;
}

export interface PivotTableState {
  aggregatorName: string;
  colOrder: string;
  cols: string[];
  data: any[];
  menuLimit: number;
  rendererName: TableRenderers | BuildInPlotlyRenderersNames;
  rowOrder: string;
  rows: string[];
}

// ========== Query Param Utils ==========

export const setUpPivotTableQueryParams = (report: Report): string => {
  const { name, rows, cols, rendererName, aggregatorName, colOrder, rowOrder } =
    report;

  // array query param format example: Array=['Classification', 'Chromosome'] encoded query param =  'rows=Classification&rows=Chromosome'
  let rowsEncoded = '';
  rows.forEach(rowName => (rowsEncoded += `&rows=${rowName}`));
  let colsEncoded = '';
  cols.forEach(rowName => (colsEncoded += `&cols=${rowName}`));

  return `?name=${name}&rendererName=${rendererName}&aggregatorName=${aggregatorName}${rowsEncoded}${colsEncoded}&rowOrder=${rowOrder}&colOrder=${colOrder}`;
};

export const defaultSortOrder: TQuerySort = 'key_a_to_z';
export const defaultRendererName = 'Table';
export const defaultAggregatorName = 'Count';
export const defaultArray: string[] = [];

// We have these custom decoder functions because the user-query-param library doesn't know how to handle default, null, undefined, or unexpected values so handle them here

// custom function telling use-query-params how to decode the sort order param so typescript is happy
export function decodeSortOrderQueryParam(
  sortOrder: string | (string | null)[] | null | undefined,
): TQuerySort {
  if (sortOrder == null) return defaultSortOrder;
  if (typeof sortOrder === typeof ['']) return defaultSortOrder;
  if (sortOrder === 'key_a_to_z') return sortOrder;
  if (sortOrder === 'key_z_to_a') return sortOrder;
  if (sortOrder === 'value_a_to_z') return sortOrder;
  if (sortOrder === 'value_z_to_a') return sortOrder;

  return defaultSortOrder;
}

// custom function telling use-query-params how to decode the renderer param so typescript is happy
export function decodeRendererQueryParam(
  renderer: string | (string | null)[] | null | undefined,
): string {
  if (renderer == null) return defaultRendererName;
  if (typeof renderer === typeof ['']) return defaultRendererName;
  if (typeof renderer === typeof [null]) return defaultRendererName;
  if (typeof renderer === typeof '') return renderer as string;

  return defaultRendererName;
}

// custom function telling use-query-params how to decode the aggregator param so typescript is happy
export function decodeAggregatorQueryParam(
  aggregator: string | (string | null)[] | null | undefined,
): string {
  if (aggregator == null) return defaultAggregatorName;
  if (typeof aggregator === typeof ['']) return defaultAggregatorName;
  if (typeof aggregator === typeof [null]) return defaultAggregatorName;
  if (typeof aggregator === typeof '') return aggregator as string;

  return defaultAggregatorName;
}

// custom function telling use-query-params how to decode the pivot table rows and cols array params so typescript is happy
export function decodePivotTableArrayParam(
  array: string | (string | null)[] | null | undefined,
): string[] {
  const decodedArray = decodeArray(array);

  if (decodedArray == null) return defaultArray;
  if (typeof decodedArray === typeof ['one', 'two'])
    return decodedArray as string[];

  return defaultArray;
}

export const SortOrderParam: QueryParamConfig<TQuerySort | undefined> = {
  encode: (sortOrder: TQuerySort | null | undefined) => encodeString(sortOrder),
  decode: (sortOrder: string | (string | null)[] | null | undefined) =>
    decodeSortOrderQueryParam(sortOrder),
};

export const RendererParam: QueryParamConfig<string | undefined> = {
  encode: (rendererName: string | (string | null)[] | null | undefined) =>
    encodeString(rendererName),
  decode: (rendererName: string | (string | null)[] | null | undefined) =>
    decodeRendererQueryParam(rendererName),
};

export const AggregatorParam: QueryParamConfig<string | undefined> = {
  encode: (aggregatorName: string | (string | null)[] | null | undefined) =>
    encodeString(aggregatorName),
  decode: (aggregatorName: string | (string | null)[] | null | undefined) =>
    decodeAggregatorQueryParam(aggregatorName),
};

export const PivotTableArrayParam: QueryParamConfig<
  string[] | undefined | null
> = {
  encode: (array: (string | null)[] | null | undefined) => encodeArray(array),
  decode: (array: string | (string | null)[] | null | undefined) =>
    decodePivotTableArrayParam(array),
};
