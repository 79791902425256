import {
  Box,
  Table,
  TableContainer,
  TablePagination,
  styled,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { useFileConfigState } from '../../state/fileconfigState';
import { useUserState } from '../../state/userState';
import {
  FullWidthContainer,
  StyledSkeleton,
  WarningMessageBox,
} from '../../styles/commonStyles';
import { theme } from '../../styles/theme';
import {
  DatalakeColumn,
  DatalakeFolder,
  OrderByDatalakeParam,
  RowData,
  RowsPerPageDatalakeParam,
  ShowProcessFlowsParam,
  ShowUploadsParam,
} from '../../types/datalake';
import {
  OrderParam,
  PageNumParam,
  defaultPageNum,
  getComparator,
  stableSort,
} from '../../types/tables';
import WarningMessage from '../common/warningMessage';
import DatalakeModals from './modals';
import DatalakeTableBody from './tableBody';
import DatalakeTableHeader from './tableHeader';

const SkeletonLoadersContainer = styled(Box)({
  display: 'grid',
  rowGap: theme.spacing(1),
}) as typeof Box;

const columns: readonly DatalakeColumn[] = [
  { id: 'type', label: 'Origin', minWidth: '8dvw' },
  { id: 'client', label: 'Client', minWidth: '7dvw' },
  {
    id: 'fileName',
    label: 'File Name (click to view data)',
    align: 'left',
    minWidth: '38dvw',
  },
  {
    id: 'lastRunDate',
    label: 'Last Ran',
    minWidth: '8dvw',
  },
  {
    id: 'lastRunStatus',
    label: 'Status',
    minWidth: '7dvw',
  },
  {
    id: 'action',
    label: 'Actions',
    align: 'right',
    minWidth: '10dvw',
  },
];

const getFolderFromName = (
  folderName: string,
  folders: DatalakeFolder[],
): DatalakeFolder | undefined => {
  const folderIndex = folders.findIndex(folder => folder.name === folderName);

  if (folderIndex >= 0) {
    return folders[folderIndex];
  }

  return undefined;
};

export default function DatalakeTable() {
  const [fileToDelete, setFileToDelete] = useState<RowData | undefined>(
    undefined,
  );
  const [processFlowToRun, setProcessFlowToRun] = useState<RowData | undefined>(
    undefined,
  );
  const [selectedErrorMsg, setSelectedErrorMsg] = useState<string | undefined>(
    undefined,
  );

  // query param variables
  const [page, setPage] = useQueryParam('pageNum', PageNumParam);
  const [rowsPerPage, setRowsPerPage] = useQueryParam(
    'rowsPerPage',
    RowsPerPageDatalakeParam,
  );
  // these values are handled in the <DatalakeTableHeader> component but we read them here too
  const [order] = useQueryParam('order', OrderParam);
  const [orderBy] = useQueryParam('orderBy', OrderByDatalakeParam);

  // these booleans are handled in the <DatalakeHeader> component but we read them here too
  const [showProcessFlows] = useQueryParam(
    'showProcessFlows',
    ShowProcessFlowsParam,
  );
  const [showUploads] = useQueryParam('showUploads', ShowUploadsParam);
  const [selectedFolder, setSelectedFolder] = useQueryParam(
    'selectedFolder',
    StringParam,
  );

  const {
    formattedFileConfigs,
    getFileConfigIsLoading,
    getFileConfigsError,
    selectedFileConfigs,
    formattedFileConfigFolders,
    getAvailableFileConfigs,
    setSelectedFileConfigs,
  } = useFileConfigState();
  const { selectedClient } = useUserState();

  const rows: RowData[] = useMemo(
    () => selectedFileConfigs ?? [],
    [selectedFileConfigs],
  );

  const filteredRows: RowData[] = useMemo(
    () =>
      rows.filter(row => {
        if (showProcessFlows && showUploads) return true;

        if (showProcessFlows && !showUploads) return row.type === 'processFlow';

        if (!showProcessFlows && showUploads) return row.type === 'upload';

        if (!showProcessFlows && !showUploads) return false;

        return true;
      }),
    [rows, showProcessFlows, showUploads],
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = useMemo(
    () =>
      stableSort(filteredRows, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, filteredRows, rowsPerPage],
  );

  // grabs available files on load
  useEffect(() => {
    if (selectedClient != null && !getFileConfigIsLoading) {
      getAvailableFileConfigs(selectedClient.clientNameFormattedDynamo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAvailableFileConfigs, selectedClient]);

  // handles when the selected folder changes, if it's null then we use the standard file configs not in a folder
  useEffect(() => {
    if (selectedFolder == null) {
      setSelectedFileConfigs(formattedFileConfigs);
    }
    if (
      selectedFolder != null &&
      formattedFileConfigFolders != null &&
      selectedClient != null
    ) {
      const folder = getFolderFromName(
        selectedFolder,
        formattedFileConfigFolders,
      );

      if (folder != null) {
        // the client name and selected folder name could differ after a refresh
        // if they're the same, we're all good
        if (
          selectedClient.clientName.toUpperCase() ===
          folder.client.toUpperCase()
        ) {
          setSelectedFileConfigs(folder?.files);
        } else {
          //if the selected folder and client name are different, we need to clear the state
          setSelectedFileConfigs(undefined);
          setSelectedFolder(null);
        }
      } else {
        setSelectedFileConfigs(undefined);
        setSelectedFolder(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFolder, formattedFileConfigFolders, selectedClient]);

  const updateTableDataState = useCallback((): number => {
    if (getFileConfigsError && !getFileConfigIsLoading) {
      return -1;
    }

    if (getFileConfigIsLoading || formattedFileConfigs == null) {
      return 0;
    }

    if (formattedFileConfigs != null) {
      return formattedFileConfigs.length > 0 ? 1 : 2;
    }

    return -1;
  }, [formattedFileConfigs, getFileConfigIsLoading, getFileConfigsError]);

  // -1 = There was an Error, 0 = loading, 1 = good data, 2 = good data but empty
  const tableDataState = useMemo(
    () => updateTableDataState(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      updateTableDataState,
      formattedFileConfigs,
      getFileConfigIsLoading,
      getFileConfigsError,
    ],
  );

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(defaultPageNum);
  };

  // handles if the filters update in such a way that the new length of available pages exceeds the current page number, reset the page number to its default
  useEffect(() => {
    if (filteredRows.length / rowsPerPage > page + 1) {
      setPage(defaultPageNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRows, rowsPerPage]);

  const skeletonLoaders = Array.from({ length: rowsPerPage }, (_, index) => (
    <StyledSkeleton
      key={index}
      variant="rectangular"
      animation="wave"
      height="4rem"
    />
  ));

  // Loading
  if (tableDataState === 0) {
    return (
      <SkeletonLoadersContainer>{skeletonLoaders}</SkeletonLoadersContainer>
    );
  }
  // Data is valid and there is data
  if (visibleRows && tableDataState === 1) {
    return (
      <FullWidthContainer>
        <TableContainer sx={{ width: '100%' }}>
          <Table stickyHeader aria-label="RKE Datalake Storage Table">
            <DatalakeTableHeader columns={columns} />
            <DatalakeTableBody
              columns={columns}
              visibleRowsProp={visibleRows}
              setFileToDelete={setFileToDelete}
              setProcessFlowToRun={setProcessFlowToRun}
              setSelectedErrorMsg={setSelectedErrorMsg}
            />
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 15, 25, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />

        <DatalakeModals
          fileToDeleteProp={fileToDelete}
          processFlowToRunProp={processFlowToRun}
          selectedErrorMsgProp={selectedErrorMsg}
          setFileToDelete={setFileToDelete}
          setProcessFlowToRun={setProcessFlowToRun}
          setSelectedErrorMsg={setSelectedErrorMsg}
        />
      </FullWidthContainer>
    );
  }

  // Data is valid but empty
  if (visibleRows && tableDataState === 2) {
    return (
      <WarningMessageBox>
        <WarningMessage>
          There were no saved Process Flows or Uploads found.
        </WarningMessage>
      </WarningMessageBox>
    );
  }

  // Error with the data
  return (
    <WarningMessageBox>
      <WarningMessage>
        There was an error getting your data. Refresh to try again.
      </WarningMessage>
    </WarningMessageBox>
  );
}
