import { Box, styled } from '@mui/material';
import { useState } from 'react';
// TODO: track fix in RKEP-50
// @ts-ignore
import PivotTableUI, { PivotTableUIProps } from 'react-pivottable/PivotTableUI';
// @ts-ignore
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
// @ts-ignore
import TableRenderers from 'react-pivottable/TableRenderers';
import './styles.css';
import Plot from 'react-plotly.js';
import { useQueryParam } from 'use-query-params';
import { useReportsState } from '../../state/reportsState';
import {
  AggregatorParam,
  PivotTableArrayParam,
  RendererParam,
  SortOrderParam,
} from '../../types/reports';
import { StyledSkeleton } from '../../styles/commonStyles';
import { theme, themeColorsStrings } from '../../styles/theme';
import { chartColors } from '../../styles/colors';

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const PivotTableContainer = styled(Box)({
  width: '100%',
  overflowX: 'scroll',
});

// Pivot Table Color Variables
// We use these to inject our theme into the pivot table styles since it uses exclusively raw CSS

// --table-text-color = text color for the text inside the table
// --icon-color = color of the icons on the chips, dropdowns, filters, etc.
// --background-color = background color for the various table containers
// --surface-color = background color for the inputs and table cells
// --border-color
// --selected-value-color = background color for any selected value
// --selected-text-color = text color for any selected value
// --button-color
// --button-text-color
// --button-hover-color
// --button-active-color
// --input-border-color
// --input-focus-border-color

const dynamicStyles = `
:root {
  --table-text-color: ${theme.palette.text.primary};
  --icon-color: ${theme.palette.text.primary};
  --background-color: ${theme.palette.background.default};
  --surface-color: ${theme.palette.surface.default};
  --border-color: ${theme.palette.text.disabled};

  --selected-value-color: ${theme.palette.primary.light};
  --selected-text-color: ${theme.palette.primary.contrastText};

  --button-color: ${theme.palette.secondary.light};
  --button-text-color: ${theme.palette.secondary.contrastText};
  --button-hover-color: ${theme.palette.secondary.main};
  --button-active-color: ${theme.palette.secondary.dark};

  --input-border-color: ${theme.palette.text.disabled};
  --input-focus-border-color: ${theme.palette.primary.main};
}
`;

export default function PivotTable() {
  const [tableState, setTableState] = useState({});
  const { selectedData } = useReportsState();

  const [aggregatorName, setAggregatorName] = useQueryParam(
    'aggregatorName',
    AggregatorParam,
  );
  const [rendererName, setRendererName] = useQueryParam(
    'rendererName',
    RendererParam,
  );
  const [rows, setRows] = useQueryParam('rows', PivotTableArrayParam);
  const [cols, setCols] = useQueryParam('cols', PivotTableArrayParam);
  const [colOrder, setColOrder] = useQueryParam('colOrder', SortOrderParam);
  const [rowOrder, setRowOrder] = useQueryParam('rowOrder', SortOrderParam);

  if (selectedData != null) {
    return (
      <PivotTableContainer>
        <style>{dynamicStyles}</style>
        <PivotTableUI
          data={selectedData}
          onChange={(s: PivotTableUIProps) => {
            setRendererName(s?.rendererName);
            setAggregatorName(s?.aggregatorName);
            setRows(s?.rows);
            setCols(s?.cols);
            setRowOrder(s?.rowOrder);
            setColOrder(s?.colOrder);
            setTableState(s);
          }}
          rendererName={rendererName}
          rows={rows ?? []}
          cols={cols ?? []}
          rowOrder={rowOrder}
          colOrder={colOrder}
          aggregatorName={aggregatorName}
          renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
          unusedOrientationCutoff={Infinity}
          colorway={themeColorsStrings.concat(chartColors)}
          {...tableState}
        />
      </PivotTableContainer>
    );
  }

  return (
    <StyledSkeleton
      variant="rectangular"
      animation="wave"
      height="70vh"
      width="100%"
    />
  );
}
